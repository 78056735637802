import Navbar from "../../../components/header/Navbar";
import Sidebar from "../../../components/header/Sidebar";
import Footer from "../../../components/footer/Footer";
import React, {useEffect, useState} from "react";
import CustomerSubscriptionList from "../../../components/customer_panel/subscriptions/CustomerSubscriptionList";
import {useLocation, useParams} from "react-router-dom";


const CustomerSubscriptionListPage = () => {
  const location = useLocation()
  const [isOpenForm, setIsOpenForm] = useState(false)
  const {uid} = useParams()

  useEffect(() => {
    if (location.pathname.startsWith('/dashboard/subscriptions/create') || uid) {
      setIsOpenForm(true)
    } else {
      setIsOpenForm(false)
    }
  }, [location.pathname])

  return <>
    <Navbar/>
    <Sidebar/>
    <CustomerSubscriptionList
      isOpenForm={isOpenForm}
      updatingUID={uid}
    />
    <Footer/>
  </>
}

export default CustomerSubscriptionListPage
