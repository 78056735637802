import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  DialogContent,
  IconButton,
  useMediaQuery
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "@emotion/react";
import {useNavigate} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close'
import {useGlobalContext} from "../../../contexts/global_context";
import {AUTHORIZE_NET_LOGIN_ID, AUTHORIZE_NET_PUBLIC_KEY, BACKEND_URL, DEBUG} from "../../../data/config";
import Box from "@mui/material/Box";
import BillingInfoForm from "../../order_checkout/BillingInfoForm";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {getInputValue} from "../../../utils";
import {v4 as uuidv4} from "uuid";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import SaveIcon from '@mui/icons-material/Save';
import {ValidatorForm} from "react-material-ui-form-validator";
import {baseStyles} from "../../../assets/css/styles";


const CustomerBillingFormDialog = ({isOpenForm, updatingUID, fetchData}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const {executeRecaptcha} = useGoogleReCaptcha()
  let form = useRef()
  let {isSendingAjax, showAlert, sendAjax, setIsSendingAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})
  const [fingerprint, setFingerprint] = useState('')
  const [showNewCardForm, setShowNewCardForm] = useState(true)
  const [paymentMethods, setPaymentMethods] = useState([])

  let formInitValues = {
    card_number: '',
    expire_date: '',
    cvv: '',
    first_name: '',
    last_name: '',
    billing_address: '',
    billing_zipcode: '',
    billing_country: '',
    fingerprint: '',
    is_primary: false,
    payment_method: '',
  }

  let [formValues, setFormValues] = useState({...formInitValues})

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
      handleClose()
      fetchData()

    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    setIsSendingAjax(true)

    executeRecaptcha('CHECKOUT').then(gr => {
      let expireDate = formValues.expire_date.replaceAll(' ', '')
      expireDate = `${expireDate.split('/')[0]}/20${expireDate.split('/')[1]}`

      let newFormValues = {
        ...formValues,
        expire_date: expireDate,
        card_number: formValues.card_number.split(" ").join(""),
        fingerprint: fingerprint,
        usage_type: formValues.is_primary ? 10 : 20,
        gr: gr,
      }

      if (updatingUID) {
        // update
        sendAjax({
          url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/${updatingUID}/`,
          method: "put",
          data: newFormValues,
        }).then((r) => {
          afterSubmit(r)
        })

      } else {
        // create
        sendAjax({
          url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/`,
          method: "post",
          data: newFormValues,
        }).then((r) => {
          afterSubmit(r)
        })
      }
    })
  }

  const handleClose = () => {
    setFieldErrors({})
    setFormValues({...formInitValues})
    navigate('/dashboard/billing', {replace: true})
  }

  useEffect(() => {
    if (isOpenForm) {
      if (updatingUID) {
        sendAjax({
          url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/${updatingUID}/`,
          method: "get",
          data: {},
        }).then((r) => {
          if (r.data.status_code === 200) {
            setFormValues({
              ...r.data.data,
              card_number: `**** **** **** ${r.data.data.masked_card_number}`,
              expire_date: ``,
              cvv: ``,
              is_primary: r.data.data.usage_type === 10,
              payment_method: updatingUID,
            })
          }
        })

      } else if (window.location.pathname === '/dashboard/billing/create') {
        // find client country
        sendAjax({
          url: `${BACKEND_URL}/api/v1/core/ip-info/`,
          method: 'get',
        }).then(r => {
          if (r.data.status_code === 200) {
            setFormValues(formValues => ({...formValues, billing_country: r.data.data.country}))
          }
        })
      }
    }
  }, [isOpenForm, updatingUID])

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={isOpenForm}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Payment Method Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box
            component={ValidatorForm}
            onSubmit={handleSubmit}
            ref={form}
            sx={{
              ...baseStyles,
              paddingTop: 1,
              '& .MuiTextField-root': {marginBottom: '30px'},
            }}
          >
            <BillingInfoForm
              formValues={formValues}
              setFormValues={setFormValues}
              setFingerprint={setFingerprint}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
              handleSubmit={handleSubmit}
              showNewCardForm={showNewCardForm}
              setShowNewCardForm={setShowNewCardForm}
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
              showTitle={false}
              showSaveCard={false}
              showSetAsPrimary={true}
              selectPrimaryMethod={false}
            />
          </Box>

          <Grid container spacing={2} justifyContent={'flex-end'}>
            <Grid item>
              <Button
                color={'primary'}
                size={'large'}
                fullWidth={false}
                type={'submit'}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <LoadingButton
                color={'primary'}
                size={'large'}
                fullWidth={false}
                type={'submit'}
                variant="contained"
                loading={isSendingAjax || !Boolean(fingerprint)}
                startIcon={<SaveIcon/>}
                onClick={handleSubmit}
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default CustomerBillingFormDialog
