import {Grid} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
import HowItWorksItem from "./HowItWorksItem"
import SelectItemIcon from "../../assets/img/select-item.svg"
import CheckoutIcon from "../../assets/img/checkout.svg"
import ChartUpIcon from "../../assets/img/chart-up.svg"
import Divider from "@mui/material/Divider"
import SinDividerWhiteImg from "../../assets/img/sin-divider-white.svg"

const HowItWorks = () => {
  return (
    <div className={'how-it-works'}>
      <div className={'how-it-works-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={'our-features-head'}>
              <Typography variant={'h4'} component={'h1'}>
                How It Works?
              </Typography>
            </div>
          </Grid>
        </Grid>

        <HowItWorksItem
          title={'1. Select Package'}
          description={
            'We offer a variety of different packages to give you the ' +
            'best value for likes, followers, views and comments. ' +
            'All of our services have premium quality and they are competitively priced for you.'
          }
          icon={SelectItemIcon}
          index={0}
        />

        <Divider variant="middle"/>

        <HowItWorksItem
          title={'2. Enter Details'}
          description={
            'We never require any sensitive information such as passwords or ' +
            'other secure data in order to deliver your order. ' +
            'Simply provide us your username or your page link and we\'ll get started with your order right away.'
          }
          icon={CheckoutIcon}
          index={1}
        />

        <Divider variant="middle"/>

        <HowItWorksItem
          title={'3. See Results!'}
          description={
            'Almost all of our services, start delivering immediately after placing order. ' +
            'Our process is expedited so that it is both fast and easy to provide you with the packages you need.'
          }
          icon={ChartUpIcon}
          index={2}
        />
      </div>

      <img className={'sin-divider-bottom'} src={SinDividerWhiteImg} alt={''}/>
    </div>
  )
}

export default HowItWorks
