import {ThemeProvider} from "@mui/material/styles"
import React from "react"
import {baseStyles} from "../assets/css/styles"
import Box from '@mui/material/Box'
import {theme} from "../assets/css/styles"
import {NavbarContextProvider} from "../contexts/navbar_context"
import {GlobalContextProvider} from "../contexts/global_context"
import CategoryLandingPage from "../pages/CategoryLandingPage"
import IndexPage from "../pages/IndexPage"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom"
import EnterDetailsPage from "../pages/EnterDetailsPage"
import SelectPostsPage from "../pages/SelectPostsPage"
import CheckoutPage from "../pages/CheckoutPage"
import ScrollToTop from "./base/ScrollToTop"
import TermOfServicePage from "../pages/TermOfServicePage"
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage"
import SupportPage from "../pages/SupportPage"
import RefundPolicyPage from "../pages/RefundPolicyPage"
import CategoryListAdminPage from '../pages/admin/category/CategoryListAdminPage';
import CategoryCreateUpdateAdminPage from '../pages/admin/category/CategoryCreateUpdateAdminPage';
import PackageListAdminPage from "../pages/admin/package/PackageListAdminPage";
import PackageCreateUpdateAdminPage from "../pages/admin/package/PackageCreateUpdateAdminPage";
import AdminLoginPage from "../pages/admin/users/AdminLoginPage";
import ProviderCreateUpdateAdminPage from "../pages/admin/provider/ProviderCreateUpdateAdminPage";
import ProviderListAdminPage from "../pages/admin/provider/ProviderListAdminPage";
import OrderListAdminPage from "../pages/admin/orders/OrderListAdminPage";
import SuccessPaymentPage from "../pages/SuccessPaymentPage";
import TemplateListAdminPage from "../pages/admin/template/TemplateListAdminPage";
import TemplateCreateUpdateAdminPage from "../pages/admin/template/TemplateCreateUpdateAdminPage";
import {AdminContextProvider} from "../contexts/admin_context";
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {GOOGLE_RECAPTCHA_SITE_KEY} from "../data/config";
import TelegramChatListAdminPage from "../pages/admin/telegram_chat/TelegramChatListAdminPage";
import TelegramChatCreateUpdateAdminPage from "../pages/admin/telegram_chat/TelegramChatCreateUpdateAdminPage";
import CustomerSignUpPage from "../pages/customer_auth/CustomerSignUpPage";
import LoginPage from "../pages/auth/LoginPage";
import ResetPasswordStep1Page from "../pages/auth/ResetPasswordStep1Page";
import ResetPasswordStep2Page from "../pages/auth/ResetPasswordStep2Page";
import CustomerDashboardPage from "../pages/customer_panel/main/CustomerDashboardPage";
import CustomerOrderListPage from "../pages/customer_panel/orders/CustomerOrderListPage";
import CustomerSubscriptionListPage from "../pages/customer_panel/subscriptions/CustomerSubscriptionListPage";
import CustomerBillingListPage from "../pages/customer_panel/billing/CustomerBillingListPage";
import CustomerSettingsPage from "../pages/customer_panel/settings/CustomerSettingsPage";
import SubscriptionListAdminPage from "../pages/admin/subscription/SubscriptionListAdminPage";
import SettingsAdminPage from "../pages/admin/settings/SettingsAdminPage";


const App = () => {
  // to close menus after scrolling
  document.addEventListener('scroll', (e) => {
    try {
      document.querySelector('.MuiBackdrop-root').click()
    } catch (e) {
    }
  })

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={GOOGLE_RECAPTCHA_SITE_KEY}
      language={'en'}
    >
      <GlobalContextProvider>
        <NavbarContextProvider>
          <ThemeProvider theme={theme}>

            <Box sx={baseStyles}>
              {/* public */}
              <Router>
                <ScrollToTop>
                  <Routes>
                    <Route
                      path="/"
                      element={<IndexPage/>}
                      exact
                    />

                    <Route
                      path="/categories/:categorySlug"
                      element={<CategoryLandingPage/>}
                      exact
                    />

                    <Route
                      path="/packages/:packageSlug"
                      element={<EnterDetailsPage/>}
                      exact
                    />

                    <Route
                      path="/order/select-posts"
                      element={<SelectPostsPage/>}
                      exact
                    />

                    <Route
                      path="/order/checkout/:orderUid"
                      element={<CheckoutPage/>}
                      exact
                    />

                    <Route
                      path="/order/success/:orderUid"
                      element={<SuccessPaymentPage/>}
                      exact
                    />

                    <Route
                      path="/terms"
                      element={<TermOfServicePage/>}
                      exact
                    />

                    <Route
                      path="/privacy-policy"
                      element={<PrivacyPolicyPage/>}
                      exact
                    />

                    <Route
                      path="/refund-policy"
                      element={<RefundPolicyPage/>}
                      exact
                    />

                    <Route
                      path="/support"
                      element={<SupportPage/>}
                      exact
                    />

                    {/* AUTHENTICATION */}
                    <Route
                      path="/signup"
                      element={<CustomerSignUpPage/>}
                      exact
                    />

                    <Route
                      path="/login"
                      element={<LoginPage/>}
                      exact
                    />

                    <Route
                      path="/reset-password-step-1"
                      element={<ResetPasswordStep1Page/>}
                      exact
                    />

                    <Route
                      path="/reset-password-step-2/:token"
                      element={<ResetPasswordStep2Page/>}
                      exact
                    />

                    {/* CUSTOMER DASHBOARD */}
                    <Route
                      path="/dashboard"
                      element={<CustomerDashboardPage/>}
                      exact
                    />

                    <Route
                      path="/dashboard/orders"
                      element={<CustomerOrderListPage/>}
                      exact
                    />

                    <Route
                      path="/dashboard/subscriptions"
                      element={<CustomerSubscriptionListPage/>}
                      exact
                    />
                    <Route
                      path="/dashboard/subscriptions/create"
                      element={<CustomerSubscriptionListPage/>}
                      exact
                    />
                    <Route
                      path="/dashboard/subscriptions/update/:uid"
                      element={<CustomerSubscriptionListPage/>}
                      exact
                    />

                    <Route
                      path="/dashboard/billing"
                      element={<CustomerBillingListPage/>}
                      exact
                    />
                    <Route
                      path="/dashboard/billing/create"
                      element={<CustomerBillingListPage/>}
                      exact
                    />
                    <Route
                      path="/dashboard/billing/update/:uid"
                      element={<CustomerBillingListPage/>}
                      exact
                    />

                    <Route
                      path="/dashboard/settings"
                      element={<CustomerSettingsPage/>}
                      exact
                    />
                  </Routes>
                </ScrollToTop>
              </Router>

              {/* admin */}
              <Router>
                <AdminContextProvider>
                  <Routes>
                    {/* login */}
                    {/*<Route*/}
                    {/*  path="/admin"*/}
                    {/*  element={<AdminLoginPage/>}*/}
                    {/*  exact*/}
                    {/*/>*/}

                    {/* order */}
                    <Route
                      path="/admin/orders"
                      element={<OrderListAdminPage/>}
                      exact
                    />

                    <Route
                      path="/admin/subscriptions"
                      element={<SubscriptionListAdminPage/>}
                      exact
                    />

                    <Route
                      path="/admin/orders/details/:uid"
                      element={<OrderListAdminPage/>}
                      exact
                    />

                    {/* package */}
                    <Route
                      path="/admin/packages"
                      element={<PackageListAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/packages/create"
                      element={<PackageCreateUpdateAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/packages/update/:uid"
                      element={<PackageCreateUpdateAdminPage/>}
                      exact
                    />

                    {/* provider */}
                    <Route
                      path="/admin/providers"
                      element={<ProviderListAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/providers/create"
                      element={<ProviderCreateUpdateAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/providers/update/:uid"
                      element={<ProviderCreateUpdateAdminPage/>}
                      exact
                    />

                    {/* category */}
                    <Route
                      path="/admin/categories"
                      element={<CategoryListAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/categories/create"
                      element={<CategoryCreateUpdateAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/categories/update/:uid"
                      element={<CategoryCreateUpdateAdminPage/>}
                      exact
                    />

                    {/* template */}
                    <Route
                      path="/admin/templates"
                      element={<TemplateListAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/templates/create"
                      element={<TemplateCreateUpdateAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/templates/update/:uid"
                      element={<TemplateCreateUpdateAdminPage/>}
                      exact
                    />

                    {/* telegram chat */}
                    <Route
                      path="/admin/telegram-chats"
                      element={<TelegramChatListAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/telegram-chats/create"
                      element={<TelegramChatCreateUpdateAdminPage/>}
                      exact
                    />
                    <Route
                      path="/admin/telegram-chats/update/:uid"
                      element={<TelegramChatCreateUpdateAdminPage/>}
                      exact
                    />

                    {/* settings */}
                    <Route
                      path="/admin/settings"
                      element={<SettingsAdminPage/>}
                      exact
                    />
                  </Routes>
                </AdminContextProvider>
              </Router>
              {/* admin */}
            </Box>
          </ThemeProvider>
        </NavbarContextProvider>
      </GlobalContextProvider>
    </GoogleReCaptchaProvider>
  )
}

export default App
