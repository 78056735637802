import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import Button from "@mui/material/Button";
import CustomerBillingBoxMenu from "./CustomerBillingBoxMenu";
import VisaCardLogo from "../../../assets/img/visa-card.svg";
import * as React from "react";
import MasterCardLogo from "../../../assets/img/mastercard.svg";
import AmexLogo from "../../../assets/img/american-express.svg";
import DiscoverLogo from "../../../assets/img/discover-card.svg";
import CreditCardLogo from "../../../assets/img/credit-card.png";
import {useNavigate} from "react-router-dom";
import {getIconByMethodType} from "../../../utils";
import moment from "moment";


function isDescendant(child, parentIDs) {
  let node = child.parentNode;
  while (node != null) {
    if (parentIDs.includes(node.id)) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}


const CustomerPaymentMethodBox = ({paymentMethod, setDeletingUid}) => {
  const [disableRipple, setDisableRipple] = useState(false)
  const navigate = useNavigate()

  return (
    <Button
      sx={{
        padding: 2,
        textTransform: 'none',
      }}
      elevation={3}
      component={Card}
      disableRipple={disableRipple}
      onClick={(e) => {
        if (isDescendant(e.target, ['id_billing_menu', 'id_billing_icon_button'])) {
          setDisableRipple(true)
        } else {
          setDisableRipple(false)
          navigate(`/dashboard/billing/update/${paymentMethod.uid}`)
        }
      }}
    >
      <Grid container>
        <Grid item xs={6} sm={6} md={6} sx={{textAlign: 'left'}}>
          <img className={'payment-card-img'} src={getIconByMethodType(paymentMethod.method_type)} alt={'Mastercard'}/>
        </Grid>

        <Grid item xs={6} sm={6} md={6} sx={{textAlign: 'right'}}>
          <CustomerBillingBoxMenu
            uid={paymentMethod.uid}
            setDeletingUid={setDeletingUid}
            setDisableRipple={setDisableRipple}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'left'}}>
          <Typography variant="h5" gutterBottom>
            {paymentMethod.method_type_display} **** {paymentMethod.masked_card_number}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'left'}}>
          <Typography variant="subtitle1" gutterBottom color={'text.secondary'}>
            Expires {paymentMethod.expire_date}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'left'}}>
          <Typography variant="body2" gutterBottom color={'text.secondary'}>
            {paymentMethod.billing_address}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'left'}}>
          <Typography variant="body2" color={'text.secondary'} gutterBottom>
            Last Used: {moment(paymentMethod.last_used).fromNow()}
          </Typography>
        </Grid>
      </Grid>
    </Button>
  )
}

export default CustomerPaymentMethodBox
