import {useNavigate, useParams} from 'react-router-dom'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import React, {useEffect, useRef, useState} from "react"
import {getFieldErrorParams, getInputValue} from "../../../utils"
import AdminContainer from "../../../components/admin/AdminContainer"
import {Grid} from "@mui/material"
import {useGlobalContext} from "../../../contexts/global_context"
import {BACKEND_URL} from "../../../data/config"
import MenuItem from "@mui/material/MenuItem";
import {Helmet} from "react-helmet";

const TelegramChatCreateUpdateAdminPage = () => {
  const navigate = useNavigate()
  let {uid} = useParams()
  let form = useRef()
  let {sendAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})

  useEffect(() => {
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/notifications/admin/telegram-chats/${uid}/`,
        method: 'get',
        data: {},
      }).then((r) => {
        if (r.data.status_code === 200) {
          let telegramChat = r.data.data
          setFormValues({
            title: telegramChat.title,
            status: telegramChat.status,
            chat_id: telegramChat.chat_id,
            message_types: telegramChat.message_types,
          })
        }
      })
    }
  }, [])

  let [formValues, setFormValues] = useState({
    title: '',
    status: '',
    chat_id: '',
    message_types: [],
  })

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
      navigate("/admin/telegram-chats")
    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/notifications/admin/telegram-chats/${uid}/`,
        method: 'put',
        data: formValues,
      }).then(
        afterSubmit
      )
    } else {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/notifications/admin/telegram-chats/`,
        method: 'post',
        data: formValues,
      }).then(
        afterSubmit
      )
    }
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
    setFieldErrors({})
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>Telegram Chats - LikeTank</title>
      </Helmet>

      <Box
        component={ValidatorForm}
        ref={form}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Title'}
              placeholder={'Title'}
              name={'title'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.title}
              {...getFieldErrorParams(fieldErrors, 'title')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label="Status"
              name={'status'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.status}
              {...getFieldErrorParams(fieldErrors, 'status')}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={10}>Enabled</MenuItem>
              <MenuItem value={20}>Disabled</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Chat ID'}
              placeholder={'Chat ID'}
              name={'chat_id'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.chat_id}
              {...getFieldErrorParams(fieldErrors, 'chat_id')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label="Message Types"
              name={'message_types'}
              variant={'outlined'}
              fullWidth={true}
              {...getFieldErrorParams(fieldErrors, 'message_types')}
              value={formValues.message_types}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                multiple: true,
                value: formValues.message_types,
                onChange: handleChange,
              }}
            >
              <MenuItem value={10}>New Order</MenuItem>
            </TextValidator>
          </Grid>

        </Grid>

        <Button
          color={'primary'}
          type={'submit'}
          variant="contained"
          sx={{
            margin: '20px 0 20px 0',
          }}
          onClick={() => {
          }}
        >
          Save
        </Button>
      </Box>
    </AdminContainer>
  )
}

export default TelegramChatCreateUpdateAdminPage
