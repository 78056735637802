import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import CardMedia from "@mui/material/CardMedia"
import CardContent from "@mui/material/CardContent"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {ListItemIcon, ListItemText} from "@mui/material"
import {Link} from "react-router-dom"
import Card from "@mui/material/Card"
import Typography from "@mui/material/Typography"
import {getIconByCategoryTitle, getIconByServiceType} from '../../utils'

const GetStartedBox = ({title, description, icon, items}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Card className={'get-started-box'} elevation={0}>
      <CardMedia
        className={'get-started-box-icon'}
        component="img"
        image={icon}
        alt="Fast Delivery"
      />
      <CardContent>
        <Typography variant="body2">
          {description}
        </Typography>

        <br/>

        <div>
          <Button
            color={'primary'}
            variant="outlined"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {title}
            <ArrowDropDownIcon/>
          </Button>
          <Menu
            disableScrollLock={true}
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {items &&
            Object.keys(items).length &&
            items.categories.map(item => {
              return (
                <MenuItem
                  component={Link}
                  to={`/categories/${item.slug}`}
                  onClick={handleClose}
                  key={item.uid}
                >
                  <ListItemIcon>{getIconByCategoryTitle(item.title)}</ListItemIcon>
                  <ListItemText>{item.title}</ListItemText>
                </MenuItem>
              )
            })}
          </Menu>
        </div>

      </CardContent>
    </Card>
  )
}

export default GetStartedBox
