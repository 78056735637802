import BillingInfoForm from "../../order_checkout/BillingInfoForm";
import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom";
import {useTheme} from "@emotion/react";
import {useMediaQuery} from "@mui/material";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useGlobalContext} from "../../../contexts/global_context";
import Grid from "@mui/material/Grid";
import CustomerSubscriptionSummary from "./CustomerSubscriptionSummary";
import {baseStyles} from "../../../assets/css/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LockIcon from "@mui/icons-material/Lock";
import VisaCardLogo from "../../../assets/img/visa-card.svg";
import MasterCardLogo from "../../../assets/img/mastercard.svg";
import DiscoverCardLogo from "../../../assets/img/discover-card.svg";
import AmExLogo from "../../../assets/img/american-express.svg";
import Card from "@mui/material/Card";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {ValidatorForm} from "react-material-ui-form-validator";


const CustomerSubscriptionCheckoutForm = (
  {
    formValues,
    setFormValues,
    setFingerprint,
    fieldErrors,
    setFieldErrors,
    handleSubmit,
    showNewCardForm,
    setShowNewCardForm,
    paymentMethods,
    setPaymentMethods,
    autoService,
    setStep,
  }
) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const {executeRecaptcha} = useGoogleReCaptcha()
  let {isSendingAjax, showAlert, sendAjax, setIsSendingAjax} = useGlobalContext()

  const handleBack = () => {
    if (showNewCardForm) {
      if (paymentMethods && paymentMethods.length) {
        setShowNewCardForm(false)
      } else {
        setStep('DETAILS')
      }
    } else {
      setStep('DETAILS')
    }
  }

  return <Box>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6} order={{xs: 2, sm: 2, md: 1}}>
        <Card elevation={5} sx={{padding: 2}}>
          <BillingInfoForm
            formValues={formValues}
            setFormValues={setFormValues}
            setFingerprint={setFingerprint}
            fieldErrors={fieldErrors}
            setFieldErrors={setFieldErrors}
            handleSubmit={handleSubmit}
            showNewCardForm={showNewCardForm}
            setShowNewCardForm={setShowNewCardForm}
            paymentMethods={paymentMethods}
            setPaymentMethods={setPaymentMethods}
            showTitle={true}
            showSaveCard={false}
            showSetAsPrimary={false}
            selectPrimaryMethod={true}
          />

          <br/>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={6} md={6}>
              <LoadingButton
                sx={{
                  margin: '-20px 0 20px 0',
                }}
                color={'primary'}
                size={'large'}
                fullWidth={true}
                type={'button'}
                variant="outlined"
                startIcon={<KeyboardDoubleArrowLeftIcon/>}
                onClick={handleBack}
              >
                Back
              </LoadingButton>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <LoadingButton
                sx={{
                  margin: '-20px 0 20px 0',
                }}
                color={'primary'}
                size={'large'}
                fullWidth={true}
                type={'submit'}
                variant="contained"
                endIcon={<DoubleArrowIcon/>}
                loading={isSendingAjax || !Boolean(executeRecaptcha)}
                id={'id_checkout_form_submit_btn'}
              >
                Continue
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} sm={12} md={6} order={{xs: 1, sm: 1, md: 2}}>
        <CustomerSubscriptionSummary
          autoService={autoService}
          setStep={setStep}
        />
      </Grid>
    </Grid>
  </Box>
}

export default CustomerSubscriptionCheckoutForm
