import Navbar from "../../components/header/Navbar";
import Sidebar from "../../components/header/Sidebar";
import Footer from "../../components/footer/Footer";
import React from "react";
import ResetPasswordStep2 from "../../components/auth/ResetPasswordStep2";


const ResetPasswordStep2Page = () => {
  return <>
    <Navbar/>
    <Sidebar/>
    <ResetPasswordStep2/>
    <Footer/>
  </>
}

export default ResetPasswordStep2Page
