import Navbar from "../components/header/Navbar";
import Sidebar from "../components/header/Sidebar";
import Footer from "../components/footer/Footer";
import React from "react";
import Faq from "../components/support/Faq";
import Contact from "../components/support/Contact";
import SinDividerFooter from "../assets/img/sin-divider-footer.svg";
import {Link} from "@mui/material";
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../data/head";


const SupportPage = () => {
  return <>
    {/* SEO */}
    <Helmet>
      <title>Support - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <Navbar/>
    <Sidebar/>

    <div className={'support'}>
      <div className={'support-container'}>
        <Faq
          items={[
            {
              uid: '1',
              question: 'What are acceptable methods of payment?',
              answer: 'Our products can be easily purchased with any major credit card. ' +
                'Cards accepted at this time are MasterCard, Visa, Visa Electron, American Express and Discover. ',
            },
            {
              uid: '2',
              question: 'Do you need my password?',
              answer: 'No, we do not need your password. ' +
                'We only need your username to be able to deliver your desired package. ',
            },
            {
              uid: '3',
              question: 'What is your refund policy?',
              answer: <span>
                You can read our refund policy in <Link href={'/refund-policy'}>this</Link> page.
              </span>,
            },
            {
              uid: '4',
              question: 'Do you save my card details?',
              answer: 'We don\'t save your card info if you buy a one-time package, ' +
                'but if you sign up for a subscription (eg. monthly or weekly packages) we will ' +
                'securely save you card details to automatically renew your subscription. ' +
                'We will completely delete your card details if you delete it from your dashboard panel.'
            },
          ]}
        />
        <Contact/>
      </div>

      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>

    <Footer/>
  </>
}

export default SupportPage
