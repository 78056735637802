import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import {Divider} from "@mui/material"
import React from "react"
import {Link} from "react-router-dom"
import MuiLink from '@mui/material/Link'
import CheckoutForm from "./CheckoutForm";

const OrderCheckout = ({order}) => {
  return (
    <div className={'order-checkout-container'}>
      <Paper className={'enter-details-container'} elevation={5}>
        <div className={'enter-details-header'}>
          <Typography variant="h4" component="h1">
            Checkout
          </Typography>
        </div>

        <Divider/>

        {order.price && <CheckoutForm order={order}/>}

        <div style={{textAlign: 'center', marginTop: '10px'}}>
          <Typography variant="body2" gutterBottom sx={{maxWidth: '80%', display: 'inline-block'}}>
            By completing your order, you agree to the&nbsp;
            <MuiLink component={Link} to={'/terms'}>terms of services</MuiLink>
            &nbsp;and&nbsp;
            <MuiLink component={Link} to={'/privacy-policy'}>privacy policy</MuiLink>.
          </Typography>
        </div>
      </Paper>
    </div>
  )
}

export default OrderCheckout
