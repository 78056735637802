import {Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useNavigate, useParams} from "react-router-dom"
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import InputAdornment from "@mui/material/InputAdornment";
import LoadingButton from "@mui/lab/LoadingButton";
import SinDividerFooter from "../../assets/img/sin-divider-footer.svg";
import {useRef, useState} from "react";
import {grey} from "@mui/material/colors";
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../../data/head";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {getFieldErrorParams, getInputValue} from "../../utils";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useGlobalContext} from "../../contexts/global_context";
import {BACKEND_URL} from "../../data/config";


const ResetPasswordStep2 = () => {
  let [formValues, setFormValues] = useState({
    password: '',
    password2: '',
  })

  let {token} = useParams()
  let form = useRef()
  const {executeRecaptcha} = useGoogleReCaptcha()
  const navigate = useNavigate();
  let {sendAjax, isSendingAjax, setIsSendingAjax} = useGlobalContext();
  let [fieldErrors, setFieldErrors] = useState({})

  const handleSubmit = () => {
    if (!executeRecaptcha) {
      return
    }

    // check password match
    if (formValues.password !== formValues.password2) {
      setFieldErrors({
        ...fieldErrors,
        password2: 'Please make sure your passwords match.',
      })
      return
    }

    setIsSendingAjax(true)

    executeRecaptcha('RESET_PASSWORD').then(gr => {
      sendAjax({
        url: BACKEND_URL + "/api/v1/users/csrf/",
        method: "post",
        data: {},
        successMessage: "",
      }).then(() => {
          setIsSendingAjax(true)

          setTimeout(() => {
            sendAjax({
              url: BACKEND_URL + "/api/v1/users/reset-password-step-2/",
              method: "post",
              data: {
                ...formValues,
                token: token,
                gr: gr,
              },
            }).then(
              afterSubmit
            )
          }, 100)
        }
      );
    })
  };

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
    setFieldErrors({})
  }

  return <>
    {/* SEO */}
    <Helmet>
      <title>Sign Up - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <div className={'enter-details'}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={'enter-details-container'} elevation={5}>
            <div className={'enter-details-header'}>
              <Typography variant="h4" component="h1" gutterBottom>
                New Password
              </Typography>

              <Typography color={grey[600]} variant="body1" gutterBottom>
                Please create a new password for your account
              </Typography>
            </div>

            <Divider/>

            <div className={'enter-details-content'}>
              <Box
                component={ValidatorForm}
                ref={form}
                onSubmit={handleSubmit}
                debounceTime={1500}
                sx={{
                  '& .MuiTextField-root': {marginBottom: '30px'},
                }}
              >
                <TextValidator
                  label={'New Password'}
                  placeholder={'Create New Password...'}
                  name={'password'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOpenIcon color={'secondary'}/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  {...getFieldErrorParams(fieldErrors, 'password', 0)}
                  onChange={handleChange}
                  value={formValues.password}
                  type={"password"}
                />

                <TextValidator
                  label={'Repeat Password'}
                  placeholder={'Confirm Your Password...'}
                  name={'password2'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOpenIcon color={'secondary'}/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  {...getFieldErrorParams(fieldErrors, 'password2')}
                  onChange={handleChange}
                  value={formValues.password2}
                  type={"password"}
                />

                <LoadingButton
                  sx={{
                    margin: '0 0 20px 0',
                  }}
                  color={'primary'}
                  size={'large'}
                  fullWidth={true}
                  type={'submit'}
                  variant="contained"
                  endIcon={<DoubleArrowIcon/>}
                  loading={isSendingAjax || !Boolean(executeRecaptcha)}
                >
                  Reset Password
                </LoadingButton>
              </Box>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>
  </>
}

export default ResetPasswordStep2
