import Typography from "@mui/material/Typography"
import {Divider} from "@mui/material"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import React, {useState} from "react"
import {Link} from "react-router-dom"
import {getIconByServiceType} from "../../../utils"
import {BACKEND_URL} from "../../../data/config";

const CustomerSubscriptionSummary = ({autoService, setStep}) => {
  let [orderInfo, setOrderInfo] = useState(JSON.parse(localStorage.getItem('auto_service_info')) || {})

  let postTypes = []
  if (autoService.post_types) {
    autoService.post_types.map(item => {
      if (item === 10) {
        postTypes.push('Photo Post')
      } else if (item === 20) {
        postTypes.push('Video Post')
      } else if (item === 30) {
        postTypes.push('Album Post')
      } else if (item === 40) {
        postTypes.push('Reel')
      }
    })
  }

  return (
    <Paper className={'subscription-summary-container'} elevation={5}>
      <div className={'enter-details-header'}>
        <Typography variant="h5" component="h1">
          Subscription Summary
        </Typography>
      </div>

      <Divider/>

      <div className={'order-summary-account-info'}>
        <img
          className={'order-summary-account-info-avatar'}
          src={`${BACKEND_URL}/api/v1/socials/url-router/?url=${encodeURIComponent(orderInfo.profile_pic)}`}
          alt={'avatar'}
        />

        <div className={'order-summary-account-info-user'}>
          <b style={{display: 'block', margin: '7px 7px 0 7px'}}>
            @{autoService.username}
          </b>

          <Button
            variant="text"
            onClick={() => {
              setStep('DETAILS')
            }}
          >
            Change
          </Button>
        </div>
      </div>

      <Divider/>

      <div className={'order-summary-order-info'}>
        <div className={'order-summary-order-info-icon'}>
          {getIconByServiceType(10)}
        </div>

        <div className={'order-summary-order-info-title'}>
          <b style={{display: 'block'}}>
            {autoService.title}
          </b>

          <div style={{marginTop: '7px'}}>
            For Each New
            <br/>
            {postTypes.join(', ')}
          </div>
        </div>
      </div>

      <Divider/>

      <div className={'order-summary-price'}>
        <Typography variant="h6" gutterBottom component="span" sx={{float: 'left'}}>
          Total to Pay
        </Typography>

        <Typography variant="h6" gutterBottom component="span" sx={{float: 'right'}}>
          ${autoService.price}/Post
        </Typography>
      </div>
    </Paper>
  )
}

export default CustomerSubscriptionSummary
