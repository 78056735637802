import Navbar from "../../../components/header/Navbar";
import Sidebar from "../../../components/header/Sidebar";
import Footer from "../../../components/footer/Footer";
import React from "react";
import CustomerDashboard from "../../../components/customer_panel/main/CustomerDashboard";


const CustomerDashboardPage = () => {
  return <>
    <Navbar/>
    <Sidebar/>
    <CustomerDashboard/>
    <Footer/>
  </>
}

export default CustomerDashboardPage
