import * as React from "react"
import PropTypes from "prop-types"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import MenuIcon from "@mui/icons-material/Menu"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import {Link} from "react-router-dom"
import {useAdminContext} from "../../contexts/admin_context";

const drawerWidth = 240

function ResponsiveDrawer(props) {
  const {window, children} = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const {isAdmin} = useAdminContext()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <div>
      <Toolbar/>
      <Divider/>
      <List>
        <ListItem button component={Link} to={"/admin/orders"}>
          <ListItemText primary={"Orders"}/>
        </ListItem>
        <ListItem button component={Link} to={"/admin/subscriptions"}>
          <ListItemText primary={"Subscriptions"}/>
        </ListItem>
        <ListItem button component={Link} to={"/admin/packages"}>
          <ListItemText primary={"Packages"}/>
        </ListItem>
        <ListItem button component={Link} to={"/admin/providers"}>
          <ListItemText primary={"Providers"}/>
        </ListItem>
        <ListItem button component={Link} to={"/admin/categories"}>
          <ListItemText primary={"Categories"}/>
        </ListItem>
        <ListItem button component={Link} to={"/admin/templates"}>
          <ListItemText primary={"Templates"}/>
        </ListItem>
        <ListItem button component={Link} to={"/admin/telegram-chats"}>
          <ListItemText primary={"Telegram Chats"}/>
        </ListItem>
        <ListItem button component={Link} to={"/admin/settings"}>
          <ListItemText primary={"Settings"}/>
        </ListItem>
      </List>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return <>
    {isAdmin &&
      <Box sx={{display: "flex", overflow: "auto"}}>
        <CssBaseline/>
        <AppBar
          position="fixed"
          sx={{
            width: {sm: `calc(100% - ${drawerWidth}px)`},
            ml: {sm: `${drawerWidth}px`},
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{mr: 2, display: {sm: "none"}}}
            >
              <MenuIcon/>
            </IconButton>
            <Typography variant="h6" component="div">
              LikeTank Admin
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              display: {xs: "block", sm: "none"},
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: {xs: "none", sm: "block"},
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: {sm: `calc(100% - ${drawerWidth}px)`, xs: '100%'},
          }}
        >
          <Toolbar/>
          {children}
        </Box>
      </Box>
    }
  </>
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default ResponsiveDrawer
