import AdminContainer from "../../../components/admin/AdminContainer"
import React, {useEffect, useState} from "react"
import AdminTable from "../../../components/admin/AdminTable"
import {BACKEND_URL} from "../../../data/config"
import {Button, IconButton} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import {Link} from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import {useGlobalContext} from "../../../contexts/global_context"
import {Helmet} from "react-helmet";

const DeleteDialog = ({deletingItemUid, setDeletingItemUid, fetchData}) => {
  let {sendAjax} = useGlobalContext()

  const DeleteItem = (uid) => {
    sendAjax({
      url: BACKEND_URL + `/api/v1/subscription/admin/auto-services/${uid}/`,
      method: "delete",
    }).then((r) => {
      if (r.data.status_code === 200) {
        fetchData()
      }
    })
    setDeletingItemUid('')
  }

  return (
    <Dialog
      open={Boolean(deletingItemUid)}
      onClose={() => {
        setDeletingItemUid("")
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Item?"}</DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setDeletingItemUid("")
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            DeleteItem(deletingItemUid)
          }}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SubscriptionListAdminPage = () => {
  const {sendAjax} = useGlobalContext()
  const [page, setPage] = useState(0)
  const [data, setData] = useState({})
  const [deletingItemUid, setDeletingItemUid] = useState("")
  let items = []
  let numTotal = 0

  const fetchData = () => {
    sendAjax({
      url: BACKEND_URL + "/api/v1/subscription/admin/auto-services/",
      method: "get",
      data: {page: page + 1},
    }).then((r) => {
      setData(r.data)
    })
  }

  const handleClickDelete = (uid) => {
    setDeletingItemUid(uid)
  }

  useEffect(() => {
    fetchData()
  }, [page])

  try {
    for (let [index, item] of data.data.results.entries()) {
      items.push({
        'uid': item.uid,
        "#": index + 1,
        'Title': item.package_title,
        'Status': item.status_display,
        'Social User': item.username,
        'Account': item.created_by,
        'Invoice': item.invoice_number,
        'Actions': (
          <>
            <IconButton
              onClick={() => {
                handleClickDelete(item.uid)
              }}
            >
              <DeleteIcon/>
            </IconButton>
          </>
        ),
      })
    }
    numTotal = data.data.count
  } catch {
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>Providers - LikeTank</title>
      </Helmet>

      <DeleteDialog
        {...{deletingItemUid, setDeletingItemUid, fetchData}}
      />

      <AdminTable
        items={items}
        numTotal={numTotal}
        page={page}
        setPage={setPage}
      />
    </AdminContainer>
  )
}

export default SubscriptionListAdminPage
