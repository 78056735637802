import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import InputAdornment from "@mui/material/InputAdornment";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import DateRangeOutlinedIcon from "@mui/icons-material/DateRangeOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import React, {useEffect, useState} from "react";
import {usePaymentInputs} from "react-payment-inputs";
import images from "react-payment-inputs/lib/images";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {useGlobalContext} from "../../contexts/global_context";
import LoadingButton from "@mui/lab/LoadingButton";
import {Autocomplete, CardHeader, FormHelperText} from "@mui/material"
import {allCountries} from 'country-region-data'
import {getFieldErrorParams, getIconByMethodType, getInputValue} from "../../utils";
import {BACKEND_URL, DEBUG} from "../../data/config";
import {v4 as uuidv4} from "uuid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import LockIcon from "@mui/icons-material/Lock";
import VisaCardLogo from "../../assets/img/visa-card.svg";
import MasterCardLogo from "../../assets/img/mastercard.svg";
import DiscoverCardLogo from "../../assets/img/discover-card.svg";
import AmExLogo from "../../assets/img/american-express.svg";
import Button from "@mui/material/Button";


const BillingInfoForm = (
  {
    formValues,
    setFormValues,
    setFingerprint,
    fieldErrors,
    setFieldErrors,
    handleSubmit,
    showNewCardForm,
    setShowNewCardForm,
    paymentMethods,
    setPaymentMethods,
    showTitle,
    showSaveCard,
    showSetAsPrimary,
    selectPrimaryMethod,
  }
) => {
  let {isSendingAjax, showAlert, sendAjax, setIsSendingAjax, userInfo} = useGlobalContext()
  const {getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps} = usePaymentInputs()
  const [enableSetAsPrimary, setEnableSetAsPrimary] = useState(false)

  const handleChange = (event) => {
    setFormValues(formValues => ({...formValues, [event.target.name]: getInputValue(event.target)}))
    setFieldErrors({
      ...fieldErrors,
      [event.target.name]: '',
    })
  }

  const handleComboBoxChange = (name) => (event, value) => {
    setFormValues(formValues => ({...formValues, [name]: value}))
  }

  let paymentCardImages = {
    ...images,
    placeholder: <CreditCardOutlinedIcon color={'secondary'} fontSize={'large'} viewBox={'2 2 18 18'}/>,
  }

  const renderUserFields = () => {
    if (userInfo && userInfo.email) {
      return <>
        {showSaveCard ?
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              label="Save Card"
              control={
                <Checkbox
                  checked={formValues.save_card}
                  onChange={handleChange}
                  name={'save_card'}
                />
              }
              sx={{
                marginTop: '-20px',
                marginBottom: '-10px',
              }}
            />
            <FormHelperText
              sx={{
                marginBottom: '30px',
              }}
            >
              If checked, the card will be saved securely for your future orders
            </FormHelperText>
          </Grid> : ''}

        {showSetAsPrimary ?
          <Grid item xs={12} sm={12} md={6}>
            <FormControlLabel
              label="Set as Primary Card"
              control={
                <Checkbox
                  checked={formValues.is_primary}
                  onChange={handleChange}
                  name={'is_primary'}
                  disabled={!enableSetAsPrimary}
                />
              }
              sx={{
                marginTop: '-20px',
                marginBottom: '-10px',
              }}
            />
            <FormHelperText
              sx={{
                marginBottom: '30px',
              }}
            >
              The primary card will be used for your subscriptions
            </FormHelperText>
          </Grid> : ''}
      </>
    }

    return ''
  }

  useEffect(() => {
    if (!paymentMethods || paymentMethods.length === 0) {
      setEnableSetAsPrimary(false)
      if (showSaveCard) {
        setFormValues(formValues => ({...formValues, is_primary: formValues.save_card}))
      } else {
        setFormValues(formValues => ({...formValues, is_primary: true}))
      }

    } else if (showSaveCard) {
      if (formValues.save_card) {
        setEnableSetAsPrimary(true)
      } else {
        setFormValues(formValues => ({...formValues, is_primary: false}))
        setEnableSetAsPrimary(false)
      }

    } else {
      setEnableSetAsPrimary(true)
      if (!formValues.payment_method) {
        setFormValues(formValues => ({...formValues, is_primary: false}))
      }
    }
  }, [formValues.save_card, paymentMethods])

  useEffect(() => {
    // get saved payment methods
    sendAjax({
      url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/`,
      method: 'get',
      data: {},
      successMessage: '',
      errorMessage: '',
    }).then(r => {
      if (r.data.status_code === 200) {
        setPaymentMethods(r.data.data)
        if (!r.data.data.length) {
          setShowNewCardForm(true)
        }

        // select primary payment method
        let primaryMethod = r.data.data.find(item => item.usage_type === 10)
        if (primaryMethod && selectPrimaryMethod) {
          setFormValues(formValues => ({...formValues, payment_method: primaryMethod.uid}))
        }
      } else {
        setShowNewCardForm(true)
      }
    })

    // set or get session
    let customSession = localStorage.getItem('mycs')
    if (!customSession) {
      customSession = uuidv4()
      localStorage.setItem('mycs', customSession)
    }

    window.seon.config({
      host: "liketank.com",
      session_id: customSession,
      audio_fingerprint: true,
      canvas_fingerprint: true,
      webgl_fingerprint: true,
      onSuccess: function (message) {
        window.seon.getBase64Session(function (data) {
          setFingerprint(data)
        })
      },
      onError: function (message) {
        console.log("error", message);
      }
    });
  }, [])

  const renderEnterPaymentMethod = () => {
    if (showNewCardForm) {
      return (
        <Grid container columnSpacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <TextValidator
              label={'Card Number'}
              placeholder={'Card Number'}
              variant={'outlined'}
              inputProps={getCardNumberProps({onChange: handleChange, name: 'card_number'})}
              InputProps={{
                autoComplete: 'cc-number',
                startAdornment: (
                  <InputAdornment position="start">
                    <svg {...getCardImageProps({images: paymentCardImages})} />
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              {...getFieldErrorParams(fieldErrors, 'card_number')}
              value={formValues.card_number}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextValidator
              label={'Expire Date'}
              variant={'outlined'}
              inputProps={getExpiryDateProps({onChange: handleChange, name: 'expire_date'})}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeOutlinedIcon color={'secondary'}/>
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              {...getFieldErrorParams(fieldErrors, 'expire_date')}
              value={formValues.expire_date}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextValidator
              label={'CVV'}
              variant={'outlined'}
              inputProps={getCVCProps({onChange: handleChange, name: 'cvv', placeholder: 'CVV'})}
              InputProps={{
                autoComplete: 'cc-csc',
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon color={'secondary'}/>
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              {...getFieldErrorParams(fieldErrors, 'cvv')}
              value={formValues.cvv}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextValidator
              label={'First Name'}
              placeholder={'First Name'}
              name={'first_name'}
              variant={'outlined'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlinedIcon color={'secondary'}/>
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              {...getFieldErrorParams(fieldErrors, 'first_name')}
              onChange={handleChange}
              value={formValues.first_name}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextValidator
              label={'Last Name'}
              placeholder={'Last Name'}
              name={'last_name'}
              variant={'outlined'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlinedIcon color={'secondary'}/>
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              {...getFieldErrorParams(fieldErrors, 'last_name')}
              onChange={handleChange}
              value={formValues.last_name}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextValidator
              label={'Billing Address'}
              placeholder={'Billing Address'}
              name={'billing_address'}
              variant={'outlined'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HomeOutlinedIcon color={'secondary'}/>
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              {...getFieldErrorParams(fieldErrors, 'billing_address')}
              onChange={handleChange}
              value={formValues.billing_address}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'Billing Zip / Postal Code'}
              placeholder={'Billing Zip / Postal Code'}
              name={'billing_zipcode'}
              variant={'outlined'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOnOutlinedIcon color={'secondary'}/>
                  </InputAdornment>
                ),
              }}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              {...getFieldErrorParams(fieldErrors, 'billing_zipcode')}
              onChange={handleChange}
              value={formValues.billing_zipcode}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Autocomplete
              name={'billing_country'}
              options={allCountries.map(item => item[1])}
              onChange={handleComboBoxChange('billing_country')}
              value={formValues.billing_country}
              getOptionLabel={(option) => {
                let country = allCountries.find(item => item[1] === option)
                return country ? country[0] : ''
              }}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name={'billing_country'}
                  label="Billing Country"
                  placeholder={'Billing Country'}
                  value={formValues.billing_country}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  {...getFieldErrorParams(fieldErrors, 'billing_country')}
                />
              )}
            />
          </Grid>

          {renderUserFields()}
        </Grid>
      )
    } else {
      return (
        <Card
          elevation={2}
          sx={{
            marginBottom: '30px',
          }}
        >
          <CardHeader
            title="Saved Cards"
            subheader="Select a card or add a new one to continue"
            titleTypographyProps={{
              fontSize: 'large',
            }}
            sx={{
              marginBottom: 0,
              paddingBottom: 0,
            }}
          />

          <CardContent>
            <List>
              {paymentMethods.map((paymentMethod) => {
                return (
                  <ListItem
                    sx={{textAlign: 'left'}}
                    key={paymentMethod.uid}
                    secondaryAction={
                      <Radio
                        edge="end"
                        checked={formValues.payment_method === paymentMethod.uid}
                        inputProps={{'aria-labelledby': 'labelId'}}
                      />
                    }
                    disablePadding
                    onClick={() => {
                      setFormValues(formValues => ({...formValues, payment_method: paymentMethod.uid}))
                    }}
                  >
                    <ListItemButton sx={{textAlign: 'left'}}>
                      <ListItemAvatar sx={{textAlign: 'left'}}>
                        <img
                          style={{width: '30px'}}
                          src={getIconByMethodType(paymentMethod.method_type)}
                        />
                      </ListItemAvatar>
                      <ListItemText id={'labelId'} primary={`**** **** **** ${paymentMethod.masked_card_number}`}/>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>

            <Button
              variant="text"
              sx={{
                paddingLeft: 2,
              }}
              onClick={() => {
                setShowNewCardForm(true)
                setFormValues(formValues => ({
                  ...formValues,
                  payment_method: '',
                }))
              }}
            >
              + Add a New Card
            </Button>
          </CardContent>
        </Card>
      )
    }
  }

  return <>
    {showTitle ?
      <Grid container sx={{margin: '0 0 30px 0'}}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography variant="subtitle2" component="h1" fontSize={'large'}>
            Pay with Credit / Debit Card
            <LockIcon fontSize={'small'} color={'success'} sx={{left: '5px', top: '3px', position: 'relative'}}/>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={6} className={'order-checkout-payment-card-container'}>
          <img className={'order-checkout-payment-card-img'} src={VisaCardLogo} alt={'Visa Card'}/>
          <img className={'order-checkout-payment-card-img'} src={MasterCardLogo} alt={'Mastercard'}/>
          <img className={'order-checkout-payment-card-img'} src={DiscoverCardLogo} alt={'Discover Card'}/>
          <img className={'order-checkout-payment-card-img'} src={AmExLogo} alt={'American Express'}/>
        </Grid>
      </Grid> : ''}

    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        {renderEnterPaymentMethod()}
      </Grid>
    </Grid>
  </>
}

export default BillingInfoForm