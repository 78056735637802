import * as React from 'react'
import FeatureBox from "./FeatureBox"
import FastDeliveryIcon from '../../assets/img/fast-delivery.png'
import TrustedAccountIcon from '../../assets/img/trusted-account.png'
import CashbackIcon from '../../assets/img/cashback.png'
import Grid from '@mui/material/Grid'
import {Typography} from "@mui/material";
import SinDividerHowItWorksImg from "../../assets/img/sin-divider-how-it-works.svg"

const OurFeatures = () => {
  return (
    <div className={'our-features'}>
      <div className={'our-features-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={'our-features-head'}>
              <Typography variant={'h4'} component={'h1'}>
                Our Features
              </Typography>

              <br/>

              <Typography variant="p" color={'text.secondary'}>
                Time has arrived to check out some of the exclusive features associated with our amazing packages!
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <FeatureBox
              title={'Real Accounts'}
              description={'You’ll be amazed by the accounts that will ' +
              'follow you or like your images. None of our competitors can match that.'}
              icon={TrustedAccountIcon}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <FeatureBox
              title={'Fastest Delivery'}
              description={'You’ll see results immediately. packages will be delivered as soon as you place an order!'}
              icon={FastDeliveryIcon}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <FeatureBox
              title={'100% Guarantee'}
              description={'If you didn\'t receive your package at the appointed time, simply notify us ' +
              'and we will refund you.'}
              icon={CashbackIcon}
            />
          </Grid>
        </Grid>
      </div>

      <img className={'sin-divider-bottom'} src={SinDividerHowItWorksImg} alt={''}/>
    </div>
  )
}

export default OurFeatures
