import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TablePagination from "@mui/material/TablePagination"
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Link from '@mui/material/Link'


function Row({row}) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {borderBottom: 'unset'},
          'whiteSpace': 'nowrap',
        }}
        onClick={() => setOpen(!open)}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.invoice_number}
        </TableCell>
        <TableCell>{row.title}</TableCell>
        <TableCell>{row.status}</TableCell>
        <TableCell>{row.date}</TableCell>
        <TableCell>${row.price}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{padding: '0 0 0px 25px'}} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{width: '150px'}}>User / Post</TableCell>
                    <TableCell>Order Title</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.order_providers.map((orderProvider) => (
                    <TableRow key={orderProvider.uid}>
                      <TableCell component="th" scope="row">
                        <Link
                          href={orderProvider.link}
                          target={'_blank'}
                        >
                          {orderProvider.link}
                        </Link>
                      </TableCell>
                      <TableCell>{orderProvider.title}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


const CustomerOrdersTable = ({items, numTotal, page, setPage}) => {
  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  return <>
    <Paper elevation={0} sx={{margin: 1}}>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell/>
              <TableCell>INVOICE</TableCell>
              <TableCell>TITLE</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>PRICE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              items && items.length ?
                items.map((row) => (
                  <Row key={row.uid} row={row}/>
                ))
                :
                <TableRow>
                  <TableCell
                    colSpan={6}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    You have not placed any orders yet
                  </TableCell>
                </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={numTotal}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />
    </Paper>
  </>
}

export default CustomerOrdersTable
