import * as React from 'react'
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import {Link} from "react-router-dom"
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import {navbarLinks} from "../../data/navbar"
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PopupState, {bindHover, bindPopover} from 'material-ui-popup-state'
import MenuIcon from '@mui/icons-material/Menu'
import {IconButton, ListItemIcon, ListItemText} from "@mui/material"
import {useNavbarContext} from "../../contexts/navbar_context"
import {alpha} from '@mui/material/styles'
import {theme} from "../../assets/css/styles"
import {getIconByCategoryTitle} from "../../utils"
import logo from "../../assets/img/logo/liketank-logo-horizontal-transparent.png"
import {useGlobalContext} from "../../contexts/global_context"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = () => {
  let {groupedCategories, userInfo} = useGlobalContext()
  let {toggleSidebar, logoutUser} = useNavbarContext()

  const renderUserButtons = () => {
    if (userInfo && userInfo.email) {
      let dashboardURL = userInfo.is_staff ? '/admin/orders/' : '/dashboard/'

      return <>
        <PopupState variant="popover" popupId="demoPopover">
          {(popupState) => (
            <div>
              <Button
                {...bindHover(popupState)}
                color={'secondary'}
                className={'navbar-btn'}
                variant="outlined"
                endIcon={<ArrowDropDownIcon/>}
                sx={{
                  marginLeft: '10px',
                }}
                onClick={() => {
                  window.location = dashboardURL
                }}
              >
                Account
              </Button>

              <HoverPopover
                PaperProps={{
                  sx: {
                    paddingTop: '10px',
                    overflow: 'visible',
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: '50%',
                      width: 5,
                      height: 5,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuList>
                  <MenuItem
                    onClick={() => {
                      window.location = dashboardURL
                    }}
                  >
                    <ListItemIcon><AccountCircleIcon/></ListItemIcon>
                    <ListItemText>Dashboard</ListItemText>
                  </MenuItem>

                  <MenuItem
                    onClick={logoutUser}
                  >
                    <ListItemIcon><LogoutIcon/></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </MenuList>
              </HoverPopover>
            </div>
          )}
        </PopupState>
      </>

      if (userInfo.is_staff) {
        return <>
          <Button
            color={'secondary'}
            className={'navbar-btn'}
            onClick={() => {
              window.location.pathname = '/admin/orders/'
            }}
            variant="outlined"
          >
            Admin Panel
          </Button>
        </>
      } else {

      }
    } else {
      return <>
        <Button
          className={'navbar-btn'}
          component={Link}
          to={'/login'}
        >
          Login
        </Button>

        <Button
          color={'secondary'}
          className={'navbar-btn'}
          component={Link}
          to={'/signup'}
          variant="outlined"
        >
          Sign up
        </Button>
      </>
    }
  }

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: alpha(theme.palette.primary.main, 0.95),
        textAlign: 'center',
      }}
    >
      <Toolbar sx={{paddingLeft: '0px'}}>
        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
          <Link to={'/'} style={{display: 'flex', alignItems: 'center'}}>
            <img src={logo} alt={'LikeTank Logo'} className={'header-logo'}/>
          </Link>
        </Typography>

        {groupedCategories && groupedCategories.map((menuItem, index) => {
          return (
            <PopupState key={index} variant="popover" popupId="demoPopover">
              {(popupState) => (
                <div>
                  <Button className={'navbar-btn'} {...bindHover(popupState)}>
                    {menuItem.social_display}
                    <ArrowDropDownIcon/>
                  </Button>

                  <HoverPopover
                    PaperProps={{
                      sx: {
                        paddingTop: '10px',
                        overflow: 'visible',
                        '&:before': {
                          content: '""',
                          display: 'block',
                          position: 'absolute',
                          top: 0,
                          right: '50%',
                          width: 5,
                          height: 5,
                          bgcolor: 'background.paper',
                          transform: 'translateY(-50%) rotate(45deg)',
                          zIndex: 0,
                        },
                      },
                    }}
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <NavbarSubmenu subMenuItems={menuItem.categories}/>
                  </HoverPopover>
                </div>
              )}
            </PopupState>
          )
        })}

        {navbarLinks.map(item => {
          return (
            <Button className={'navbar-btn'} component={Link} to={item.slug} key={item.uid} variant={item.variant}>
              {item.title}
            </Button>
          )
        })}

        {renderUserButtons()}

        <IconButton className={'navbar-sidebar-btn'} onClick={toggleSidebar(true)}>
          <MenuIcon/>
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

const NavbarSubmenu = ({subMenuItems}) => {
  return (
    <MenuList>
      {subMenuItems.map((item, index) => {
        return (
          <MenuItem
            key={item.uid}
            onClick={() => {
              window.location = `/categories/${item.slug}`
            }}
          >
            <ListItemIcon>{getIconByCategoryTitle(item.title)}</ListItemIcon>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        )
      })}
    </MenuList>
  )
}

export default Navbar
