import Navbar from "../components/header/Navbar"
import Sidebar from "../components/header/Sidebar"
import Footer from "../components/footer/Footer"
import React from "react"
import OrderSelectPosts from "../components/order_select_posts/OrderSelectPosts";
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../data/head";

const EnterDetailsPage = () => {
  return <>
    {/* SEO */}
    <Helmet>
      <title>Select Posts - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <Navbar/>
    <Sidebar/>
    <OrderSelectPosts/>
    <Footer/>
  </>
}

export default EnterDetailsPage
