import React, {Component} from 'react'
import {useContext, createContext, useState, forwardRef, useEffect} from "react"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import Cookies from "js-cookie"
import axios from "axios"
import {BACKEND_URL} from "../data/config";

let GlobalContext = createContext(null)

export const GlobalContextProvider = ({children}) => {
  const [userInfo, setUserInfo] = useState({})

  let alertInitState = {
    message: "",
    type: "success",
  }

  let [alertState, setAlertState] = useState(alertInitState)
  let [groupedCategories, setGroupedCategories] = useState([])

  let [isSendingAjax, setIsSendingAjax] = useState(false)
  let [sendingAjaxUrls, setSendingAjaxUrls] = useState([])

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
  })

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setAlertState(alertInitState)
  }

  useEffect(() => {
    // get categories
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/categories/grouped-by-social/`,
      method: 'get',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        setGroupedCategories(r.data.data)
      }
    })

    // get user info
    sendAjax({
      url: `${BACKEND_URL}/api/v1/users/user-info/`,
      method: 'get',
      data: {},
      errorMessage: '',
    }).then(r => {
      if (r.data.status_code === 200) {
        setUserInfo(r.data.data)
      }
    })
  }, [])

  const showAlert = (message, type) => {
    setAlertState(alertInitState)

    setAlertState({
      message: message,
      type: type,
    })
  }

  const sendAjax = async ({url, method, data, successMessage = null, errorMessage = null}) => {
    setIsSendingAjax(true)
    setSendingAjaxUrls(sendingAjaxUrls.concat([url]))

    const methodLower = method.toLowerCase()
    const headers = {
      "X-CSRFToken": Cookies.get("csrftoken"),
    }

    const handleSuccess = (r) => {
      setIsSendingAjax(false)
      setSendingAjaxUrls(sendingAjaxUrls.filter(item => item !== url))

      if (method !== "get") {
        if (successMessage === null) {
          showAlert(r.data.status_message, "success")
        } else if (successMessage) {
          showAlert(successMessage, "success")
        }
      }
      return r
    }

    const handleError = (e) => {
      setIsSendingAjax(false)
      setSendingAjaxUrls(sendingAjaxUrls.filter(item => item !== url))

      if (errorMessage === null) {
        showAlert(e.response.data.status_message, "error")
      } else if (errorMessage) {
        showAlert(errorMessage, "error")
      }

      return e.response
    }

    if (methodLower === "get") {
      return axios
        .get(url, {
          params: data,
          withCredentials: true,
        })
        .then(handleSuccess)
        .catch(handleError)
    } else if (methodLower === "post") {
      return axios
        .post(url, data, {
          headers: headers,
          withCredentials: true,
        })
        .then(handleSuccess)
        .catch(handleError)
    } else if (methodLower === "put") {
      return axios
        .put(url, data, {
          headers: headers,
          withCredentials: true,
        })
        .then(handleSuccess)
        .catch(handleError)
    } else if (methodLower === "delete") {
      return axios
        .delete(url, {
          headers: headers,
          withCredentials: true,
        })
        .then(handleSuccess)
        .catch(handleError)
    }
  }

  const value = {
    showAlert,
    sendAjax,
    groupedCategories,
    setGroupedCategories,
    isSendingAjax,
    setIsSendingAjax,
    sendingAjaxUrls,
    setSendingAjaxUrls,
    userInfo,
    setUserInfo,
  }

  return (
    <GlobalContext.Provider value={value}>
      <Snackbar
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
        open={Boolean(alertState.message)}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{zIndex: 100000}}
      >
        <Alert
          onClose={handleClose}
          severity={alertState.type}
          sx={{width: "100%"}}
        >
          {alertState.message}
        </Alert>
      </Snackbar>

      {children}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => {
  return useContext(GlobalContext)
}
