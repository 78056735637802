import Button from "@mui/material/Button"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {Link, useNavigate} from "react-router-dom"
import {IconButton, ListItemIcon, ListItemText} from "@mui/material"
import * as React from "react"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import CustomerConfirmDialog from "../dialog/CustomerConfirmDialog";
import {useState} from "react";


const CustomerBillingBoxMenu = ({uid, title, icon, items, disableRipple, setDisableRipple, setDeletingUid}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setTimeout(() => {
      setDisableRipple(false)
    }, 200)

    setAnchorEl(null)
  }

  const handleEdit = () => {
    navigate(`/dashboard/billing/update/${uid}`)
    handleClose()
  }

  const handleDelete = () => {
    setDeletingUid(uid)
    handleClose()
  }

  return (
    <div>
      <IconButton
        variant="contained"
        id="id_billing_icon_button"
        aria-controls="id_billing_menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        disableScrollLock={true}
        id="id_billing_menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'id_billing_icon_button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={handleEdit}
        >
          <ListItemIcon><EditIcon/></ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={handleDelete}
        >
          <ListItemIcon><DeleteOutlineIcon/></ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default CustomerBillingBoxMenu
