import PeopleIcon from "@mui/icons-material/People"
import VisibilityIcon from "@mui/icons-material/Visibility"
import CommentIcon from "@mui/icons-material/Comment"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import * as React from "react"
import InstagramIcon from "@mui/icons-material/Instagram"
import MusicNoteIcon from "@mui/icons-material/MusicNote"
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined"
import PanToolOutlinedIcon from "@mui/icons-material/PanToolOutlined"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import MasterCardLogo from "./assets/img/mastercard.svg";
import VisaCardLogo from "./assets/img/visa-card.svg";
import AmexLogo from "./assets/img/american-express.svg";
import DiscoverLogo from "./assets/img/discover-card.svg";
import CreditCardLogo from "./assets/img/credit-card.png";

export const getIconBySocial = (social) => {
  let icon = null
  social = social.toLowerCase()
  if (social === "instagram") {
    icon = <InstagramIcon/>
  } else if (social === "tiktok") {
    icon = <MusicNoteIcon/>
  } else if (social === "clubhouse") {
    icon = <PanToolOutlinedIcon/>
    // icon = <Icon><img src={WavingHandIcon} alt={''} width={'100%'}/></Icon>
  }

  return icon
}

export const getIconByServiceType = (serviceType) => {
  let icon = null
  if (serviceType === 10) {
    icon = <FavoriteOutlinedIcon/>
  } else if (serviceType === 20) {
    icon = <PeopleIcon/>
  } else if (serviceType === 30) {
    icon = <VisibilityIcon/>
  } else if (serviceType === 40) {
    icon = <CommentIcon/>
  } else if (serviceType === 50) {
    icon = <VisibilityIcon/>
  } else if (serviceType === 60) {
    icon = <PeopleAltIcon/>
  }

  return icon
}

export const getIconByCategoryTitle = (title) => {
  title = title.toLowerCase()
  let icon = <FavoriteOutlinedIcon/>

  if (title.includes('blue tick') || title.includes('verified')) {
    icon = <CheckCircleIcon/>
  } else if (title.includes('like')) {
    icon = <FavoriteOutlinedIcon/>
  } else if (title.includes('follow')) {
    icon = <PeopleIcon/>
  } else if (title.includes('view')) {
    icon = <VisibilityIcon/>
  } else if (title.includes('comment')) {
    icon = <CommentIcon/>
  } else if (title.includes('visit')) {
    icon = <VisibilityIcon/>
  } else if (title.includes('member')) {
    icon = <PeopleAltIcon/>
  }

  return icon
}

export const getInputValue = (input) => {
  if (input.type === "checkbox") {
    return input.checked
  } else {
    return input.value
  }
}

export const fileFieldToBase64 = file => new Promise((resolve, reject) => {
  if (file) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  } else {
    resolve(null)
  }
})

export const getFieldErrorParams = (fieldErrors, fieldName, index = null) => {
  let errors = fieldErrors ? fieldErrors[fieldName] : null
  if (errors) {
    let text = ''
    if (index === null) {
      if (Array.isArray(errors)) {
        text = errors.join(' ')
      } else {
        text = errors
      }
    } else {
      text = errors[index]
    }

    return {
      error: Boolean(text),
      helperText: text,
    }
  } else {
    return {
      error: false,
      helperText: '',
    }
  }
}

export const objectToQuery = (obj) => {
  const query = new URLSearchParams()

  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      obj[key].map(item => query.append(key, item))
    } else {
      query.append(key, obj[key])
    }
  }

  return query
}

export const getIconByMethodType = (methodType) => {
  if (methodType === 'MASTERCARD') {
    return MasterCardLogo
  } else if (methodType === 'VISA') {
    return VisaCardLogo
  } else if (methodType === 'AMEX') {
    return AmexLogo
  } else if (methodType === 'DISCOVER') {
    return DiscoverLogo
  } else {
    return CreditCardLogo
  }
}