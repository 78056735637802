import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Button,
  Chip,
  DialogContent,
  IconButton,
  Link as MuiLink,
  useMediaQuery
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTheme} from "@emotion/react";
import {useNavigate} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close'
import Grid from "@mui/material/Grid";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {useGlobalContext} from "../../../contexts/global_context";
import {BACKEND_URL} from "../../../data/config";
import Box from "@mui/material/Box";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Divider from "@mui/material/Divider";
import {JsonToTable} from "react-json-to-table"
import ConfirmDialog from "../dialog/ConfirmDialog";

const OrderDetailsDialog = ({uid}) => {
  const navigate = useNavigate()
  const theme = useTheme();
  const {sendAjax} = useGlobalContext()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [order, setOrder] = useState({})
  const [isOpenCompleteDialog, setIsOpenCompleteDialog] = useState(false)
  const [isOpenRefundDialog, setIsOpenRefundDialog] = useState(false)

  const handleSubmit = () => {

  }

  const completeOrder = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/commerce/admin/orders/complete/${uid}/`,
      method: 'post',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        setIsOpenCompleteDialog(false)
        fetchOrder()
      }
    })
  }

  const refundOrder = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/commerce/admin/orders/refund/${uid}/`,
      method: 'post',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        setIsOpenRefundDialog(false)
        fetchOrder()
      }
    })
  }

  const handleClose = () => {
    navigate('/admin/orders')
  };

  const fetchOrder = () => {
    if (uid) {
      setOrder({})
      sendAjax({
        url: `${BACKEND_URL}/api/v1/commerce/admin/orders/${uid}/`,
        method: 'get',
        data: {},
      }).then(r => {
        if (r.data.status_code === 200) {
          setOrder(r.data.data)
        }
      })
    }
  }

  useEffect(() => {
    fetchOrder()
  }, [uid])

  return (
    <div>
      <ConfirmDialog
        message={'Change to completed?'}
        confirmBtnText={'Complete'}
        onConfirm={completeOrder}
        onClose={() => {
          setIsOpenCompleteDialog(false)
        }}
        isOpen={isOpenCompleteDialog}
        zIndex={2000}
      />

      <ConfirmDialog
        message={'Change to refunded?'}
        confirmBtnText={'Refund'}
        onConfirm={refundOrder}
        onClose={() => {
          setIsOpenRefundDialog(false)
        }}
        isOpen={isOpenRefundDialog}
        zIndex={2000}
      />

      <Dialog
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={Boolean(uid)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Order Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box
            component={ValidatorForm}
            onSubmit={handleSubmit}
            sx={{
              width: '900px',
              maxWidth: '100%',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12}>
                <Divider>
                  <Chip label="Order Providers"/>
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow sx={{whiteSpace: 'nowrap'}}>
                        <TableCell align="center">Provider Status</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Provider Response</TableCell>
                        <TableCell align="center">Link</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Provider</TableCell>
                        <TableCell align="center">Service Code</TableCell>
                        <TableCell align="center">Provider Type</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {order.order_providers && order.order_providers.map(item => {
                        let lastProviderStatus = item.order_provider_status_changes[
                        item.order_provider_status_changes.length - 1
                          ]

                        return (
                          <TableRow
                            key={item.uid}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                          >
                            <TableCell align="center">
                              {lastProviderStatus ?
                                <JsonToTable styles={{border: ''}} json={lastProviderStatus.data}/> : ''}
                            </TableCell>
                            <TableCell align="center">{item.status_display}</TableCell>
                            <TableCell align="center">{<JsonToTable json={item.provider_response}/>}</TableCell>
                            <TableCell align="center">
                              <MuiLink href={item.link} target={'_blank'}>Link</MuiLink>
                            </TableCell>
                            <TableCell align="center">{item.quantity}</TableCell>
                            <TableCell align="center">
                              <MuiLink
                                href={`/admin/providers/update/${item.provider}`}
                                target={'_blank'}
                              >
                                {item.provider_title}
                              </MuiLink>
                            </TableCell>
                            <TableCell align="center">{item.service_code}</TableCell>
                            <TableCell align="center">{item.provider_type_display}</TableCell>

                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{marginTop: '30px'}}>
                  <Chip label="Status Changes"/>
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center">Elapsed</TableCell>
                        <TableCell align="center">Date</TableCell>
                        <TableCell align="center">User</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {order.order_status_changes && order.order_status_changes.map(item => {
                        return (
                          <TableRow
                            key={item.uid}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                          >
                            <TableCell align="center">{item.status_display}</TableCell>
                            <TableCell align="center">{moment(item.created_at).fromNow()}</TableCell>
                            <TableCell align="center">{moment(item.created_at).format('LLL')}</TableCell>
                            <TableCell align="center">{item.created_by}</TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{marginTop: '30px'}}>
                  <Chip label="Operations"/>
                </Divider>
              </Grid>

              <Grid item xs={6} sm={4} md={2} sx={{textAlign: 'center'}}>
                <Button
                  color={'warning'}
                  variant="outlined"
                  onClick={() => {
                    setIsOpenCompleteDialog(true)
                  }}
                >
                  Complete
                </Button>
              </Grid>

              <Grid item xs={6} sm={4} md={2} sx={{textAlign: 'center'}}>
                <Button
                  color={'error'}
                  variant="outlined"
                  onClick={() => {
                    setIsOpenRefundDialog(true)
                  }}
                >
                  Refund
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Divider sx={{marginTop: '30px'}}>
                  <Chip label="Order Details"/>
                </Divider>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'UID'}
                  name={'uid'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.uid || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Invoice Number'}
                  name={'invoice_number'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.invoice_number || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Title'}
                  name={'title'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.title || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Price'}
                  name={'price'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.price || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Status'}
                  name={'status'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.status_display || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Elapsed Time'}
                  name={'elapsed_time'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.created_at ? moment(order.created_at).fromNow() : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Create Date'}
                  name={'create_date'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.created_at ? moment(order.created_at).format('LLL') : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Social'}
                  name={'social'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.social_display || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Created By'}
                  name={'created_by'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.created_by || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Customer Email'}
                  name={'customer_email'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.order_customer_info ? order.order_customer_info.email : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextValidator
                  label={'Customer IP'}
                  name={'customer_ip'}
                  variant={'outlined'}
                  fullWidth={true}
                  value={order.order_customer_info ? order.order_customer_info.ip : ''}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

      </Dialog>
    </div>
  );
}

export default OrderDetailsDialog
