import AdminContainer from "../../../components/admin/AdminContainer"
import React, {useEffect, useState} from "react"
import AdminTable from "../../../components/admin/AdminTable"
import {BACKEND_URL} from "../../../data/config"
import {Autocomplete, Button, Grid, IconButton} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import {Link} from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import {useGlobalContext} from "../../../contexts/global_context"
import {Helmet} from "react-helmet";
import SearchContainer from "../../../components/admin/SearchContainer"
import SearchIcon from '@mui/icons-material/Search'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {getInputValue} from "../../../utils";
import Box from "@mui/material/Box";


const DeleteDialog = ({
                        deletingItemUid,
                        setDeletingItemUid,
                        fetchData,
                      }) => {
  let {sendAjax} = useGlobalContext()

  const DeleteItem = (uid) => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/admin/packages/${uid}/`,
      method: "delete",
      data: {},
    }).then((r) => {
      setDeletingItemUid("")
      fetchData()
    })
  }

  return (
    <Dialog
      open={Boolean(deletingItemUid)}
      onClose={() => {
        setDeletingItemUid("")
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Item?"}</DialogTitle>
      <DialogActions>
        <Button
          onClick={() => {
            setDeletingItemUid("")
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            DeleteItem(deletingItemUid)
          }}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const PackageListAdminPage = () => {
  const {sendAjax} = useGlobalContext()
  let items = []
  let numTotal = 0

  const [page, setPage] = useState(0)
  const [data, setData] = useState({})
  const [deletingItemUid, setDeletingItemUid] = useState("")
  let [categories, setCategories] = useState([])
  const [formValues, setFormValues] = useState({
    category: '',
  })

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
  }

  const handleComboBoxChange = (name) => (event, value) => {
    setFormValues({...formValues, [name]: value})
  }

  useEffect(() => {
    // get categories
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/admin/categories/`,
      method: 'get',
      data: {
        page_size: 1000,
      },
    }).then(r => {
      if (r.data.status_code === 200) {
        setCategories(r.data.data.results)
      }
    })
  }, [])

  const fetchData = () => {
    console.log('send')
    sendAjax({
      url: BACKEND_URL + "/api/v1/pkg/admin/packages/",
      method: "get",
      data: {
        page: page + 1,
        ...formValues,
      },
    }).then((r) => {
      setData(r.data)
    })
  }

  const handleClickDelete = (uid) => {
    setDeletingItemUid(uid)
  }

  useEffect(() => {
    fetchData()
  }, [page])

  try {
    for (let [index, item] of data.data.results.entries()) {
      items.push({
        uid: item.uid,
        "#": index + 1,
        title: item.title,
        price: item.price,
        quantity: item.quantity,
        discount: item.discount,
        status: item.status,
        index: item.index,
        actions: (
          <>
            <IconButton
              component={Link}
              to={`/admin/packages/update/${item.uid}`}
            >
              <EditIcon/>
            </IconButton>

            <IconButton
              onClick={() => {
                handleClickDelete(item.uid)
              }}
            >
              <DeleteIcon/>
            </IconButton>
          </>
        ),
      })
    }
    numTotal = data.data.count
  } catch {
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>Packages - LikeTank</title>
      </Helmet>

      <DeleteDialog
        {...{deletingItemUid, setDeletingItemUid, fetchData}}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <Button
            component={Link}
            to={"/admin/packages/create"}
            variant="outlined"
            startIcon={<AddIcon/>}
          >
            Add
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <SearchContainer>
            <Box
              component={ValidatorForm}
              onSubmit={fetchData}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                  <TextValidator
                    label={'Title'}
                    placeholder={'Title'}
                    name={'title'}
                    variant={'outlined'}
                    fullWidth={true}
                    onChange={handleChange}
                    value={formValues.title}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <Autocomplete
                    disablePortal
                    name={'category'}
                    options={categories.map(category => category.uid)}
                    onChange={handleComboBoxChange('category')}
                    value={formValues.category}
                    getOptionLabel={(option) => {
                      let category = categories.find((item) => item.uid === option)
                      return category ? category.title : ''
                    }}
                    renderInput={(params) => (
                      <TextValidator
                        {...params}
                        name={'category'}
                        label="Category"
                        placeholder={'Category'}
                        value={formValues.category}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="outlined"
                    startIcon={<SearchIcon/>}
                    type={'submit'}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </SearchContainer>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <AdminTable
            items={items}
            numTotal={numTotal}
            page={page}
            setPage={setPage}
          />
        </Grid>
      </Grid>
    </AdminContainer>
  )
}

export default PackageListAdminPage
