import Typography from "@mui/material/Typography"
import {Divider} from "@mui/material"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import React, {useState} from "react"
import {Link} from "react-router-dom"
import {getIconByServiceType} from "../../utils"
import {BACKEND_URL} from "../../data/config";

const OrderSummary = ({order}) => {
  let [orderInfo, setOrderInfo] = useState(JSON.parse(localStorage.getItem('order_info')) || {})

  return (
    <div className={'order-summary-container'}>
      <Paper className={'enter-details-container'} elevation={5}>
        <div className={'enter-details-header'}>
          <Typography variant="h5" component="h1">
            Order Summary
          </Typography>
        </div>

        <Divider/>

        <div className={'order-summary-account-info'}>
          <img
            className={'order-summary-account-info-avatar'}
            src={`${BACKEND_URL}/api/v1/socials/url-router/?url=${encodeURIComponent(orderInfo.profile_pic)}`}
            alt={'avatar'}
          />

          <div className={'order-summary-account-info-user'}>
            <b style={{display: 'block', margin: '7px 7px 0 7px'}}>
              {orderInfo.link_title}
            </b>

            <Button
              component={Link}
              to={`/packages/${order.package_slug}`}
              variant="text"
            >
              Change
            </Button>
          </div>
        </div>

        <Divider/>

        <div className={'order-summary-order-info'}>
          <div className={'order-summary-order-info-icon'}>
            {getIconByServiceType(10)}
          </div>

          <div className={'order-summary-order-info-title'}>
            <b style={{display: 'block'}}>
              {order.title}
            </b>

            {order.num_posts &&
            [10, 20].includes(order.social) &&
            order.service_type_quantities ?
              order.service_type_quantities.map(item => {
                return (
                  <div style={{marginTop: '7px'}} key={item.service_type}>
                    {Math.floor(item.display_quantity / order.num_posts)}
                    &nbsp;
                    {item.service_type_display} / {order.num_posts} Post(s)
                  </div>
                )
              })
              :
              <></>
            }
          </div>
        </div>

        <Divider/>

        <div className={'order-summary-price'}>
          <Typography variant="h6" gutterBottom component="span" sx={{float: 'left'}}>
            Total to Pay
          </Typography>

          <Typography variant="h6" gutterBottom component="span" sx={{float: 'right'}}>
            ${order.price} USD
          </Typography>
        </div>
      </Paper>
    </div>
  )
}

export default OrderSummary
