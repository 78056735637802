import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import SinDividerWhiteImg from "../../assets/img/sin-divider-white.svg"
import * as React from "react"
import Button from "@mui/material/Button"
import {Link} from "react-router-dom";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined'

const PricingBox = ({item}) => {
  const title1 = item.title.split(' ')[0]
  const title2 = item.title.split(' ').slice(1).join(' ')

  return (
    <div className={'pricing-box'}>
      <div className={'pricing-box-discount'}>
        {item.discount}% OFF
      </div>

      <Paper elevation={5}>
        <div className={'pricing-box-header'}>
          <Typography variant="h4" component="div">
            {title1}
          </Typography>

          <Typography variant="h5" gutterBottom component="div">
            {title2}
          </Typography>

          <img
            style={{
              width: "100.4%",
              position: "relative",
              right: "0",
              left: '-0.2%',
              bottom: "-4px",
            }}
            src={SinDividerWhiteImg} alt={''}
          />
        </div>

        <div className={'pricing-box-content'}>
          <div className={'pricing-box-content-line'}>
            <CheckCircleOutlineOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.pricing_descriptions[0]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <RestartAltOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.pricing_descriptions[1]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <LockOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.pricing_descriptions[2]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <AccessTimeOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.pricing_descriptions[3]}
            </span>
          </div>

          <div className={'pricing-box-content-line'}>
            <SupportAgentOutlinedIcon className={'pricing-box-content-line-icon'}/>
            <span className={'pricing-box-content-line-text'}>
              {item.pricing_descriptions[4]}
            </span>
          </div>

          <Typography className={'pricing-box-price'} variant="h4" component="div">
            ${item.price}
          </Typography>

          <Button
            component={Link}
            to={`/packages/${item.slug}`}
            className={'pricing-box-buy-button'}
            variant="contained"
            size="large"
            fullWidth={true}
            color={'primary'}
          >
            Buy Now
          </Button>
        </div>
      </Paper>
    </div>
  )
}

export default PricingBox
