import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import * as React from "react";
import {Box} from "@mui/material";
import {Link} from "react-router-dom";


const CustomerDashboardBox = ({title, icon, link}) => {
  return (
    <Box component={Link} to={link} sx={{textDecorationColor: 'black'}}>
      <Card>
        <CardMedia
          component="img"
          image={icon}
          sx={{
            width: '50%',
            display: 'inline',
            padding: '20px 20px 0 20px',
          }}
        />
        <CardContent sx={{textAlign: 'center'}}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  )
}

export default CustomerDashboardBox
