import Paper from '@mui/material/Paper'
import React, {useEffect, useRef, useState} from "react"
import Grid from "@mui/material/Grid"
import {Divider} from "@mui/material"
import Typography from "@mui/material/Typography"
import SinDividerFooter from "../../assets/img/sin-divider-footer.svg"
import {useNavigate} from "react-router-dom"
import ImagePicker from "./ImagePicker";
import {useGlobalContext} from "../../contexts/global_context";
import {BACKEND_URL} from "../../data/config";
import LoadingButton from "@mui/lab/LoadingButton";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const OrderSelectPosts = () => {
  const {executeRecaptcha} = useGoogleReCaptcha()
  let orderInfoLocalStorage = JSON.parse(localStorage.getItem('order_info'))
  const navigate = useNavigate()
  let [orderInfo, setOrderInfo] = useState({})
  let [allImages, setAllImages] = useState([])
  let [selectedImages, setSelectedImages] = useState([])
  let {sendAjax, sendingAjaxUrls, setSendingAjaxUrls, showAlert} = useGlobalContext()
  const theme = useTheme()
  let pageSize = useRef(0)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  let [pkg, setPkg] = useState({})
  let quantity = 0
  let min_quantity = 0

  useEffect(() => {
    if (orderInfo.package) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/packages/${orderInfo.package}`,
        method: 'get',
        data: {},
      }).then(r => {
        if (r.data.status_code === 200) {
          setPkg(r.data.data)
        }
      })
    }
  }, [orderInfo.package])

  // calculate quantity
  if (pkg && pkg.package_providers && pkg.package_providers.length) {
    quantity = 0
    min_quantity = 0

    for (let item of pkg.package_providers) {
      quantity += item.display_quantity

      if (item.min_quantity > min_quantity) {
        min_quantity = item.min_quantity
      }
    }
  } else {
    quantity = 0
    min_quantity = 0
  }

  // calculate page size
  if (pkg.category_social === 10) {
    if (isMobile) {
      pageSize.current = 6
    } else {
      pageSize.current = 8
    }
  } else if (pkg.category_social === 20) {
    if (isMobile) {
      pageSize.current = 6
    } else {
      pageSize.current = 8
    }
  }

  useEffect(() => {
    setAllImages(orderInfoLocalStorage.posts.posts)
    setOrderInfo(orderInfoLocalStorage)
  }, [])

  const handleSubmit = () => {
    // check if any post selected or not?
    if (!selectedImages.length) {
      showAlert('Please select at least one post.', 'error')
      return
    }

    // place order
    let orderURL = `${BACKEND_URL}/api/v1/commerce/orders/`
    setSendingAjaxUrls(sendingAjaxUrls.concat([orderURL]))

    executeRecaptcha('PLACE_ORDER').then(gr => {
      sendAjax({
        url: orderURL,
        method: 'post',
        data: {
          package: pkg.uid,
          email: orderInfo.email,
          link: orderInfo.username,
          link_title: orderInfo.link_title,
          gender: 10,
          posts: selectedImages.map(item => item.id),
          gr: gr,
        },
        successMessage: '',
      }).then(r => {
        if (r.data.status_code === 200) {
          localStorage.setItem('order_info', JSON.stringify({
            ...orderInfoLocalStorage,
            invoice_number: r.data.data.invoice_number,
          }))

          let orderUid = r.data.data.uid
          navigate(`/order/checkout/${orderUid}`)
        }
      })
    })
  }

  const getMoreImages = () => {
    let getPostsURL = `${BACKEND_URL}/api/v1/socials/posts/`
    setSendingAjaxUrls(sendingAjaxUrls.concat([getPostsURL]))

    executeRecaptcha('GET_POSTS').then(gr => {
      sendAjax({
        url: getPostsURL,
        method: 'get',
        data: {
          package: orderInfo.package,
          username: orderInfo.username,
          user_id: orderInfo.posts.user_id,
          cursor: orderInfo.posts.cursor,
          gr: gr,
        },
      }).then(r => {
        if (r.data.status_code === 200) {
          let posts = {
            ...r.data.data,
            posts: orderInfo.posts.posts.concat(r.data.data.posts),
          }

          setOrderInfo({
            ...orderInfo,
            posts: posts,
          })

          setAllImages(posts.posts)
        }
      })
    })

  }

  return (
    <div className={'enter-details'}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={'enter-details-container'} elevation={5}>
            <div className={'enter-details-header'}>
              <Typography variant="h4" component="h1">
                Select Posts
              </Typography>
            </div>

            <Divider/>

            <div className={'select-post-content'}>
              <Typography variant="subtitle1" component="div">
                You can select <b>multiple</b> posts.
              </Typography>

              <Typography variant="subtitle1" component="div">
                Your package will be <b>split evenly</b> between your posts.
              </Typography>

              {orderInfo &&
                orderInfo.posts &&
                pageSize.current &&
                pkg &&
                Object.keys(pkg).length &&
                <ImagePicker
                  totalCount={quantity}
                  minCount={min_quantity}
                  allImages={allImages}
                  selectedImages={selectedImages}
                  setSelectedImages={setSelectedImages}
                  square={pkg.category_social === 10}
                  pageSize={pageSize.current}
                  getMoreImages={getMoreImages}
                  hasMoreImages={Boolean(orderInfo.posts.cursor)}
                />
              }

              <LoadingButton
                sx={{
                  margin: '10px 0 10px 0',
                }}
                color={'primary'}
                size={'large'}
                fullWidth={true}
                type={'submit'}
                variant="contained"
                onClick={handleSubmit}
                endIcon={<DoubleArrowIcon/>}
                loading={sendingAjaxUrls.includes(`${BACKEND_URL}/api/v1/commerce/orders/`)}
              >
                Continue
              </LoadingButton>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>
  )
}

export default OrderSelectPosts
