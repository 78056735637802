import Navbar from "../../components/header/Navbar";
import Sidebar from "../../components/header/Sidebar";
import Footer from "../../components/footer/Footer";
import React from "react";
import Login from "../../components/auth/Login";


const LoginPage = () => {
  return <>
    <Navbar/>
    <Sidebar/>
    <Login/>
    <Footer/>
  </>
}

export default LoginPage
