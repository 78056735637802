import * as React from 'react';
import {Divider, Grid} from "@mui/material";
import GetStarted from "../../get_started/GetStarted";
import {useGlobalContext} from "../../../contexts/global_context";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import {Link, useNavigate} from "react-router-dom";
import CustomerPaymentMethodBox from "./CustomerPaymentMethodBox";
import CustomerBillingBoxMenu from "./CustomerBillingBoxMenu";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CustomerBillingFormDialog from "./CustomerBillingFormDialog";
import {BACKEND_URL} from "../../../data/config";
import CustomerConfirmDialog from "../dialog/CustomerConfirmDialog";


const CustomerBillingList = ({isOpenForm, updatingUID}) => {
  let {groupedCategories, sendAjax} = useGlobalContext()
  const [data, setData] = useState([])
  const navigate = useNavigate()
  const [deletingUid, setDeletingUid] = useState('')

  const fetchData = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/`,
      method: "get",
      data: {},
    }).then((r) => {
      setData(r.data.data)
    })
  }

  const handleDelete = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/${deletingUid}/`,
      method: "delete",
      data: {},
    }).then((r) => {
      fetchData()
      setDeletingUid('')
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return <>
    <CustomerBillingFormDialog
      isOpenForm={isOpenForm}
      updatingUID={updatingUID}
      fetchData={fetchData}
    />

    <CustomerConfirmDialog
      message={'Delete Payment Method?'}
      cancelBtnText={'No'}
      confirmBtnText={'Delete'}
      onConfirm={handleDelete}
      onClose={() => {
        setDeletingUid('')
      }}
      isOpen={Boolean(deletingUid)}
      zIndex={2000}
    />

    <div className={'customer-dashboard'}>
      <div className={'customer-dashboard-container'}>
        <Box elevation={3}>
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant={'h4'} component={'h1'}>
                Payment Methods
              </Typography>

              <br/>
              <Divider/>
              <br/>
            </Grid>

            <Grid container spacing={2}>
              {data.length ? data.map(paymentMethod => {
                return (
                  <Grid item xs={12} sm={6} md={4} key={paymentMethod.uid}>
                    <CustomerPaymentMethodBox paymentMethod={paymentMethod} setDeletingUid={setDeletingUid}/>
                  </Grid>
                )
              }) : ''}

              <Grid item xs={12} sm={6} md={4}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate('/dashboard/billing/create')
                  }}
                  sx={{
                    border: '1px dashed grey',
                    width: '100%',
                    height: '210px',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h3>+ Add Payment Method</h3>
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </Box>
      </div>
    </div>

    <GetStarted
      title={'Place a New Order'}
      description={
        'Choose one of the options below to get started. ' +
        'Your fantastic likes, followers, views and comments are only a few clicks away!'
      }
      groupedCategories={groupedCategories}
    />
  </>
}

export default CustomerBillingList
