import React from 'react'
import Navbar from "../components/header/Navbar"
import Sidebar from "../components/header/Sidebar"
import Hero from "../components/hero/Hero"
import OurFeatures from "../components/our_features/OurFeatures"
import HowItWorks from "../components/how_it_works/HowItWorks"
import GetStarted from "../components/get_started/GetStarted"
import Footer from "../components/footer/Footer"
import Pricing from "../components/pricing/Pricing"
import {useGlobalContext} from "../contexts/global_context"
import {useParams} from "react-router-dom"
import {useEffect, useState} from "react"
import {BACKEND_URL} from "../data/config"
import {Helmet} from "react-helmet"
import HeaderNotification from "../components/notification/HeaderNotification";

const CategoryLandingPage = () => {
  let {groupedCategories, sendAjax} = useGlobalContext()
  let {categorySlug} = useParams()
  let [category, setCategory] = useState({})

  useEffect(() => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/categories/`,
      method: 'get',
      data: {
        slug: categorySlug,
      },
    }).then(r => {
      if (r.data.status_code === 200 && r.data.data.results.length) {
        setCategory(r.data.data.results[0])
      }
    })
  }, [categorySlug])

  return <>
    {/* SEO */}
    <Helmet>
      <title>{category.seo_title ? `${category.seo_title} -` : ''} LikeTank</title>
      <meta name="description" content={category.meta_description ? category.meta_description : ''}/>
    </Helmet>

    <HeaderNotification/>
    <Navbar groupedCategories={groupedCategories}/>
    <Sidebar groupedCategories={groupedCategories}/>
    <Hero category={category}/>
    <OurFeatures/>
    <HowItWorks/>
    <Pricing showTabs={false} groupedCategories={groupedCategories}/>
    <GetStarted
      title={'Other Great Services'}
      description={
        'Choose one of the options below to get started. ' +
        'Your fantastic likes, followers, views and comments are only a few clicks away!'
      }
      groupedCategories={groupedCategories}
    />
    <Footer groupedCategories={groupedCategories}/>
  </>
}

export default CategoryLandingPage
