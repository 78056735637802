import {useNavigate, useParams} from 'react-router-dom'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import React, {useEffect, useRef, useState} from "react"
import {fileFieldToBase64, getFieldErrorParams, getInputValue} from "../../../utils"
import AdminContainer from "../../../components/admin/AdminContainer"
import {FormHelperText, Grid} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import {useGlobalContext} from "../../../contexts/global_context"
import {BACKEND_URL} from "../../../data/config"
import {Helmet} from "react-helmet";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const CategoryCreateUpdateAdminPage = () => {
  const navigate = useNavigate()
  let {uid} = useParams()
  let form = useRef()
  let {sendAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})

  useEffect(() => {
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/categories/${uid}/`,
        method: 'get',
        data: {},
      }).then((r) => {
        if (r.data.status_code === 200) {
          let category = r.data.data
          setFormValues({
            title: category.title,
            image: null,
            social: category.social,
            index: category.index,
            link_name: category.link_name,
            link_description: category.link_description,
            short_description: category.short_description,
            long_description: category.long_description,
            seo_title: category.seo_title,
            slug: category.slug,
            status: category.status,
            enable_auto_service: category.enable_auto_service,
            meta_description: category.meta_description,
          })
        }
      })
    }
  }, [])

  let [formValues, setFormValues] = useState({
    title: '',
    image: null,
    social: '',
    index: '',
    link_name: '',
    link_description: '',
    short_description: '',
    long_description: '',
    seo_title: '',
    slug: '',
    status: '',
    enable_auto_service: '',
    meta_description: '',
  })

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
      navigate("/admin/categories/")
    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/categories/${uid}/`,
        method: 'put',
        data: formValues,
      }).then(
        afterSubmit
      )
    } else {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/categories/`,
        method: 'post',
        data: formValues,
      }).then(
        afterSubmit
      )
    }
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
    setFieldErrors({})
  }

  const handleFileSelect = (event) => {
    fileFieldToBase64(event.target.files[0]).then(data => {
      setFormValues({...formValues, [event.target.name]: data})
      setFieldErrors({})
    })
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>Categories - LikeTank</title>
      </Helmet>

      <Box
        component={ValidatorForm}
        ref={form}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Title'}
              placeholder={'Title'}
              name={'title'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.title}
              {...getFieldErrorParams(fieldErrors, 'title')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Image'}
              name={'image'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleFileSelect}
              type={'file'}
              {...getFieldErrorParams(fieldErrors, 'image')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label="Social"
              name={'social'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.social}
              {...getFieldErrorParams(fieldErrors, 'social')}
              InputProps={{
                startAdornment: <></>,
              }}
            >
              <MenuItem value={10}>Instagram</MenuItem>
              <MenuItem value={20}>TikTok</MenuItem>
              <MenuItem value={30}>Clubhouse</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Index'}
              name={'index'}
              placeholder={'Index'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.index}
              {...getFieldErrorParams(fieldErrors, 'index')}
              type={'number'}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Link Name'}
              name={'link_name'}
              placeholder={'Link Name'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.link_name}
              {...getFieldErrorParams(fieldErrors, 'link_name')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Link Description'}
              name={'link_description'}
              placeholder={'Link Description'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.link_description}
              {...getFieldErrorParams(fieldErrors, 'link_description')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextValidator
              label={'Short Description'}
              name={'short_description'}
              placeholder={'Short Description'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.short_description}
              {...getFieldErrorParams(fieldErrors, 'short_description')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextValidator
              label={'Long Description'}
              name={'long_description'}
              placeholder={'Long Description'}
              variant={'outlined'}
              fullWidth={true}
              multiline={true}
              rows={2}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.long_description}
              {...getFieldErrorParams(fieldErrors, 'long_description')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'SEO Title'}
              name={'seo_title'}
              placeholder={'SEO Title'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.seo_title}
              {...getFieldErrorParams(fieldErrors, 'seo_title')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Slug'}
              name={'slug'}
              placeholder={'Slug'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.slug}
              {...getFieldErrorParams(fieldErrors, 'slug')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label="Status"
              name={'status'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.status}
              {...getFieldErrorParams(fieldErrors, 'status')}
              InputProps={{
                startAdornment: <></>,
              }}
            >
              <MenuItem value={10}>Enabled</MenuItem>
              <MenuItem value={20}>Disabled</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <FormControlLabel
              label="Enable Auto Service"
              control={
                <Checkbox
                  checked={formValues.enable_auto_service}
                  onChange={handleChange}
                  name={'enable_auto_service'}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextValidator
              label={'Meta Description'}
              name={'meta_description'}
              placeholder={'Meta Description'}
              variant={'outlined'}
              fullWidth={true}
              multiline={true}
              rows={2}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.meta_description}
              {...getFieldErrorParams(fieldErrors, 'meta_description')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>
        </Grid>

        <Button
          color={'primary'}
          type={'submit'}
          variant="contained"
          sx={{
            margin: '20px 0 20px 0',
          }}
          onClick={() => {
          }}
        >
          Save
        </Button>
      </Box>
    </AdminContainer>
  )
}

export default CategoryCreateUpdateAdminPage
