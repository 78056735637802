import Paper from '@mui/material/Paper'
import InputAdornment from "@mui/material/InputAdornment"
import React, {useEffect, useRef, useState} from "react"
import Grid from "@mui/material/Grid"
import {
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  Select
} from "@mui/material"
import Typography from "@mui/material/Typography"
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem"
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SinDividerFooter from "../../assets/img/sin-divider-footer.svg"
import {useNavigate} from "react-router-dom"
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import {getInputValue} from "../../utils"
import {useGlobalContext} from "../../contexts/global_context";
import {BACKEND_URL} from "../../data/config";
import LoadingButton from '@mui/lab/LoadingButton'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {Helmet} from "react-helmet";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";


const OrderEnterDetails = ({packageSlug}) => {
  const {executeRecaptcha} = useGoogleReCaptcha()
  let [packages, setPackages] = useState([])
  let {sendAjax, setIsSendingAjax, isSendingAjax, userInfo} = useGlobalContext()
  const navigate = useNavigate()
  let form = useRef()
  let [pkg, setPkg] = useState({})

  let [formValues, setFormValues] = useState({
    username: '',
    email: '',
    package_slug: packageSlug,
  })

  useEffect(() => {
    if (formValues.package_slug) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/packages/`,
        method: 'get',
        data: {
          slug: formValues.package_slug,
        },
      }).then(r => {
        if (r.data.status_code === 200) {
          setPkg(r.data.data.results[0])
        }
      })
    }
  }, [formValues.package_slug])

  useEffect(() => {
    if (pkg.category) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/packages/`,
        method: 'get',
        data: {
          category__uid: pkg.category,
          page_size: 1000,
        },
      }).then(r => {
        if (r.data.status_code === 200) {
          setPackages(r.data.data.results)
        }
      })
    }
  }, [pkg])

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
  }

  const handleSubmit = () => {
    if (!executeRecaptcha) {
      return
    }

    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/packages/required-order-info/${pkg.uid}/`,
      method: 'get',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        if (r.data.data.param_names.includes('posts')) {
          // get posts
          setIsSendingAjax(true)
          executeRecaptcha('GET_POSTS').then(gr => {
            sendAjax({
              url: `${BACKEND_URL}/api/v1/socials/posts/`,
              method: 'get',
              data: {
                package: pkg.uid,
                username: formValues.username,
                gr: gr,
              },
            }).then(r => {
              if (r.data.status_code === 200) {
                localStorage.setItem('order_info', JSON.stringify({
                  ...formValues,
                  username: r.data.data.username,
                  package: pkg.uid,
                  price: pkg.price,
                  posts: r.data.data,
                  profile_pic: r.data.data.image,
                  link_title: r.data.data.title,
                }))

                navigate('/order/select-posts/')
              }
            })
          })
        } else {
          // validate link and get profile picture
          setIsSendingAjax(true)
          executeRecaptcha('GET_LINK_INFO').then(gr => {
            sendAjax({
              url: `${BACKEND_URL}/api/v1/socials/link-info/`,
              method: 'get',
              data: {
                package: pkg.uid,
                value: formValues.username,
                gr: gr,
              },
            }).then(r => {
              if (r.data.status_code === 200) {
                // save profile pic url
                localStorage.setItem('order_info', JSON.stringify({
                  ...formValues,
                  username: r.data.data.value,
                  package: pkg.uid,
                  price: pkg.price,
                  posts: [],
                  profile_pic: r.data.data.image,
                  link_title: r.data.data.title,
                }))

                // place order
                setIsSendingAjax(true)
                executeRecaptcha('PLACE_ORDER').then(gr => {
                  sendAjax({
                    url: `${BACKEND_URL}/api/v1/commerce/orders/`,
                    method: 'post',
                    data: {
                      package: pkg.uid,
                      email: formValues.email,
                      link: r.data.data.value,
                      link_title: r.data.data.title,
                      gender: 10,
                      gr: gr,
                    },
                    successMessage: '',
                  }).then(r => {
                    if (r.data.status_code === 200) {
                      let orderInfoLocalStorage = JSON.parse(localStorage.getItem('order_info'))
                      localStorage.setItem('order_info', JSON.stringify({
                        ...orderInfoLocalStorage,
                        invoice_number: r.data.data.invoice_number,
                      }))

                      navigate(`/order/checkout/${r.data.data.uid}`)
                    }
                  })
                })
              }
            })
          })
        }
      }
    })
  }

  return <>
    {/* SEO */}
    <Helmet>
      <title>{pkg.seo_title ? `${pkg.seo_title} -` : ''} LikeTank</title>
      <meta name="description" content={pkg.meta_description ? pkg.meta_description : ''}/>
    </Helmet>

    <div className={'enter-details'}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={'enter-details-container'} elevation={5}>
            <div className={'enter-details-header'}>
              <Typography variant="h4" component="h1">
                Get Started
              </Typography>
            </div>

            <Divider/>

            <div className={'enter-details-content'}>
              <Box
                component={ValidatorForm}
                ref={form}
                onSubmit={handleSubmit}
                debounceTime={1500}
                sx={{
                  '& .MuiTextField-root': {marginBottom: '30px'},
                }}
              >
                <TextValidator
                  label={pkg && pkg.category_link_name}
                  placeholder={pkg && pkg.category_link_description}
                  name={'username'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailOutlinedIcon color={'secondary'}/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={handleChange}
                  value={formValues.username}
                />

                {!userInfo || !userInfo.email ?
                  <TextValidator
                    label={'Email address'}
                    placeholder={'Email address'}
                    name={'email'}
                    variant={'outlined'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon color={'secondary'}/>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth={true}
                    validators={['required', 'isEmail']}
                    errorMessages={['This field is required', 'Email is not valid']}
                    onChange={handleChange}
                    value={formValues.email}
                  /> : ''}

                <FormControl fullWidth>
                  <InputLabel id="id-package-label">Package</InputLabel>
                  <Select
                    labelId="id-package-label"
                    label="Package"
                    onChange={handleChange}
                    value={formValues.package_slug}
                    name={'package_slug'}
                  >
                    {packages.map(item => {
                      return (
                        <MenuItem key={item.uid} value={item.slug}>
                          <ListItemText>{item.title}</ListItemText>
                          <Typography variant="body2" color="text.secondary">
                            ${item.price}
                          </Typography>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>

                <LoadingButton
                  sx={{
                    margin: '20px 0 20px 0',
                  }}
                  color={'primary'}
                  size={'large'}
                  fullWidth={true}
                  type={'submit'}
                  variant="contained"
                  endIcon={<DoubleArrowIcon/>}
                  loading={isSendingAjax || !Boolean(executeRecaptcha)}
                >
                  Continue
                </LoadingButton>
              </Box>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>
  </>
}

export default OrderEnterDetails
