import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LockIcon from "@mui/icons-material/Lock";
import VisaCardLogo from "../../assets/img/visa-card.svg";
import MasterCardLogo from "../../assets/img/mastercard.svg";
import DiscoverCardLogo from "../../assets/img/discover-card.svg";
import AmExLogo from "../../assets/img/american-express.svg";
import React, {useEffect, useRef, useState} from "react";
import {getInputValue} from "../../utils";
import {useGlobalContext} from "../../contexts/global_context";
import {AUTHORIZE_NET_LOGIN_ID, AUTHORIZE_NET_PUBLIC_KEY, BACKEND_URL, DEBUG} from "../../data/config";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useNavigate} from "react-router-dom";
import {v4 as uuidv4} from 'uuid'
import BillingInfoForm from "./BillingInfoForm";
import Box from "@mui/material/Box";
import {ValidatorForm} from "react-material-ui-form-validator";
import {baseStyles} from "../../assets/css/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";


const CheckoutForm = ({order}) => {
  const {executeRecaptcha} = useGoogleReCaptcha()
  const navigate = useNavigate()
  let {isSendingAjax, showAlert, sendAjax, setIsSendingAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})
  const [fingerprint, setFingerprint] = useState('')
  const [showNewCardForm, setShowNewCardForm] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  let [orderInfo, setOrderInfo] = useState(JSON.parse(localStorage.getItem('order_info')) || {})
  let form = useRef()

  let [formValues, setFormValues] = useState({
    card_number: '',
    expire_date: '',
    cvv: '',
    first_name: '',
    last_name: '',
    billing_address: '',
    billing_zipcode: '',
    billing_country: '',
    fingerprint: '',
    payment_method: '',
    save_card: false,
    is_primary: false,
  })

  const handleSubmit = () => {
    if (!executeRecaptcha) {
      return
    }

    // check whether a payment method is selected or not
    if (!showNewCardForm && !formValues.payment_method) {
      showAlert(
        'Please select a payment method to continue.',
        'error',
      )
      return
    }

    setIsSendingAjax(true)

    executeRecaptcha('CHECKOUT').then(gr => {
      let expireDate = formValues.expire_date.replaceAll(' ', '')
      expireDate = `${expireDate.split('/')[0]}/20${expireDate.split('/')[1]}`

      let newFormValues = {
        ...formValues,
        expire_date: expireDate,
        card_number: formValues.card_number.split(" ").join(""),
        fingerprint: fingerprint,
        gr: gr,
      }

      sendAjax({
        url: `${BACKEND_URL}/api/v1/payments/authorizenet/pay/${order.uid}/`,
        method: 'post',
        data: newFormValues,
        successMessage: '',
      }).then(r => {
        if (r.data.status_code === 200) {
          navigate(`/order/success/${order.uid}`)
        }
      })
    })
  }

  const handleBack = () => {
    if (showNewCardForm) {
      setShowNewCardForm(false)
    } else {
      if (orderInfo.posts.posts) {
        navigate(`/order/select-posts/`)
      } else {
        navigate(`/packages/${order.package_slug}/`)
      }
    }
  }

  return (
    <div className={'enter-details-content'}>
      <Box
        component={ValidatorForm}
        onSubmit={handleSubmit}
        ref={form}
        sx={{
          '& .MuiTextField-root': {marginBottom: '30px'},
        }}
      >
        <BillingInfoForm
          formValues={formValues}
          setFormValues={setFormValues}
          setFingerprint={setFingerprint}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          handleSubmit={handleSubmit}
          showNewCardForm={showNewCardForm}
          setShowNewCardForm={setShowNewCardForm}
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
          showTitle={true}
          showSaveCard={true}
          showSetAsPrimary={true}
          selectPrimaryMethod={true}
        />

        <br/>

        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6}>
            <LoadingButton
              sx={{
                margin: '-20px 0 20px 0',
              }}
              color={'primary'}
              size={'large'}
              fullWidth={true}
              type={'button'}
              variant="outlined"
              startIcon={<KeyboardDoubleArrowLeftIcon/>}
              onClick={handleBack}
            >
              Back
            </LoadingButton>
          </Grid>

          <Grid item xs={6} sm={6} md={6}>
            <LoadingButton
              sx={{
                margin: '-20px 0 20px 0',
              }}
              color={'primary'}
              size={'large'}
              fullWidth={true}
              type={'submit'}
              variant="contained"
              loading={isSendingAjax || !Boolean(executeRecaptcha)}
              id={'id_checkout_form_submit_btn'}
            >
              Pay ${order.price} USD
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

export default CheckoutForm
