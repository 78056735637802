import {useNavigate, useParams} from 'react-router-dom'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import React, {useEffect, useRef, useState} from "react"
import {getFieldErrorParams, getInputValue} from "../../../utils"
import AdminContainer from "../../../components/admin/AdminContainer"
import {Grid} from "@mui/material"
import {useGlobalContext} from "../../../contexts/global_context"
import {BACKEND_URL} from "../../../data/config"
import MenuItem from "@mui/material/MenuItem";
import {Helmet} from "react-helmet";

const SettingsAdminPage = () => {
  const navigate = useNavigate()
  let {uid} = useParams()
  let form = useRef()
  let {sendAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})

  useEffect(() => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/core/admin/settings/`,
      method: 'get',
      data: {},
    }).then((r) => {
      if (r.data.status_code === 200) {
        let settings = r.data.data
        setFormValues({
          instagram_scraper: settings.instagram_scraper,
          tiktok_scraper: settings.tiktok_scraper,
          scraper_proxies: settings.scraper_proxies.join('\n'),
        })
      }
    })
  }, [])

  let [formValues, setFormValues] = useState({
    instagram_scraper: '',
    tiktok_scraper: '',
    scraper_proxies: '',
  })

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
      navigate("/admin/settings")
    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    let proxies = []
    formValues.scraper_proxies.split('\n').forEach(proxy => {
      let trimmedProxy = proxy.trim().replace(' ', '')
      if (trimmedProxy) {
        proxies.push(trimmedProxy)
      }
    })

    sendAjax({
      url: `${BACKEND_URL}/api/v1/core/admin/settings/`,
      method: 'post',
      data: {
        ...formValues,
        scraper_proxies: proxies,
      },
    }).then(
      afterSubmit
    )
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
    setFieldErrors({})
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>Settings - LikeTank</title>
      </Helmet>

      <Box
        component={ValidatorForm}
        ref={form}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              select
              label="Instagram Scraper"
              name={'instagram_scraper'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.instagram_scraper}
              {...getFieldErrorParams(fieldErrors, 'instagram_scraper')}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={10}>Instagram Scraper 2022</MenuItem>
              <MenuItem value={50}>Instagram 230</MenuItem>
              <MenuItem value={20}>Instagram Data1</MenuItem>
              <MenuItem value={30}>Instagram 28</MenuItem>
              <MenuItem value={40}>Instagram Bulk Profile Scrapper</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              select
              label="tiktok Scraper"
              name={'tiktok_scraper'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.tiktok_scraper}
              {...getFieldErrorParams(fieldErrors, 'tiktok_scraper')}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={10}>TokApi Mobile Version</MenuItem>
              <MenuItem value={20}>TikTok Best Experience</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextValidator
              label={'Scraper Proxies'}
              name={'scraper_proxies'}
              placeholder={
                'Warning: If you don\'t put proxies here, the system will get ' +
                'Instagram and TikTok photos without any proxy (Not Recommended)\n' +
                'Please put proxies here in separated lines like this:\n' +
                '130.180.231.128:8270:firkgld:eirikdXkv\n' +
                '45.196.54.142:6721:firkgld:firkgld'
              }
              variant={'outlined'}
              fullWidth={true}
              multiline={true}
              rows={10}
              onChange={handleChange}
              value={formValues.scraper_proxies}
              {...getFieldErrorParams(fieldErrors, 'scraper_proxies')}
              InputProps={{
                startAdornment: <></>,
              }}
            />
          </Grid>
        </Grid>

        <Button
          color={'primary'}
          type={'submit'}
          variant="contained"
          sx={{
            margin: '20px 0 20px 0',
          }}
          onClick={() => {
          }}
        >
          Save
        </Button>
      </Box>
    </AdminContainer>
  )
}

export default SettingsAdminPage
