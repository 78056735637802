import CustomerBillingFormDialog from "../billing/CustomerBillingFormDialog";
import Box from "@mui/material/Box";
import {Chip, Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import CustomerPaymentMethodBox from "../billing/CustomerPaymentMethodBox";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import GetStarted from "../../get_started/GetStarted";
import * as React from "react";
import {useGlobalContext} from "../../../contexts/global_context";
import MenuItem from "@mui/material/MenuItem";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {useRef, useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import {getFieldErrorParams, getInputValue} from "../../../utils";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from '@mui/icons-material/Save';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {BACKEND_URL} from "../../../data/config";


const CustomerSettings = () => {
  const {executeRecaptcha} = useGoogleReCaptcha()
  let form = useRef()
  let {groupedCategories, sendAjax, setIsSendingAjax, isSendingAjax, userInfo} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})
  let [formValues, setFormValues] = useState({
    email: 'test@test.com',
    old_password: '',
    new_password: '',
    repeat_password: '',
  })

  const handleChange = (event) => {
    setFieldErrors({})
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
  }

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {

    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    // check passwords
    if (formValues.new_password !== formValues.repeat_password) {
      setFieldErrors({
        ...fieldErrors,
        repeat_password: 'Confirm password does not match.'
      })
      return
    }

    setIsSendingAjax(true)
    executeRecaptcha('UPDATE_CUSTOMER_SETTINGS').then(gr => {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/users/customer/settings/`,
        method: 'post',
        data: {
          ...formValues,
          gr: gr,
        },
      }).then(r => {
        afterSubmit(r)
      })
    })
  }

  return <>
    <div className={'customer-dashboard'}>
      <div className={'customer-dashboard-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <Paper
              elevation={3}
              component={ValidatorForm}
              ref={form}
              onSubmit={handleSubmit}
              sx={{
                padding: 2,
                '& .MuiFormControl-root': {marginBottom: '30px'},
              }}
            >
              <Typography variant={'h4'} component={'h1'}>
                Settings
              </Typography>

              <br/>
              <Divider/>
              <br/>

              <TextValidator
                label={'Email'}
                placeholder={'Account Email'}
                name={'email'}
                variant={'outlined'}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailOutlinedIcon color={'secondary'}/>
                    </InputAdornment>
                  ),
                }}
                fullWidth={true}
                value={userInfo.email}
                {...getFieldErrorParams(fieldErrors, 'email')}
              />

              <Divider>Change Password</Divider>
              <br/>

              <TextValidator
                label={'Current Password'}
                placeholder={'Current Password'}
                name={'old_password'}
                variant={'outlined'}
                type="password"
                autocomplete={'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailOutlinedIcon color={'secondary'}/>
                    </InputAdornment>
                  ),
                }}
                fullWidth={true}
                onChange={handleChange}
                value={formValues.old_password}
                {...getFieldErrorParams(fieldErrors, 'old_password')}
              />

              <TextValidator
                label={'New Password'}
                placeholder={'New Password'}
                name={'new_password'}
                variant={'outlined'}
                autocomplete={false}
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailOutlinedIcon color={'secondary'}/>
                    </InputAdornment>
                  ),
                }}
                fullWidth={true}
                onChange={handleChange}
                value={formValues.new_password}
                {...getFieldErrorParams(fieldErrors, 'new_password')}
              />

              <TextValidator
                label={'Repeat Password'}
                placeholder={'Repeat Password'}
                name={'repeat_password'}
                variant={'outlined'}
                autocomplete={false}
                type="password"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailOutlinedIcon color={'secondary'}/>
                    </InputAdornment>
                  ),
                }}
                fullWidth={true}
                onChange={handleChange}
                value={formValues.repeat_password}
                {...getFieldErrorParams(fieldErrors, 'repeat_password')}
              />

              <LoadingButton
                sx={{
                  margin: '0 0 20px 0',
                }}
                color={'primary'}
                size={'large'}
                fullWidth={true}
                type={'submit'}
                variant="contained"
                startIcon={<SaveIcon/>}
                loading={isSendingAjax || !Boolean(executeRecaptcha)}
              >
                Save Changes
              </LoadingButton>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>

    <GetStarted
      title={'Place a New Order'}
      description={
        'Choose one of the options below to get started. ' +
        'Your fantastic likes, followers, views and comments are only a few clicks away!'
      }
      groupedCategories={groupedCategories}
    />
  </>
}

export default CustomerSettings
