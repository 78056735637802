import Navbar from "../../components/header/Navbar";
import Sidebar from "../../components/header/Sidebar";
import Footer from "../../components/footer/Footer";
import React from "react";
import CustomerSignUp from "../../components/customer_auth/CustomerSignUp";


const CustomerSignUpPage = () => {
  return <>
    <Navbar/>
    <Sidebar/>
    <CustomerSignUp/>
    <Footer/>
  </>
}

export default CustomerSignUpPage
