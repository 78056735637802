import {createTheme} from "@mui/material/styles";
import {
  pink,
  orange,
  deepPurple,
} from "@mui/material/colors";
import {alpha} from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: deepPurple,
    secondary: orange,
    text: {
      // disabled: "rgba(0, 0, 0, 0.38)",
      // primary: "rgba(0,0,0,1)",
      // secondary: "rgba(255,255,255,1)",
      light: "rgba(255,255,255,1)",
      lightSecondary: "rgba(255,255,255,0.80)",
    },
  },
});

// console.log(theme.palette.primary.main)
// console.log(theme.palette.primary);

export const baseStyles = {
  ".navbar-btn": {
    color: "text.light",
    marginRight: "10px",
    display: "none",
  },
  ".navbar-sidebar-btn": {
    display: "none",
    color: "text.light",
  },
  ".MuiToolbar-root": {
    [theme.breakpoints.up("md")]: {
      width: "1100px",
      maxWidth: "95%",
      margin: "auto",
    },
  },
  ".header-logo": {
    maxWidth: "100%",
    maxHeight: "40px",
  },
  ".hero": {
    background: alpha(theme.palette.primary.main, 0.8),
    width: "100%",
    textAlign: "center",
    overflow: "hidden",
    position: "relative",
  },
  ".hero-container": {
    margin: "80px 20px 60px 20px",
    maxWidth: "1000px",
    display: "inline-block",
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
      textAlign: "center",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  ".hero-background": {
    position: "absolute",
    top: "0px",
    left: "0px",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    zIndex: "-500",
    filter: "blur(5px)",
  },
  ".hero-phone-img-container": {
    [theme.breakpoints.down("md")]: {
      float: "center",
    },
    [theme.breakpoints.up("md")]: {
      float: "right",
    },
    textAlign: "center",
    position: "relative",
    display: "inline-block",
    width: "70%",
  },
  ".hero-phone-img": {
    width: "100%",
    maxWidth: "400px",
    position: "relative",
    // display: 'none'
  },
  ".live-stream-hearts-img": {
    position: "absolute",
    margin: "0 auto 0 auto",
    left: "-3px",
    bottom: "5%",
    right: 0,
    width: "50%",
    maxWidth: "150px",
    opacity: "0.2",
  },
  ".hero-text-container": {
    color: "text.light",
  },
  ".hero-text-1": {
    marginBottom: '20px',
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
      fontSize: 48,
    },
  },
  ".hero-text-2": {
    fontSize: 18,
    marginBottom: '30px',
  },
  ".hero-button-container": {
    marginBottom: "20px",
  },
  ".hero-button": {
    margin: "0 10px 10px 0",
  },
  ".sin-divider-bottom": {
    width: "100%",
    position: "relative",
    right: "0",
    bottom: "-4px",
  },
  ".sin-divider-top": {
    width: "100%",
    position: "relative",
    right: "0",
    top: "0",
  },
  ".our-features": {
    position: "relative",
    width: "100%",
    textAlign: "center",
  },
  ".our-features-container": {
    margin: "60px 20px 40px 20px",
    maxWidth: "1000px",
    display: "inline-block",
  },
  ".our-features-head": {
    marginBottom: "10px",
  },
  ".our-features-box": {
    maxWidth: "300px",
    minHeight: "300px",
    backgroundColor: "white",
    textAlign: "center",
    display: "inline-block",
    margin: "20px",
    boxShadow:
      "0 1px 30px 0 rgba(0, 0, 0, 0.1), 0 10px 50px 0 rgba(0, 0, 0, 0.1)",
  },
  ".our-features-box-icon": {
    width: "40%",
    display: "inline-block",
    margin: "20px 0 0 0",
    objectFit: "contain",
  },
  ".how-it-works": {
    background: alpha(theme.palette.primary.main, 0.8),
    textAlign: "center",
    color: "text.light",
    position: "relative",
  },
  ".how-it-works-container": {
    margin: "60px 20px 40px 20px",
    maxWidth: "800px",
    display: "inline-block",
  },
  ".how-it-works-item": {
    margin: "0 20px 0 20px",
    padding: "25px 0 25px 0",
  },
  ".how-it-works-img": {
    width: "40%",
    maxWidth: "100px",
    margin: "25px 0 0 0",
  },
  ".how-it-works-img-grid-left": {
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  ".how-it-works-img-grid-right": {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
  ".how-it-works-text": {
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  ".get-started": {
    textAlign: "center",
  },
  ".get-started-container": {
    margin: "0px 20px 30px 20px",
    maxWidth: "1000px",
    display: "inline-block",
  },
  ".get-started-box": {
    maxWidth: "300px",
    minHeight: "250px",
    backgroundColor: "white",
    textAlign: "center",
    display: "inline-block",
    margin: "20px",
    boxShadow:
      "0 1px 30px 0 rgba(0, 0, 0, 0.1), 0 10px 50px 0 rgba(0, 0, 0, 0.1)",
  },
  ".get-started-box-icon": {
    width: "30%",
    display: "inline-block",
    margin: "20px 0 0 0",
    borderRadius: "20px",
  },
  ".customer-dashboard": {
    textAlign: "center",
    padding: "40px 20px 50px 20px",
  },
  ".customer-dashboard-container": {
    width: "960px",
    maxWidth: "100%",
    display: "inline-block",
  },
  ".footer": {
    backgroundColor: alpha(theme.palette.primary.main, 0.9),
    textAlign: "center",
  },
  ".footer-container": {
    margin: "40px 0px 40px 0px",
    textAlign: "left",
    maxWidth: "1200px",
    display: "inline-block",
    color: "text.light",
  },
  ".footer-col": {
    margin: "20px 30px 20px 30px",
  },
  ".payment-card-img": {
    height: "50px",
    marginRight: "10px",
  },
  ".pricing": {
    width: "100%",
    textAlign: "center",
    position: "relative",
  },
  ".pricing-container": {
    width: "100%",
    maxWidth: "1000px",
    display: "inline-block",
    padding: "60px 0 30px 0",
  },
  ".pricing-head": {
    margin: "0 20px 40px 20px",
  },
  ".pricing-box": {
    display: "inline-block",
    margin: "10px 10px 50px 10px",
    width: "300px",
    maxWidth: "90%",
  },
  ".pricing-box-discount": {
    backgroundColor: pink[400],
    display: "inline-block",
    padding: "5px 15px 5px 15px",
    color: "text.light",
    borderRadius: "5px 5px 0 0",
  },
  ".pricing-box-header": {
    backgroundColor: alpha(theme.palette.primary.main, 0.9),
    color: "text.light",
    padding: "20px 0 0 0",
  },
  ".pricing-box-content": {
    textAlign: "left",
    padding: "20px 13% 20px 13%",
  },
  ".pricing-box-content-line": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "15px 0 15px 0",
  },
  ".pricing-box-content-line-icon": {
    color: theme.palette.secondary[500],
    marginRight: "10px",
  },
  ".pricing-box-price": {
    textAlign: "center",
    margin: "20px 15px 20px 15px",
  },
  ".pricing-box-buy-button": {},
  ".swiper-button-prev": {
    color: theme.palette.primary[500],
    [theme.breakpoints.up("md")]: {
      left: "0",
    },
  },
  ".swiper-button-next": {
    color: theme.palette.primary[500],
    [theme.breakpoints.up("md")]: {
      right: "0",
    },
  },
  ".swiper-pagination-bullet-active": {
    background: theme.palette.primary[500],
  },
  ".enter-details": {
    textAlign: "center",
  },
  ".enter-details-container": {
    textAlign: "left",
    display: "inline-block",
    width: "fill-available",
    maxWidth: "600px",
    margin: "10px",
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      margin: "90px 10px 50px 10px",
    },
  },
  ".enter-details-header": {
    textAlign: "center",
    padding: "5px 0 20px 0",
  },
  ".enter-details-content": {
    padding: "30px 0 0 0",
  },
  ".select-post-content": {
    padding: "20px 0 0 0",
    textAlign: "center",
  },
  ".image-picker-container": {
    margin: "10px 0 5px 0",
  },
  ".image-picker-item": {
    margin: "5px",
    position: "relative",
  },
  ".image-picker-item-img": {
    position: "relative",
    borderRadius: "10px",
    width: "100%",
    zIndex: 1,
    objectFit: 'cover',
    objectPosition: 'center center',
  },
  ".image-picker-item-overlay": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    backgroundColor: "rgba(0,0,0,0.70)",
    zIndex: 2,
    width: "100%",
    height: "100%",
    position: "absolute",
    color: "text.light",
  },
  ".order-checkout-page": {
    width: "100%",
    textAlign: "center",
    position: "relative",
  },
  ".order-checkout-page-container": {
    width: "100%",
    maxWidth: "1000px",
    display: "inline-block",
  },
  ".order-checkout-container": {
    float: "left",
    width: "fill-available",
  },
  ".order-summary-container": {
    float: "right",
    display: "block",
    width: "fill-available",
  },
  ".order-summary-account-info": {
    display: "flex",
    alignItems: "center",
    margin: "20px 0 20px 0",
  },
  ".order-summary-account-info-avatar": {
    width: "50px",
    borderRadius: "50px",
    display: "inline",
    margin: "0 10px 0 0",
  },
  ".order-summary-account-info-user": {
    display: "inline",
  },
  ".order-summary-order-info": {
    display: "flex",
    alignItems: "center",
    margin: "20px 0 20px 0",
  },
  ".order-summary-order-info-icon": {
    width: "50px",
    display: "inline",
    margin: "0 15px 0 0",
    textAlign: "center",
  },
  ".order-summary-price": {
    margin: "20px 0 20px 0",
  },
  ".order-checkout-payment-card-img": {
    height: "30px",
    margin: "0 5px 0 0",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 0 5px",
    },
  },
  ".order-checkout-payment-card-container": {
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  ".term-of-service": {
    textAlign: "center",
  },
  ".term-of-service-container": {
    display: "inline-block",
    textAlign: "left",
    maxWidth: "700px",
    margin: "20px",
  },
  ".support": {
    position: "relative",
    width: "100%",
    textAlign: "center",
  },
  ".support-container": {
    display: "inline-block",
    maxWidth: "600px",
  },
  ".faq-container": {
    padding: "10px",
    [theme.breakpoints.up("sm")]: {
      margin: "50px 0 20px 0",
    },
  },
  ".contact-container": {
    padding: "0 10px 10px 10px",
    [theme.breakpoints.up("sm")]: {
      margin: "0px 0 20px 0",
    },
  },
  ".order-success-page": {
    width: "100%",
    textAlign: "center",
    position: "relative",
  },
  ".order-success-page-container": {
    width: "100%",
    maxWidth: "1000px",
    display: "inline-block",
  },
  overrides: {
    MUIRichTextEditor: {
      root: {
        marginTop: 200,
        width: "80%"
      },
      editor: {
        border: "1px solid gray"
      }
    }
  },

  ".subscription-summary-container": {
    textAlign: "left",
    display: "inline-block",
    width: "fill-available",
    maxWidth: "800px",
    // margin: "10px",
    padding: "20px",
    // [theme.breakpoints.up("sm")]: {
    //   margin: "90px 10px 50px 10px",
    // },
  },
};
