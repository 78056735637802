import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import PackageProviderRow from "./PackageProviderRow"
import {BACKEND_URL} from "../../../data/config"
import {useGlobalContext} from "../../../contexts/global_context"
import {useEffect, useState} from "react"
import {IconButton} from "@mui/material"
import AddIcon from '@mui/icons-material/Add'
import Grid from "@mui/material/Grid"

const PackageProviderTable = ({formValues, setFormValues}) => {
  let {sendAjax} = useGlobalContext()
  let [providers, setProviders] = useState([])

  useEffect(() => {
    // get categories
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/admin/providers/`,
      method: 'get',
      data: {
        page_size: 1000,
      },
    }).then(r => {
      if (r.data.status_code === 200) {
        setProviders(r.data.data.results)
      }
    })
  }, [])

  const addRow = () => {
    formValues.package_providers.push({
      uid: null,
      provider: null,
      quantity: '',
      display_quantity: '',
    })
    setFormValues({...formValues, package_providers: formValues.package_providers})
  }

  const removeRow = (index) => {
    formValues.package_providers.splice(index, 1)
    setFormValues({...formValues, package_providers: formValues.package_providers})
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{minWidth: 500}}>
        <TableHead>
          <TableRow>
            <TableCell align="center" width="50%">PROVIDER</TableCell>
            <TableCell align="center" width="20%">QUANTITY</TableCell>
            <TableCell align="center" width="20%">DISPLAY QUANTITY</TableCell>
            <TableCell align="center" width="10%">ACTIONS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formValues.package_providers.map((package_provider, index) => {
            return (
              <PackageProviderRow
                key={index}
                {...{providers, index, formValues, setFormValues, removeRow}}
              />
            )
          })}
        </TableBody>
      </Table>

      <Grid container>
        <Grid item xs={12} sm={12} md={12} sx={{textAlign: 'center', margin: '10px'}}>
          <IconButton
            size="large"
            color={'primary'}
            onClick={addRow}
            variant={'outlined'}
          >
            <AddIcon/>
          </IconButton>
        </Grid>
      </Grid>
    </TableContainer>
  )
}

export default PackageProviderTable
