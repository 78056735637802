import Box from "@mui/material/Box";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import SearchContainer from "../SearchContainer";
import React, {useEffect, useState} from "react";
import {Autocomplete, Grid} from "@mui/material"
import MenuItem from "@mui/material/MenuItem";
import {BACKEND_URL} from "../../../data/config";
import {useGlobalContext} from "../../../contexts/global_context";


const OrderListFilters = ({fetchData, handleChange, filters, handleComboBoxChange}) => {
  const {sendAjax} = useGlobalContext()
  const [categories, setCategories] = useState([])
  const [packages, setPackages] = useState([])

  useEffect(() => {
    // fetch categories
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/admin/categories/`,
      method: 'get',
      data: {
        page_size: 1000,
      },
    }).then(r => {
      if (r.data.status_code === 200) {
        setCategories(r.data.data.results)
      }
    })

    // fetch packages
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/admin/packages/`,
      method: 'get',
      data: {
        page_size: 1000,
      },
    }).then(r => {
      if (r.data.status_code === 200) {
        setPackages(r.data.data.results)
      }
    })
  }, [])

  return (
    <SearchContainer>
      <Box
        component={ValidatorForm}
        onSubmit={fetchData}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Invoice Number'}
              placeholder={'Invoice Number'}
              name={'invoice_number'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.invoice_number}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label={'Status'}
              name={'status'}
              variant={'outlined'}
              fullWidth={true}
              value={filters.status}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                multiple: true,
                value: filters.status,
                onChange: handleChange,
              }}
            >
              <MenuItem value={10}>Waiting for Payment</MenuItem>
              <MenuItem value={20}>In Placement Queue</MenuItem>
              <MenuItem value={40}>Accepted</MenuItem>
              <MenuItem value={50}>Started</MenuItem>
              <MenuItem value={60}>Completed</MenuItem>
              <MenuItem value={70}>Refunded</MenuItem>
              <MenuItem value={80}>Failed</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Autocomplete
              multiple
              disablePortal
              name={'category'}
              options={categories.map(category => category.uid)}
              onChange={handleComboBoxChange('category')}
              value={filters.category}
              getOptionLabel={(option) => {
                let category = categories.find((item) => item.uid === option)
                return category ? category.title : ''
              }}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name={'category'}
                  label="Category"
                  placeholder={'Category'}
                  value={filters.category}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <Autocomplete
              multiple
              disablePortal
              name={'pkg'}
              options={packages.map(item => item.uid)}
              onChange={handleComboBoxChange('pkg')}
              value={filters.pkg}
              getOptionLabel={(option) => {
                let pkg = packages.find((item) => item.uid === option)
                return pkg ? pkg.title : ''
              }}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name={'pkg'}
                  label="Packages"
                  placeholder={'Packages'}
                  value={filters.pkg}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Title'}
              placeholder={'Title'}
              name={'title'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.title}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Min Price'}
              placeholder={'Min Price'}
              name={'min_price'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.min_price}
              type={'number'}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Max Price'}
              placeholder={'Max Price'}
              name={'max_price'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.max_price}
              type={'number'}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label={'Social'}
              name={'social'}
              variant={'outlined'}
              fullWidth={true}
              value={filters.social}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                multiple: true,
                value: filters.social,
                onChange: handleChange,
              }}
            >
              <MenuItem value={10}>Instagram</MenuItem>
              <MenuItem value={20}>TikTok</MenuItem>
              <MenuItem value={30}>Clubhouse</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Email'}
              placeholder={'Email'}
              name={'email'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.email}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'IP'}
              placeholder={'IP'}
              name={'ip'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.ip}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Social Username'}
              placeholder={'Social Username'}
              name={'social_username'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.social_username}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Social Post'}
              placeholder={'Social Post'}
              name={'social_post'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.social_post}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label={'Service Type'}
              name={'service_type'}
              variant={'outlined'}
              fullWidth={true}
              value={filters.service_type}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                multiple: true,
                value: filters.service_type,
                onChange: handleChange,
              }}
            >
              <MenuItem value={10}>Likes</MenuItem>
              <MenuItem value={20}>Followers</MenuItem>
              <MenuItem value={30}>Comments</MenuItem>
              <MenuItem value={40}>Custom Comments</MenuItem>
              <MenuItem value={50}>Views</MenuItem>
              <MenuItem value={60}>Club Members</MenuItem>
              <MenuItem value={70}>Room Visitors</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label={'Provider Type'}
              name={'provider_type'}
              variant={'outlined'}
              fullWidth={true}
              value={filters.provider_type}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                multiple: true,
                value: filters.provider_type,
                onChange: handleChange,
              }}
            >
              <MenuItem value={10}>SocialPanel24</MenuItem>
              <MenuItem value={20}>Peakerr</MenuItem>
              <MenuItem value={30}>SmmRaja</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Service Code'}
              placeholder={'Service Code'}
              name={'service_code'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.service_code}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Provider Order ID'}
              placeholder={'Provider Order ID'}
              name={'provider_order_id'}
              variant={'outlined'}
              fullWidth={true}
              onChange={handleChange}
              value={filters.provider_order_id}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Button
              variant="outlined"
              startIcon={<SearchIcon/>}
              type={'submit'}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Box>
    </SearchContainer>
  )
}

export default OrderListFilters

