import Card from "@mui/material/Card";
import {CardHeader, Link} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Button from "@mui/material/Button";
import SupportAgentIcon from '@mui/icons-material/SupportAgent'

const Contact = () => {
  return (
    <div className={'contact-container'}>
      <Card elevation={5}>
        <CardHeader
          title={'Contact Us'}
          subheader={'Ask us if you have any questions.'}
        />
        <CardContent>
          <Button
            startIcon={<SupportAgentIcon/>}
            onClick={() => {window.tidioChatApi.open()}}
            variant="contained"
            color={'primary'}
          >
            Chat with us
          </Button>

          <Typography variant={'body1'} component={'div'} sx={{margin: '20px 0 10px 0'}}>
            OR
          </Typography>

          <Typography variant={'p'} component={'div'}>
            Send email to <Link href="mailto:support@liketank.com">support@liketank.com</Link>
          </Typography>

        </CardContent>
      </Card>
    </div>
  )
}

export default Contact
