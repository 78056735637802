import BillingInfoForm from "../../order_checkout/BillingInfoForm";
import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom";
import {useTheme} from "@emotion/react";
import {Divider, useMediaQuery} from "@mui/material";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useGlobalContext} from "../../../contexts/global_context";
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../../../data/head";
import Navbar from "../../header/Navbar";
import Sidebar from "../../header/Sidebar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DoneIcon from '../../../assets/img/done-icon.svg'


const CustomerSubscriptionSuccess = ({handleClose}) => {
  const navigate = useNavigate()
  const theme = useTheme()

  return <>
    {/* SEO */}
    <Helmet>
      <title>Thank You for Your Payment! - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} md={12}>
        <Paper elevation={5} sx={{padding: 5, textAlign: 'center'}}>
          <div style={{textAlign: 'center', marginBottom: '20px'}}>
            <img
              src={DoneIcon}
              alt={'Done'}
              style={{
                width: '110px',
                height: '110px',
              }}
            />
          </div>

          <Typography variant="h5" component="div" sx={{textAlign: 'center'}}>
            Your subscription has been confirmed!
          </Typography>

          <Divider sx={{margin: '20px'}}/>

          <Typography variant="subtitle1" component="div" sx={{textAlign: 'center'}}>
            You will see the results after posting a new content on your page.
          </Typography>

          <br/>

          <Button
            variant="outlined"
            onClick={handleClose}
          >
            Got it!
          </Button>
        </Paper>
      </Grid>
    </Grid>
  </>
}

export default CustomerSubscriptionSuccess
