import CustomerSettings from "../../../components/customer_panel/settings/CustomerSettings";
import Navbar from "../../../components/header/Navbar";
import Sidebar from "../../../components/header/Sidebar";
import Footer from "../../../components/footer/Footer";
import React from "react";


const CustomerSettingsPage = () => {
  return <>
    <Navbar/>
    <Sidebar/>
    <CustomerSettings/>
    <Footer/>
  </>
}

export default CustomerSettingsPage
