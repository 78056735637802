import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import React from "react";

const ConfirmDialog = ({message, confirmBtnText, onConfirm, onClose, isOpen, zIndex = 1000}) => {
  return (
    <Dialog
      sx={{
        zIndex: zIndex,
      }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      {/* <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Delete Item?
        </DialogContentText>
      </DialogContent> */}
      <DialogActions>
        <Button
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          autoFocus
        >
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
