import {useParams} from "react-router-dom"
import Navbar from "../components/header/Navbar"
import Sidebar from "../components/header/Sidebar"
import Footer from "../components/footer/Footer"
import React, {useEffect, useState} from "react"
import Grid from "@mui/material/Grid";
import SinDividerFooter from "../assets/img/sin-divider-footer.svg";
import {useGlobalContext} from "../contexts/global_context";
import {BACKEND_URL, DEBUG} from "../data/config";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {Divider} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../data/head";
import DoneIcon from '../assets/img/done-icon.svg'
import {orange} from "@mui/material/colors";


const getOrderPrice = () => {
  let price = 0
  try {
    price = parseFloat(JSON.parse(localStorage.getItem("order_info")).price)
  } catch (e) {
    price = 0
  }
  return price
}


const getOrderInvoice = () => {
  let invoice = "0"
  try {
    invoice = JSON.parse(localStorage.getItem("order_info")).invoice_number
  } catch (e) {
    invoice = "0"
  }
  return invoice
}


const getOrderEmail = () => {
  let email = ""
  try {
    email = JSON.parse(localStorage.getItem("order_info")).email
  } catch (e) {
    email = ""
  }
  return email
}


const getOrderSlug = () => {
  let slug = ""
  try {
    slug = JSON.parse(localStorage.getItem("order_info")).package_slug
  } catch (e) {
    slug = ""
  }
  return slug
}


const SuccessPaymentPage = () => {
  let {orderUid} = useParams()
  let [order, setOrder] = useState({})
  let {sendAjax} = useGlobalContext()

  useEffect(() => {
    // microsoft ads
    window.uetq = window.uetq || []
    window.uetq.push('event', 'order_success_payment', {"revenue_value": getOrderPrice(), "currency": "USD"})

    if (DEBUG) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/commerce/orders/fake-pay/${orderUid}/`,
        method: 'post',
        data: {},
        successMessage: '',
      })
    }

    sendAjax({
      url: `${BACKEND_URL}/api/v1/commerce/orders/${orderUid}/`,
      method: 'get',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        setOrder(r.data.data)
      }
    })
  }, [orderUid])

  return <>
    {/* SEO */}
    <Helmet>
      <title>Thank You for Your Payment! - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <Navbar/>
    <Sidebar/>

    <div className={'order-success-page'}>
      <div className={'order-success-page-container'}>
        <div className={'enter-details'}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={12} md={6}>
              <Paper className={'enter-details-container'} elevation={5}>
                <div style={{textAlign: 'center', marginBottom: '20px'}}>
                  <img
                    src={DoneIcon}
                    alt={'Done'}
                    style={{
                      width: '110px',
                      height: '110px',
                    }}
                  />
                </div>

                <Typography variant="h5" component="div" sx={{textAlign: 'center'}}>
                  Thank You for Your Payment!
                </Typography>

                <Divider sx={{margin: '20px'}}/>

                <Typography variant="h6" component="div" sx={{textAlign: 'center'}}>
                  Invoice Number:
                </Typography>

                <Typography variant="h6" component="div" sx={{textAlign: 'center'}} color={orange[900]}>
                  {order.invoice_number}
                </Typography>

                <Divider sx={{margin: '20px'}}/>

                <Typography variant="subtitle1" component="div" sx={{textAlign: 'center'}}>
                  We will process you order as soon as possible.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>

      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>

    <Footer/>
  </>
}

export default SuccessPaymentPage
