import Navbar from "../../../components/header/Navbar";
import Sidebar from "../../../components/header/Sidebar";
import Footer from "../../../components/footer/Footer";
import React, {useEffect, useState} from "react";
import CustomerBillingList from "../../../components/customer_panel/billing/CustomerBillingList";
import {useLocation, useParams} from 'react-router-dom'


const CustomerBillingListPage = () => {
  const location = useLocation()
  const [isOpenForm, setIsOpenForm] = useState(false)
  const {uid} = useParams()

  useEffect(() => {
    if (location.pathname === '/dashboard/billing/create' || uid) {
      setIsOpenForm(true)
    } else {
      setIsOpenForm(false)
    }
  }, [location.pathname])

  return <>
    <Navbar/>
    <Sidebar/>
    <CustomerBillingList
      isOpenForm={isOpenForm}
      updatingUID={uid}
    />
    <Footer/>
  </>
}

export default CustomerBillingListPage
