import * as React from 'react';
import {Grid} from "@mui/material";
import OrderHistoryLogo from '../../../assets/img/customer_dashboard/orders.png'
import BillingLogo from '../../../assets/img/customer_dashboard/billing.png'
import SettingsLogo from '../../../assets/img/customer_dashboard/settings.png'
import AutoServicesLogo from '../../../assets/img/customer_dashboard/auto.png'
import CustomerDashboardBox from "./CustomerDashboardBox";
import GetStarted from "../../get_started/GetStarted";
import {useGlobalContext} from "../../../contexts/global_context";
import Typography from "@mui/material/Typography";


const CustomerDashboard = () => {
  let {groupedCategories, sendAjax} = useGlobalContext()

  return <>
    <div className={'customer-dashboard'}>
      <div className={'customer-dashboard-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={'our-features-head'}>
              <Typography variant={'h4'} component={'h1'}>
                Dashboard
              </Typography>

              <br/>

              <Typography variant="p" color={'text.secondary'}>
                Welcome to your dashboard! You can see your order history, subscriptions, billing information
                and your account settings.
              </Typography>

              <br/>
              <br/>
            </div>
          </Grid>

          <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={9} md={12}>
              <Grid container justifyContent={'center'} spacing={2}>
                <Grid item xs={6} sm={6} md={3}>
                  <CustomerDashboardBox
                    title={'Order History'}
                    icon={OrderHistoryLogo}
                    link={'/dashboard/orders'}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <CustomerDashboardBox
                    title={'Subscriptions'}
                    icon={AutoServicesLogo}
                    link={'/dashboard/subscriptions'}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <CustomerDashboardBox
                    title={'Billing'}
                    icon={BillingLogo}
                    link={'/dashboard/billing'}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                  <CustomerDashboardBox
                    title={'Settings'}
                    icon={SettingsLogo}
                    link={'/dashboard/settings'}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>

    <GetStarted
      title={'Buy Packages'}
      description={
        'Choose one of the options below to get started. ' +
        'Your fantastic likes, followers, views and comments are only a few clicks away!'
      }
      groupedCategories={groupedCategories}
    />
  </>
}

export default CustomerDashboard
