import * as React from 'react';
import {Grid, Link as MuiLink} from "@mui/material";
import GetStarted from "../../get_started/GetStarted";
import {useGlobalContext} from "../../../contexts/global_context";
import Typography from "@mui/material/Typography";
import CustomerOrdersTable from "./CustomerOrdersTable";
import {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import {BACKEND_URL} from "../../../data/config";
import moment from "moment";


const CustomerOrderList = () => {
  let {groupedCategories, sendAjax} = useGlobalContext()
  const [page, setPage] = useState(0)
  const [data, setData] = useState({})
  let items = []
  let numTotal = 0

  const [filters, setFilters] = useState({})

  const fetchData = () => {
    const query = new URLSearchParams(filters)

    sendAjax({
      url: `${BACKEND_URL}/api/v1/commerce/customer/orders/?${query.toString()}`,
      method: "get",
      data: {page: page + 1},
    }).then((r) => {
      setData(r.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [page])

  try {
    for (let [index, item] of data.data.results.entries()) {
      let createdAt = moment(item.created_at)

      items.push({
        'uid': item.uid,
        'index': index + 1,
        'invoice_number': item.invoice_number,
        'title': item.title,
        'price': item.price,
        'status': item.status_display,
        'elapsed': createdAt.fromNow(),
        'date': createdAt.format('LLL'),
        'order_providers': item.order_providers,
      })
    }
    numTotal = data.data.count
  } catch {
  }

  return <>
    <div className={'customer-dashboard'}>
      <div className={'customer-dashboard-container'}>
        <Paper sx={{padding: '20px 0 0 0'}} elevation={2}>
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={'our-features-head'}>
                <Typography variant={'h4'} component={'h1'}>
                  Order History
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <CustomerOrdersTable
                items={items}
                numTotal={numTotal}
                page={page}
                setPage={setPage}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>

    <GetStarted
      title={'Place a New Order'}
      description={
        'Choose one of the options below to get started. ' +
        'Your fantastic likes, followers, views and comments are only a few clicks away!'
      }
      groupedCategories={groupedCategories}
    />
  </>
}

export default CustomerOrderList
