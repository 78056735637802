import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {
  DialogContent,
  IconButton,
  useMediaQuery
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "@emotion/react";
import {useNavigate} from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close'
import {useGlobalContext} from "../../../contexts/global_context";
import Box from "@mui/material/Box";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {getInputValue} from "../../../utils";
import CustomerSubscriptionDetailsForm from "./CustomerSubscriptionDetailsForm";
import {BACKEND_URL} from "../../../data/config";
import CustomerSubscriptionCheckoutForm from "./CustomerSubscriptionCheckoutForm";
import CustomerSubscriptionSuccess from "./CustomerSubscriptionSuccess";
import {baseStyles, theme} from "../../../assets/css/styles";
import CustomerConfirmDialog from "../dialog/CustomerConfirmDialog";
import {ValidatorForm} from "react-material-ui-form-validator";


const CustomerSubscriptionFormDialog = ({isOpenForm, updatingUID, fetchData}) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const {executeRecaptcha} = useGoogleReCaptcha()
  let {sendAjax, setIsSendingAjax, isSendingAjax, showAlert} = useGlobalContext()
  let [categories, setCategories] = useState([])
  let [packages, setPackages] = useState([])
  let [autoService, setAutoService] = useState({})
  const [step, setStep] = useState('DETAILS')
  const [fingerprint, setFingerprint] = useState('')
  let [fieldErrors, setFieldErrors] = useState({})
  const [showNewCardForm, setShowNewCardForm] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [showConfirmPrimaryCardDialog, setShowConfirmPrimaryCardDialog] = useState(false)
  const isConfirmedChangingPrimaryCard = useRef(false)
  let form = useRef()

  const formInitValues = {
    username: '',
    category: '',
    package: '',
    post_types: [10, 20, 30],
    payment_method: '',
    card_number: '',
    expire_date: '',
    cvv: '',
    first_name: '',
    last_name: '',
    billing_address: '',
    billing_zipcode: '',
    billing_country: '',
    fingerprint: '',
  }

  let [formValues, setFormValues] = useState(formInitValues)

  useEffect(() => {
    if (step === 'DETAILS') {
      if (updatingUID) {
        sendAjax({
          url: `${BACKEND_URL}/api/v1/subscription/customer/auto-services/${updatingUID}`,
          method: 'get',
          data: {},
        }).then((r) => {
          if (r.data.status_code === 200) {
            setAutoService(r.data.data)
          }
        })
      } else {
        setAutoService({})
      }
    }

  }, [updatingUID])

  useEffect(() => {
    if (step === 'DETAILS' && Object.keys(autoService).length) {
      let newFormValues = {
        ...formInitValues,
        category: categories.length ? autoService.category : '',
        package: packages.length ? autoService.package : '',
        post_types: autoService.post_types,
        username: autoService.username,
      }

      setFormValues(newFormValues)
    }
  }, [autoService, categories, packages])

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
    setFieldErrors({
      ...fieldErrors,
      [event.target.name]: '',
    })
  }

  const afterDetailsSend = (r) => {
    if (r.data.status_code === 200) {
      setAutoService(r.data.data)

      // save profile pic url
      localStorage.setItem('auto_service_info', JSON.stringify({
        username: r.data.data.link_info.value,
        profile_pic: r.data.data.link_info.image,
        link_title: r.data.data.link_info.title,
      }))

      // get the list of payment methods to check if the user has a primary payment method or not
      sendAjax({
        url: `${BACKEND_URL}/api/v1/payments/customer/payment-methods/`,
        method: 'get',
        data: {},
        successMessage: '',
      }).then(r => {
        if (r.data.status_code === 200) {
          setStep('CHECKOUT')
        }
      })
    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (!executeRecaptcha) {
      return
    }

    // DETAILS
    if (step === 'DETAILS') {
      setIsSendingAjax(true)

      executeRecaptcha('AUTO_SERVICE_DETAILS').then(gr => {
        if (updatingUID) {
          sendAjax({
            url: `${BACKEND_URL}/api/v1/subscription/customer/auto-services/${updatingUID}/`,
            method: 'put',
            data: {
              ...formValues,
              gr: gr,
            },
            successMessage: '',
          }).then(r => {
            afterDetailsSend(r)
          })
        } else {
          sendAjax({
            url: `${BACKEND_URL}/api/v1/subscription/customer/auto-services/`,
            method: 'post',
            data: {
              ...formValues,
              gr: gr,
            },
            successMessage: '',
          }).then(r => {
            afterDetailsSend(r)
          })
        }
      })
    }

    // CHECKOUT
    else if (step === 'CHECKOUT') {
      setIsSendingAjax(true)

      executeRecaptcha('CHECKOUT').then(gr => {
        // check whether a payment method is selected or not
        if (!showNewCardForm && !formValues.payment_method) {
          showAlert(
            'Please select a payment method to continue.',
            'error',
          )
          setIsSendingAjax(false)
          return
        }

        // check is the selected payment method is primary or not
        // if it isn't primary, show a waring that this will be used for all of your subscriptions
        let primaryPaymentMethod = paymentMethods.find(item => item.usage_type === 10)
        if (!isConfirmedChangingPrimaryCard.current && primaryPaymentMethod) {
          if (primaryPaymentMethod.uid !== formValues.payment_method) {
            setShowConfirmPrimaryCardDialog(true)
            setIsSendingAjax(false)
            return
          }
        }

        let expireDate = formValues.expire_date.replaceAll(' ', '')
        expireDate = `${expireDate.split('/')[0]}/20${expireDate.split('/')[1]}`

        let newFormValues = {
          ...formValues,
          expire_date: expireDate,
          card_number: formValues.card_number.split(" ").join(""),
          fingerprint: fingerprint,
          gr: gr,
        }

        sendAjax({
          url: `${BACKEND_URL}/api/v1/subscription/customer/auto-services/checkout/${autoService.uid}/`,
          method: 'post',
          data: newFormValues,
          successMessage: '',
        }).then(r => {
          if (r.data.status_code === 200) {
            setStep('SUCCESS')
          }
        })
      })
    }
  }

  const handleClose = () => {
    navigate('/dashboard/subscriptions', {replace: true})
    fetchData()

    setTimeout(
      () => {
        setStep('DETAILS')
        setFormValues(formInitValues)
        setAutoService({})
        setShowNewCardForm(false)
        isConfirmedChangingPrimaryCard.current = false
      },
      500,
    )
  }

  const renderForm = (step) => {
    if (step === 'DETAILS') {
      return (
        <CustomerSubscriptionDetailsForm
          formValues={formValues}
          setFormValues={setFormValues}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          categories={categories}
          setCategories={setCategories}
          packages={packages}
          setPackages={setPackages}
          setStep={setStep}
        />
      )
    } else if (step === 'CHECKOUT') {
      return (
        <CustomerSubscriptionCheckoutForm
          formValues={formValues}
          setFormValues={setFormValues}
          setFingerprint={setFingerprint}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          handleSubmit={handleSubmit}
          showNewCardForm={showNewCardForm}
          setShowNewCardForm={setShowNewCardForm}
          paymentMethods={paymentMethods}
          setPaymentMethods={setPaymentMethods}
          autoService={autoService}
          setStep={setStep}
        />
      )
    } else if (step === 'SUCCESS') {
      return (
        <CustomerSubscriptionSuccess
          handleClose={handleClose}
        />
      )
    }

    return ''
  }

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        maxWidth={'md'}
        open={isOpenForm}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {updatingUID ? 'Edit Subscription' : 'New Subscription'}

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon/>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box
            component={ValidatorForm}
            onSubmit={handleSubmit}
            ref={form}
            sx={{
              ...baseStyles,
              paddingTop: 1,
              '& .MuiTextField-root': {marginBottom: '30px'},
            }}
          >
            {renderForm(step)}
          </Box>
        </DialogContent>
      </Dialog>

      <CustomerConfirmDialog
        message={'Change the Primary Card?'}
        subtitle={
          'This card will be set as your primary payment method, ' +
          'and it will be used for all of your subscriptions on LikeTank. ' +
          'You can change your primary card in Dashboard -> Billing section.'
        }
        cancelBtnText={'Cancel'}
        confirmBtnText={'Continue'}
        onConfirm={() => {
          isConfirmedChangingPrimaryCard.current = true
          document.getElementById('id_checkout_form_submit_btn').click()
          setShowConfirmPrimaryCardDialog(false)
        }}
        onClose={() => {
          setShowConfirmPrimaryCardDialog(false)
        }}
        isOpen={showConfirmPrimaryCardDialog}
        zIndex={2000}
      />
    </>
  )
}

export default CustomerSubscriptionFormDialog
