import Navbar from "../../components/header/Navbar";
import Sidebar from "../../components/header/Sidebar";
import Footer from "../../components/footer/Footer";
import React from "react";
import ResetPasswordStep1 from "../../components/auth/ResetPasswordStep1";


const ResetPasswordStep1Page = () => {
  return <>
    <Navbar/>
    <Sidebar/>
    <ResetPasswordStep1/>
    <Footer/>
  </>
}

export default ResetPasswordStep1Page
