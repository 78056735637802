import Navbar from "../components/header/Navbar";
import Sidebar from "../components/header/Sidebar";
import React from "react";
import Footer from "../components/footer/Footer";
import SinDividerFooter from "../assets/img/sin-divider-footer.svg";
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../data/head";

const PrivacyPolicyPage = () => {
  return <>
    {/* SEO */}
    <Helmet>
      <title>Privacy Policy - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <Navbar/>
    <Sidebar/>
    <div className="term-of-service">
      <div className={'term-of-service-container'}>
        <h1>Privacy Policy</h1>
        <section>
          <p><i>Published on Oct 10, 2021</i></p>
          <h2>1. General Provisions</h2>
          <p><strong>1.1</strong> This document uses the same terminology and abbreviations as our Terms of Service
            document. This Privacy Policy governs the collection, use and storage of Information obtained from Users
            through the use of our Site. </p>
          <p><strong>1.2</strong> The Information on Users is divided into personally identifiable and
            non-personally identifiable Information depending on whether Information can identify the User as a
            specific person. Information about legal persons does not fall within the scope of Information as
            defined in our Terms of Service and Privacy Policy documents.</p>
        </section>
        <section>
          <h2>2. Collection and Use of Personally Identifiable Information</h2>
          <p><strong>2.1</strong> We may obtain some of your personal Information in order to provide our Services.
            The Information we collect is necessary to provide our Services, and we shall not collect any
            Information which is not required by the nature of our Services. We may collect some of the following
            personal Information:</p>
          <p>
            <i>E-mail address</i> <br/><br/>
            <i>Instagram username</i> <br/><br/>
            <i>IP address</i></p>

          <p><strong>(A) Email address</strong></p>
          <p><strong>2.2</strong> We will collect your email address on a basis of your consent and in the interest
            of providing our Services. We will collect your email address in the following cases:</p>
          <p>
            <strong>- When you register an account with us.</strong> We will collect your email address to create
            and facilitate an account for you and to send you notifications about your account such as when you
            forget your password, we change our privacy policy, or if there is a data breach. Account is necessary
            to access some of our Services and if you do not provide us with your email address for this purpose you
            will not be able to access these Services. There will be a consent box during your registration by which
            you can provide your consent to have us collect, store, and process your email address in accordance
            with this Privacy Policy. <br/><br/>
            <strong>- When you place an order, make payments or use free trial.</strong> We collect your email
            address in order to process your order and charge your PayPal account for the cost of the order. We also
            collect your email when you choose to use a free trial for our marketing purposes. There will be a
            consent box during your registration for free trial by which you can provide your consent to have us
            collect, store, and process your email address in accordance with this Privacy Policy. <br/><br/>
            <strong>- When you subscribe to receive notifications.</strong> We will collect your email address when
            you subscribe to receive notifications from us. We will only use your email address to send you
            information about the upcoming events or other matters relating to the LikeTank Services. There will
            be a consent box during your subscription by which you can provide your consent to have us collect,
            store, and process your email address in accordance with this Privacy Policy. <br/><br/>
            <strong>- When you contact us.</strong> We will collect your email address whenever you contact us
            through our email support@liketank.com. Whenever we collect your email address this way, we will only
            use it in order to respond to your inquiry.</p>

          <p><strong>2.3</strong> We use a Third-Party data processor for sending the emails called SendGrid, Inc
            and for support ticketing called Zendesk, Inc. We may transfer your email address to SendGrind and
            Zendesk to process your Information on our behalf. SendGrind and Zendesk have a position of a “data
            processor” within the meaning of the EU GDPR while we still remain data controllers with regard to your
            Information. SendGrid and Zendesk adhere to the US-EU Privacy Shield Framework and Swiss-U.S. Privacy
            Shield Framework. You can find their policies and procedures with regard to your Information on the
            following links https://sendgrid.com/policies/privacy/services-privacy-policy/,
            https://www.zendesk.com/company/customers-partners/privacy-policy/</p>

          <p><strong>(B) Instagram username</strong></p>
          <p><strong>2.4 We will collect your Instagram username in order to providing our Services and to execute
            our contractual obligation. When you choose to register for our Services we will collect your Instagram
            username in order to deliver the requested ‘likes’.</strong></p>

          <p><strong>(C) IP Address</strong></p>
          <p><strong>2.5</strong> We integrate security systems on our Site to prevent malicious attempts and
            exploits of the Site. We scan IP address and ban IP addresses that show malicious signs such as too many
            password failures, seeking exploits and similar. We process this information in the interest of
            protecting the integrity of the Site and of the databases connected with it, including database of
            Information obtained from Users. We use your IP address on a basis of a legitimate interest as well in
            order to provide an invoice for our Services.</p>

          <p><strong>(D) General</strong></p>
          <p><strong>2.6</strong> Some Services will not be available to you if you do not provide requested
            Information. We may keep records of any questions, complaints or compliments made by you and the
            response if any. Whenever you contact us, we shall collect any information which you chose to provide.
            We shall store and use this information only for the purpose of responding to your inquiries.
            Information contained within the inquiry, free from any personally identifiable Information, will be
            stored on our servers for the purpose of improving our Services and providing the best customer support
            possible.</p>
          <p><strong>2.7</strong> We do not sell or rent personal Information to any Third-Party. We use collected
            Information for our internal and marketing purposes, as necessary by the nature of the Services, and
            only in accordance with this Privacy Policy. In some instances, we are obliged to comply with court
            orders and government requests and provide Information or parts of it to authorized bodies.</p>
          <p><strong>2.8</strong> We have implemented security procedures and measures in order to ensure
            appropriate protection of the Information we process, against any misuse, unauthorized access,
            disclosure or modification. We will remove any Information about you upon your request no later than
            twenty four (24) hours after the request has been made.</p>
          <p><strong>2.9</strong> We acknowledge that the safety of your Information is one of the highest
            priorities and therefore only authorized processors have access to your information. Although we take
            all appropriate measures in respect to keeping your information secure, you understand that no data
            security measures in the world can offer 100% protection. If we ever find or suspect a personal data
            breach we will without delay, within seventy-two (72) hours after becoming aware of it, notify the
            appropriate supervisory authority about the breach and Users where necessary.</p>
          <p><strong>2.10</strong> The processing of the information is being performed automatically, without human
            intervention. However, whenever you contact us through email, the Information within the email will be
            handled and processed by a real human in order to provide you with the answer to the email.</p>
          <p><strong>2.11</strong> Users may request access to, modification and erasure of personal Information and
            the exercise of the right to object. Such requests are submitted to support@liketank.com.</p>
        </section>
        <section>
          <h2>3. Collection and Use of Non-Personally Identifiable Information</h2>
          <p><strong>3.1</strong> The Site collects a series of general data and information when a User or
            automated system calls up the Site. This general data and information are stored in the server log
            files. Collected may be (1) the browser types and versions used, (2) the operating system used by the
            accessing system, (3) the website from which an accessing system reaches our website (so-called
            referrers), (4) the sub-websites, (5) the date and time of access to the Internet site, (6) the Internet
            service provider of the accessing system, and (7) any other similar data and information that may be
            used in the event of attacks on our information technology systems.</p>
          <p><strong>3.2</strong> We collect this information for breach investigation purposes. When using this
            information, we do not draw any conclusions about the User. Rather, this information is needed to (1)
            deliver the content of our Site correctly, (2) optimize the content of our Site as well as its
            advertisement, (3) ensure the long-term viability of our information technology systems and website
            technology, and (4) provide law enforcement authorities with the information necessary for criminal
            prosecution in case of a cyber-attack. The anonymous data of the server log files are stored separately
            from all personal data provided by a data subject.</p>
        </section>
        <section>
          <h2>4. Storage and transfer of Information</h2>
          <p><strong>4.1</strong> Information will be stored on our secure servers located in Sweden. Servers are
            controlled and maintained in accordance with sufficient privacy safeguards. We may store or transfer
            Information to servers located in countries deemed adequate by the European Commission, or in countries
            which European Commission has not deemed inadequate, provided that such countries implement appropriate
            and suitable safeguards regarding the security of personal information.</p>
          <p><strong>4.2</strong> Information is stored on the servers only for the duration necessary for providing
            the Services and maintaining the integrity of our databases. We will maintain records of processing
            activities for the purpose of demonstrating compliance with EU GDPR.</p>
        </section>
        <section>
          <h2>5. Information retention period</h2>
          <p><strong>5.1</strong> If you register an account with us we will store your Information for as long as
            you have an active account. As soon as you delete your account we will remove all Information we have
            collected about you. We may keep your information for up to 30 days in our backup in order to maintain
            the integrity of our backup storage data.</p>
          <p><strong>5.2</strong> If you do not have an account with us, but have chosen to provide some personal
            Information, we will delete this information after two weeks of obtaining such Information.</p>
        </section>
        <section>
          <h2>6. User’s Rights</h2>
          <p><strong>6.1</strong> User may request to: </p>
          <p>
            <i>Receive disclosure if your Personal Information is processed, and access your stored Personal
              Information as well as supplementary information. </i> <br/><br/>
            <i>Receive a copy of Personal Information you directly volunteer to LikeTank in a structured, commonly
              used and machine-readable format. </i> <br/><br/>
            <i>Correct your Personal Information that can be influenced by LikeTank.</i> <br/><br/>
            <i>Delete your Personal Information. </i> <br/><br/>
            <i>Object to the processing of Personal Information by LikeTank. </i> <br/><br/>
            <i>Restrict the processing of your Personal Information by LikeTank.</i> <br/><br/>
            <i>File a complaint with a supervisory authority.</i></p>
          <p><strong>6.2</strong> These rights might be limited if they are subject to LikeTank’s own legitimate
            interests and regulatory requirements. </p>
        </section>
        <section>
          <h2>7. Cookies and similar technologies</h2>
          <p><strong>7.1</strong> Cookies help us optimize and improve the user experience of the Site by helping us
            deliver crucial functionalities. The cookies we use may vary over time as we continuously update and
            improve our Site.</p>
          <p><strong>7.2</strong> By visiting the Site we may use cookies to store some non-identifiable Information
            in your browser regarding your computer or mobile device and your activities in order to help improve
            the User experience. Users can deny access to cookies through their browser settings.</p>
        </section>
        <section>
          <h2>8. Third Party Websites, Services, and Cookies</h2>
          <p><strong>8.1</strong> The Site or e-mails may contain links to other external websites that do not fall
            under our domain. We are not responsible for the privacy practices or the content of such external
            websites. If you choose to follow such links to external websites, you do so at your risk.</p>
          <p><strong>8.2</strong> LikeTank Site uses third-party services, such as Google Analytics. Google
            Analytics is used to monitor and analyze web traffic and to keep track of User behavior. <a
              href="https://support.google.com/analytics/answer/2763052?hl=en" target="_blank">IP
              Anonymization</a> feature is enabled in Google Analytics for privacy reasons. Any data collected will
            be used in accordance with our Privacy Policy and Google's Privacy Policy. More information on how users
            may opt out of Google Analytics's use of cookies can be found at the <a
              href="https://tools.google.com/dlpage/gaoptout/" target="_blank">Google Analytics Opt-out Page</a>.
            For more information about cookies used by Google Analytics please see the original document: <a
              href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              target="_blank">Google Analytics Cookie Usage on Websites</a>.</p>
          <p><strong>8.3</strong> We use Google AdWords remarketing codes to log when users view specific pages or
            take specific actions on a website. This allows us to provide targeted advertising in the future. If you
            do not wish to receive this type of advertising from us in the future you can opt out by following the
            instructions provided here https://support.google.com/ads/answer/2662922?hl=en.</p>
          <p><strong>8.4</strong> Most of the Payments on the Site are conducted through Third Party service
            provider PayPal (Europe) S.à r.l. et Cie, S.C.A. (R.C.S. Luxembourg B 118 349). You hereby understand
            that by making a purchase through the Site your Information is being processed and handled in accordance
            with PayPal’s Privacy Policy available here https://www.paypal.com/ie/webapps/mpp/ua/privacy-full.</p>
        </section>
        <section>
          <h2>9. Changes to the Privacy Policy</h2>
          <p><strong>9.1</strong> We reserve the right to change our Privacy Policy at any time. The current version
            of Privacy Policy is available on the Site, indicating the effective date. You are encouraged to
            periodically check our Privacy Policy.</p>
        </section>
        <section>
          <h2>10. Contact Information</h2>
          <p><strong>10.1</strong> If you have any queries or concerns regarding our Privacy Policy and how the
            information is handled, or you wish to access, retrieve, amend, or update your Information feel free to
            contact us at support@liketank.com</p>
        </section>
      </div>
      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>

    <Footer/>
  </>
}

export default PrivacyPolicyPage
