import {useNavigate, useParams} from "react-router-dom"
import Navbar from "../components/header/Navbar"
import Sidebar from "../components/header/Sidebar"
import Footer from "../components/footer/Footer"
import React, {useEffect, useState} from "react"
import OrderCheckout from "../components/order_checkout/OrderCheckout"
import OrderSummary from "../components/order_checkout/OrderSummary"
import Grid from "@mui/material/Grid";
import SinDividerFooter from "../assets/img/sin-divider-footer.svg";
import {useGlobalContext} from "../contexts/global_context";
import {BACKEND_URL, DEBUG} from "../data/config";
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../data/head";

const EnterDetailsPage = () => {
  let {orderUid} = useParams()
  let [order, setOrder] = useState({})
  let {sendAjax} = useGlobalContext()
  const navigate = useNavigate()

  useEffect(() => {
    // if (DEBUG && orderUid) {
    //   history.push(`/order/success/${orderUid}`)
    // }

    sendAjax({
      url: `${BACKEND_URL}/api/v1/commerce/orders/${orderUid}/`,
      method: 'get',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        setOrder(r.data.data)
      }
    })
  }, [orderUid])

  return <>
    {/* SEO */}
    <Helmet>
      <title>Checkout - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <Navbar/>
    <Sidebar/>

    <div className={'order-checkout-page'}>
      <div className={'order-checkout-page-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={5} order={{xs: 1, sm: 1, md: 2}}>
            <OrderSummary order={order}/>
          </Grid>

          <Grid item xs={12} sm={12} md={7} order={{xs: 2, sm: 2, md: 1}}>
            <OrderCheckout order={order}/>
          </Grid>
        </Grid>
      </div>

      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>

    <Footer/>
  </>
}

export default EnterDetailsPage
