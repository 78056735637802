import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {Button, DialogContent, DialogContentText} from "@mui/material";
import React from "react";

const CustomerConfirmDialog = (
  {
    message,
    subtitle,
    cancelBtnText,
    confirmBtnText,
    onConfirm,
    onClose,
    isOpen,
    zIndex = 1000,
  }
) => {
  return (
    <Dialog
      sx={{
        zIndex: zIndex,
      }}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
      {subtitle ? <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {subtitle}
        </DialogContentText>
      </DialogContent> : ''}
      <DialogActions>
        <Button
          onClick={onClose}
        >
          {cancelBtnText}
        </Button>
        <Button
          onClick={onConfirm}
          autoFocus
        >
          {confirmBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomerConfirmDialog
