import Button from "@mui/material/Button"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import {Link} from "react-router-dom"
import {ListItemIcon, ListItemText} from "@mui/material"
import {getIconByCategoryTitle} from "../../utils"
import * as React from "react"

const HeroMenuButton = ({title, icon, items}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        startIcon={icon}
        endIcon={<ArrowDropDownIcon/>}
        color={'secondary'}
        variant="contained"
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={'hero-button'}
      >
        {title}
      </Button>
      <Menu
        disableScrollLock={true}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {items.map(item => {
          return (
            <MenuItem
              component={Link}
              to={`/categories/${item.slug}`}
              onClick={handleClose}
              key={item.uid}
            >
              <ListItemIcon>{getIconByCategoryTitle(item.title)}</ListItemIcon>
              <ListItemText>{item.title}</ListItemText>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

export default HeroMenuButton
