import {Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {Link, useNavigate} from "react-router-dom"
import MuiLink from '@mui/material/Link'
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import SinDividerFooter from "../../assets/img/sin-divider-footer.svg";
import {useEffect, useRef, useState} from "react";
import {grey} from "@mui/material/colors";
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../../data/head";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {getInputValue} from "../../utils";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {BACKEND_URL} from "../../data/config";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useGlobalContext} from "../../contexts/global_context";


const Login = () => {
  let form = useRef();

  const {executeRecaptcha} = useGoogleReCaptcha()
  const navigate = useNavigate();
  let {sendAjax, isSendingAjax, setIsSendingAjax, userInfo, setUserInfo} = useGlobalContext();
  let [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    // check is logged in
    if (userInfo && userInfo.email) {
      if (userInfo.is_staff) {
        window.location.pathname = `/admin/orders/`
      } else {
        window.location.pathname = `/dashboard/`
      }
    }
  }, [userInfo])

  const handleSubmit = () => {
    if (!executeRecaptcha) {
      return
    }

    setIsSendingAjax(true)

    executeRecaptcha('LOGIN').then(gr => {
      sendAjax({
        url: BACKEND_URL + "/api/v1/users/csrf/",
        method: "post",
        data: {},
        successMessage: "",
      }).then(() => {
          setIsSendingAjax(true)

          setTimeout(() => {
            sendAjax({
              url: BACKEND_URL + "/api/v1/users/login/",
              method: "post",
              data: {
                ...formValues,
                gr: gr,
              },
              successMessage: "",
            }).then((r) => {
              if (r && r.data.status_code === 200) {
                setUserInfo(r.data.data)
              }
            })
          }, 100)
        }
      );
    })
  };

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
  }

  return <>
    {/* SEO */}
    <Helmet>
      <title>Sign Up - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <div className={'enter-details'}>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={'enter-details-container'} elevation={5}>
            <div className={'enter-details-header'}>
              <Typography variant="h4" component="h1" gutterBottom>
                Login
              </Typography>

              <Typography color={grey[600]} variant="body1" gutterBottom>
                New to LikeTank? <MuiLink component={Link} to={'/signup'}>Sign up</MuiLink>
              </Typography>
            </div>

            <Divider/>

            <div className={'enter-details-content'}>
              <Box
                component={ValidatorForm}
                ref={form}
                onSubmit={handleSubmit}
                debounceTime={1500}
                sx={{
                  '& .MuiTextField-root': {marginBottom: '30px'},
                }}
              >
                <TextValidator
                  label={'Email address'}
                  placeholder={'Email address'}
                  name={'email'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon color={'secondary'}/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required', 'isEmail']}
                  errorMessages={['This field is required', 'Email is not valid']}
                  onChange={handleChange}
                  value={formValues.email}
                />

                <TextValidator
                  label={'Password'}
                  placeholder={'Password'}
                  name={'password'}
                  variant={'outlined'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOpenIcon color={'secondary'}/>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth={true}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={handleChange}
                  value={formValues.password}
                  type={"password"}
                />

                <div style={{margin: '-10px 0 20px 0'}}>
                  <MuiLink component={Link} to={'/reset-password-step-1'}>
                    Forgot password?
                  </MuiLink>
                </div>

                <LoadingButton
                  sx={{
                    margin: '0 0 20px 0',
                  }}
                  color={'primary'}
                  size={'large'}
                  fullWidth={true}
                  type={'submit'}
                  variant="contained"
                  endIcon={<DoubleArrowIcon/>}
                  loading={isSendingAjax || !Boolean(executeRecaptcha)}
                >
                  Login
                </LoadingButton>
              </Box>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>
  </>
}

export default Login
