import * as React from 'react'
import Grid from "@mui/material/Grid"
import heroPhoneImg from '../../assets/img/hero-phone.png'
import liveStreamHeart from '../../assets/img/live-stream-hearts.gif'
import Typography from '@mui/material/Typography'
import HeroBackgroundImg from '../../assets/img/hero-background.jpeg'
import HeroBackgroundVideo from '../../assets/video/hero-background.mp4'
import HeroMenuButton from "./HeroMenuButton"
import {getIconBySocial} from "../../utils"
import SinDividerWhiteImg from '../../assets/img/sin-divider-white.svg'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Button from "@mui/material/Button"
import {
  scroller
} from "react-scroll";
import {orange} from '@mui/material/colors'

const Hero = ({groupedCategories, category}) => {
  const scrollToPricing = () => {
    scroller.scrollTo("pricing-scroll-element", {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuint",
      offset: -70,
    });
  }

  return (
    <div className={'hero'}>
      <video
        preload={'true'}
        autoPlay={true}
        loop={true}
        muted={true}
        poster={HeroBackgroundImg}
        className={'hero-background'}
        playsInline={true}
      >
        <source src={HeroBackgroundVideo} type="video/mp4"/>
      </video>

      <div className={'hero-container'}>
        <Grid className={'hero-grid'} container>
          <Grid item xs={12} sm={12} md={6}>
            <div className={'hero-text-container'}>
              {category ?
                <div>
                  <Typography className={'hero-text-1'} variant="h3" component="h1">
                    Buy {category.title}
                  </Typography>

                  <Typography variant="h5" gutterBottom component="h4" color={orange[100]}>
                    {category.short_description}
                  </Typography>


                  <Typography variant="subtitle1" gutterBottom component="p" color={'text.lightSecondary'}>
                    {category.long_description}
                  </Typography>

                  <br/>

                  {groupedCategories ?
                    groupedCategories.map((item, index) => {
                      return (
                        <HeroMenuButton
                          key={index}
                          title={`${item.social_display} Packages`}
                          icon={getIconBySocial(item.social_display)}
                          items={item.categories}
                        />
                      )
                    }) :
                    <div className={'hero-button-container'}>
                      <Button
                        startIcon={<ShoppingCartIcon/>}
                        color={'secondary'}
                        variant="contained"
                        onClick={scrollToPricing}
                        className={'hero-button'}
                        size={'large'}
                      >
                        Buy Now
                      </Button>
                    </div>
                  }
                </div>

                :

                <div>
                  <Typography className={'hero-text-1'} variant="h3" component="h1">
                    Buy Real Likes, Followers, Views and Comments
                  </Typography>

                  <Typography className={'hero-text-2'} variant="subtitle1" component="p">
                    Boost your popularity in social media by LikeTank services.
                    We provide fast, reliable, real and safe services.
                  </Typography>

                  <div className={'hero-button-container'}>
                    {groupedCategories ?
                      groupedCategories.map((item, index) => {
                        return (
                          <HeroMenuButton
                            key={index}
                            title={`${item.social_display} Packages`}
                            icon={getIconBySocial(item.social_display)}
                            items={item.categories}
                          />
                        )
                      })
                      :
                      <Button
                        startIcon={<ShoppingCartIcon/>}
                        color={'secondary'}
                        variant="contained"
                        onClick={scrollToPricing}
                        className={'hero-button'}
                        size={'large'}
                      >
                        Buy Now
                      </Button>
                    }
                  </div>
                </div>
              }
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={'hero-phone-img-container'}>
              <img className={'hero-phone-img'} src={heroPhoneImg} alt={'Phone'}/>
              <img className={'live-stream-hearts-img'} src={liveStreamHeart} alt={'Phone'}/>
            </div>
          </Grid>
        </Grid>
      </div>

      <img className={'sin-divider-bottom'} src={SinDividerWhiteImg} alt={''}/>
    </div>
  )
}

export default Hero
