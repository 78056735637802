import * as React from 'react';
import {Divider, Grid} from "@mui/material";
import GetStarted from "../../get_started/GetStarted";
import {useGlobalContext} from "../../../contexts/global_context";
import Typography from "@mui/material/Typography";
import CustomerSubscriptionTable from "./CustomerSubscriptionsTable";
import {useEffect, useState} from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import {Link} from "react-router-dom";
import CustomerSubscriptionFormDialog from "./CustomerSubscriptionFormDialog";
import {BACKEND_URL} from "../../../data/config";
import moment from "moment";


const CustomerBillingList = ({isOpenForm, updatingUID}) => {
  let {groupedCategories, sendAjax} = useGlobalContext()
  const [page, setPage] = useState(0)
  const [data, setData] = useState({})
  const [filters, setFilters] = useState({})
  let items = []
  let numTotal = 0

  const fetchData = () => {
    const query = new URLSearchParams(filters)

    sendAjax({
      url: `${BACKEND_URL}/api/v1/subscription/customer/auto-services/?${query.toString()}`,
      method: "get",
      data: {page: page + 1},
    }).then((r) => {
      setData(r.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [page])

  try {
    for (let [index, item] of data.data.results.entries()) {
      let createdAt = moment(item.created_at)

      items.push({
        ...item,
        'index': index + 1,
        'date': createdAt.format('LLL'),
      })
    }
    numTotal = data.data.count
  } catch {
  }

  return <>
    <CustomerSubscriptionFormDialog
      isOpenForm={isOpenForm}
      updatingUID={updatingUID}
      fetchData={fetchData}
    />

    <div className={'customer-dashboard'}>
      <div className={'customer-dashboard-container'}>
        <Paper sx={{padding: '20px 10px 0 10px'}} elevation={3}>
          <Grid container justifyContent={'center'}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant={'h4'} component={'h1'}>
                Subscriptions
              </Typography>

              <br/>
              <Divider/>
              <br/>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={3} sx={{textAlign: 'left'}}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon/>}
                  color={'success'}
                  component={Link}
                  to={'/dashboard/subscriptions/create'}
                >
                  New Subscription
                </Button>
              </Grid>

              <Grid item xs={12} sm={12} md={9} sx={{textAlign: 'left'}}>
                Click on the "NEW SUBSCRIPTION" button to add an
                <b> automatic like / view / comment service </b>
                for your Instagram page.
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <br/>
              <Divider/>
              <CustomerSubscriptionTable
                items={items}
                numTotal={numTotal}
                page={page}
                setPage={setPage}
                fetchData={fetchData}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>

    <GetStarted
      title={'Place a New Order'}
      description={
        'Choose one of the options below to get started. ' +
        'Your fantastic likes, followers, views and comments are only a few clicks away!'
      }
      groupedCategories={groupedCategories}
    />
  </>
}

export default CustomerBillingList
