import InputAdornment from "@mui/material/InputAdornment"
import React, {useEffect, useRef, useState} from "react"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined'
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem"
import {useNavigate, useParams} from "react-router-dom"
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import {useGlobalContext} from "../../../contexts/global_context";
import {BACKEND_URL} from "../../../data/config";
import LoadingButton from '@mui/lab/LoadingButton'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import {Helmet} from "react-helmet";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {red} from "@mui/material/colors";
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {getFieldErrorParams} from "../../../utils";
import {ListItemText, OutlinedInput} from "@mui/material";


const CustomerSubscriptionDetailsForm = (
  {
    formValues,
    setFormValues,
    fieldErrors,
    setFieldErrors,
    handleSubmit,
    handleChange,
    categories,
    setCategories,
    packages,
    setPackages,
    setStep,
  }
) => {
  const {executeRecaptcha} = useGoogleReCaptcha()

  let [category, setCategory] = useState({})
  let [pkg, setPkg] = useState({})
  let {sendAjax, setIsSendingAjax, isSendingAjax} = useGlobalContext()
  const navigate = useNavigate()
  const {uid} = useParams()

  useEffect(() => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/categories/`,
      method: 'get',
      data: {
        social: 10,
        enable_auto_service: true,
        page_size: 1000,
      },
    }).then(r => {
      if (r.data.status_code === 200) {
        setCategories(r.data.data.results)
      }
    })
  }, [])

  useEffect(() => {
    setPackages([])
    setFormValues({...formValues, package: ''})

    if (formValues.category) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/packages/`,
        method: 'get',
        data: {
          category__uid: formValues.category,
          page_size: 1000,
        },
      }).then(r => {
        if (r.data.status_code === 200) {
          setPackages(r.data.data.results)
        }
      })
    }
  }, [formValues.category])

  useEffect(() => {
    if (formValues.category) {
      setCategory(categories.find(item => item.uid === formValues.category) || {})
    }
  }, [formValues.category, categories])

  useEffect(() => {
    if (formValues.package) {
      setPkg(packages.find(item => item.uid === formValues.package) || {})
    }
  }, [formValues.package, packages])

  return <>
    {/* SEO */}
    <Helmet>
      <title>LikeTank</title>
    </Helmet>

    <Box
      sx={{
        'width': '700px',
        'maxWidth': '100%',
        '& .MuiFormControl-root': {marginBottom: '30px'},
      }}
    >
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <TextValidator
            select
            label={'Automatic Service'}
            name={'category'}
            variant={'outlined'}
            fullWidth={true}
            validators={['required']}
            errorMessages={['This field is required']}
            {...getFieldErrorParams(fieldErrors, 'category')}
            onChange={handleChange}
            value={formValues.category}
          >
            {categories.map(item => {
              return <MenuItem key={item.uid} value={item.uid}>Auto {item.title}</MenuItem>
            })}
          </TextValidator>

          <TextValidator
            select
            label="For Each New..."
            name={'post_types'}
            variant={'outlined'}
            fullWidth={true}
            {...getFieldErrorParams(fieldErrors, 'post_types')}
            value={formValues.post_types}
            InputLabelProps={{
              shrink: true,
            }}
            SelectProps={{
              multiple: true,
              value: formValues.post_types,
              onChange: handleChange,
              renderValue: (selected) => {
                let titles = []
                selected.map(item => {
                  if (item === 10) {
                    titles.push('Photo Post')
                  } else if (item === 20) {
                    titles.push('Video Post')
                  } else if (item === 30) {
                    titles.push('Album Post')
                  } else if (item === 40) {
                    titles.push('Reel')
                  }
                })
                return titles.join(', ')
              }
            }}
          >
            <MenuItem value={10}>
              <Checkbox checked={formValues.post_types.includes(10)}/>
              <ListItemText primary={'Photo Post'}/>
            </MenuItem>

            <MenuItem value={20}>
              <Checkbox checked={formValues.post_types.includes(20)}/>
              <ListItemText primary={'Video Post'}/>
            </MenuItem>

            <MenuItem value={30}>
              <Checkbox checked={formValues.post_types.includes(30)}/>
              <ListItemText primary={'Album Post'}/>
            </MenuItem>

            <MenuItem value={40} disabled>
              <Checkbox checked={formValues.post_types.includes(40)}/>
              <ListItemText primary={'Reel (Coming soon...!)'}/>
            </MenuItem>
          </TextValidator>

          <TextValidator
            select
            label="Package"
            name={'package'}
            variant={'outlined'}
            fullWidth={true}
            validators={['required']}
            errorMessages={['This field is required']}
            {...getFieldErrorParams(fieldErrors, 'package')}
            onChange={handleChange}
            value={formValues.package}
            disabled={!packages.length}
          >
            {packages && packages.map(item => {
              return <MenuItem key={item.uid} value={item.uid}>{item.title}</MenuItem>
            })}
          </TextValidator>

          <TextValidator
            label={category.link_name ? category.link_name : 'Username'}
            placeholder={category.link_description ? category.link_description : 'Username'}
            name={'username'}
            variant={'outlined'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailOutlinedIcon color={'secondary'}/>
                </InputAdornment>
              ),
            }}
            fullWidth={true}
            validators={['required']}
            errorMessages={['This field is required']}
            onChange={handleChange}
            value={formValues.username}
          />

          {formValues.package &&
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{
                margin: '-10px 0 20px 0',
              }}
            >
              You'll be charged <b style={{color: red[500]}}>${pkg.price} </b>
              for <b style={{color: red[500]}}>each new post </b>
              on the above {category.social_display} account.
              You can cancel your subscription anytime you want.
            </Typography>
          }

          <LoadingButton
            sx={{
              margin: '0 0 20px 0',
            }}
            color={'primary'}
            size={'large'}
            fullWidth={true}
            type={'submit'}
            variant="contained"
            endIcon={<DoubleArrowIcon/>}
            loading={isSendingAjax || !Boolean(executeRecaptcha)}
          >
            Continue
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  </>
}

export default CustomerSubscriptionDetailsForm
