import {useNavigate, useParams} from 'react-router-dom'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import React, {useEffect, useRef, useState} from "react"
import {getFieldErrorParams, getInputValue} from "../../../utils"
import AdminContainer from "../../../components/admin/AdminContainer"
import {Autocomplete, Chip, Grid} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import {useGlobalContext} from "../../../contexts/global_context"
import {BACKEND_URL} from "../../../data/config"
import Divider from "@mui/material/Divider"
import PackageProviderTable from "../../../components/admin/package/PackageProviderTable"
import {Helmet} from "react-helmet";

const ProviderCreateUpdateAdminPage = () => {
  const navigate = useNavigate()
  let {uid} = useParams()
  let form = useRef()
  let {sendAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})
  let [categories, setCategories] = useState([])

  useEffect(() => {
    // get categories
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/admin/providers/`,
      method: 'get',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        setCategories(r.data.data.results)
      }
    })

    // get package
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/providers/${uid}/`,
        method: 'get',
        data: {},
      }).then((r) => {
        if (r.data.status_code === 200) {
          let provider = r.data.data
          setFormValues({
            title: provider.title,
            service_type: provider.service_type,
            provider_type: provider.provider_type,
            service_code: provider.service_code,
            min_quantity: provider.min_quantity,
            max_quantity: provider.max_quantity,
            gender: provider.gender,
          })
        }
      })
    }
  }, [])

  let [formValues, setFormValues] = useState({
    title: '',
    service_type: '',
    provider_type: '',
    service_code: '',
    min_quantity: '',
    max_quantity: '',
    gender: '',
  })

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
      navigate("/admin/providers/")
    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/providers/${uid}/`,
        method: 'put',
        data: formValues,
      }).then(
        afterSubmit
      )
    } else {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/providers/`,
        method: 'post',
        data: formValues,
      }).then(
        afterSubmit
      )
    }
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
    setFieldErrors({})
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>Providers - LikeTank</title>
      </Helmet>

      <Box
        component={ValidatorForm}
        ref={form}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Title'}
              placeholder={'Title'}
              name={'title'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.title}
              {...getFieldErrorParams(fieldErrors, 'title')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label="Service Type"
              name={'service_type'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.service_type}
              {...getFieldErrorParams(fieldErrors, 'service_type')}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={10}>Likes</MenuItem>
              <MenuItem value={20}>Followers</MenuItem>
              <MenuItem value={30}>Comments</MenuItem>
              <MenuItem value={50}>Views</MenuItem>
              <MenuItem value={60}>Club Members</MenuItem>
              <MenuItem value={70}>Room Visitors</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label="Provider Type"
              name={'provider_type'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.provider_type}
              {...getFieldErrorParams(fieldErrors, 'provider_type')}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={10}>SocialPanel24</MenuItem>
              <MenuItem value={20}>Peakerr</MenuItem>
              <MenuItem value={30}>SmmRaja</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Service Code'}
              placeholder={'Service Code'}
              name={'service_code'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.service_code}
              {...getFieldErrorParams(fieldErrors, 'service_code')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Min Quantity'}
              placeholder={'Min Quantity'}
              name={'min_quantity'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.min_quantity}
              type={'number'}
              {...getFieldErrorParams(fieldErrors, 'min_quantity')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Max Quantity'}
              placeholder={'Max Quantity'}
              name={'max_quantity'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.max_quantity}
              type={'number'}
              {...getFieldErrorParams(fieldErrors, 'max_quantity')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label="Gender"
              name={'gender'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.gender}
              {...getFieldErrorParams(fieldErrors, 'gender')}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={10}>Any Gender</MenuItem>
              <MenuItem value={20}>Female</MenuItem>
              <MenuItem value={30}>Male</MenuItem>
            </TextValidator>
          </Grid>
        </Grid>

        <Button
          color={'primary'}
          type={'submit'}
          variant="contained"
          sx={{
            margin: '20px 0 500px 0',
          }}
          onClick={() => {
          }}
        >
          Save
        </Button>
      </Box>
    </AdminContainer>
  )
}

export default ProviderCreateUpdateAdminPage
