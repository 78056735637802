import Navbar from "../components/header/Navbar";
import Sidebar from "../components/header/Sidebar";
import React from "react";
import SinDividerFooter from "../assets/img/sin-divider-footer.svg";
import Footer from "../components/footer/Footer";
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../data/head";


const RefundPolicyPage = () => {
  return <>
    {/* SEO */}
    <Helmet>
      <title>Refund Policy - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <Navbar/>
    <Sidebar/>
    <div className="term-of-service">
      <div className={'term-of-service-container'}>
        <h1>Refund Policy</h1>
        <p><i>Published on Oct 10, 2021</i></p>

        <section>
          <p>Since liketank.com is offering non-tangible irrevocable goods, <em><strong>we do not issue refunds once
            the order is accomplished and the product is sent</strong></em>. As a customer, you are responsible for
            understanding this upon purchasing any item at our site.</p>
          <p>However, we realize that exceptional circumstance can take place with regard to the character of the
            product we supply.</p>
        </section>

        <section>
          <p>Therefore, we DO honor requests for the refund on the following reasons:</p>
        </section>

        <section>
          <p>
            <strong>1. Non-delivery of the product:</strong>
            <br/>
            in some cases, the process times are slower, and it
            may take a little longer for your orders to finish. In this case, we recommend contacting us for
            assistance. Claims for non-delivery must be submitted to our <a href={'/support'}>support
            department</a> in writing within 72 hours from the order placing the date. Otherwise, the campaign
            will be considered completed. If your order delivery has started, then the delivery will be finished
            as soon as possible and no refunds will be issued once your order has started processing.
          </p>
        </section>

        <section>
          <p>
            <strong>1. Product not-as-described:</strong>
            <br/>
            such issues should be reported to our <a href={'/support'}>support department</a> within 72
            hours from the date of the purchase. Clear evidence must be provided proving that the
            purchased product is not as it is described on the website. Complaints, which are based merely on the
            customer’s false expectations or wishes, are NOT honored. Our support team is always eager to assist
            you and deliver highly professional support in a timely manner. Thank you for purchasing from us.
          </p>
        </section>
      </div>
      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>
    <Footer/>
  </>
}

export default RefundPolicyPage
