import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'

const FeatureBox = ({title, description, icon}) => {
  return (
    <Card className={'our-features-box'}>
      <CardMedia
        className={'our-features-box-icon'}
        component="img"
        image={icon}
        alt="Fast Delivery"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2">
          {description}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default FeatureBox
