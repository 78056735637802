import Navbar from "../components/header/Navbar";
import Sidebar from "../components/header/Sidebar";
import React from "react";
import Footer from "../components/footer/Footer";
import SinDividerFooter from "../assets/img/sin-divider-footer.svg"
import {Helmet} from "react-helmet";
import {DEFAULT_META_DESCRIPTION} from "../data/head";

const TermOfServicePage = () => {
  return <>
    {/* SEO */}
    <Helmet>
      <title>Terms of Service - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <Navbar/>
    <Sidebar/>
    <div className="term-of-service">
      <div className={'term-of-service-container'}>
        <h1>Terms of Service Agreement</h1>
        <section>
          <p><i>Published on Oct 10, 2021</i></p>
          <p>This Terms of Service Agreement explains the terms and conditions under which you can use the Platform and
            Services provided by LikeTank. Please read this Terms of Service document carefully and keep a copy of it
            for your reference.</p>
          <p>BY USING OUR SERVICES OR ACCESSING ANY CONTENT THAT IS MADE AVAILABLE BY LIKETANK YOU EXPRESS YOUR
            AGREEMENT TO BE LEGALLY BOUND BY OUR TERMS OF SERVICE STATED IN THIS DOCUMENT.</p>
          <p>These Terms do not interfere with any obligation or authorization provided in any other agreement concluded
            between you and LikeTank</p>
        </section>
        <section>
          <h2>1. Definitions</h2>
          <p><strong>1.1</strong> The following definitions explain some of the terminology and abbreviations used
            throughout our Terms of Service Agreement:</p>
          <p>
            <strong>‘Terms/Agreement’</strong> refers to the latest version of this Terms of Service Agreement
            document. <br/><br/>
            <strong>‘Site’</strong> refers to the website of the LikeTank available
            at &lt; https://liketank.com/&gt; or any other URL which may host LikeTank websites or
            Services. <br/><br/>
            <strong>‘Platform’</strong> refers to Site and Services collectively. <br/><br/>
            <strong>‘User/You’</strong> refers to any person using or accessing our Platform. <br/><br/>
            <strong>‘We/Us/LikeTank’</strong> refers to LikeTank, the Site, and their partners and
            affiliates. <br/><br/>
            <strong>‘Privacy Policy’</strong> refers to Privacy Policy document governing the rules of collecting, using
            and storing information provided by Users. <br/><br/>
            <strong>‘Third-Party’</strong> refers to any application, website, natural or legal entity other than
            LikeTank. <br/><br/>
            <strong>‘Content’</strong> refers to all images, text, audio and video data or any other information located
            on the Platform. <br/><br/>
            <strong>‘User Data’</strong> refers to the Content provided by Users. <br/><br/>
            <strong>‘Services’</strong> refers to the services provided by LikeTank, through LikeTank as advertised
            on the Site. <br/><br/>
            <strong>‘Software’</strong> refers to the applications and functionalities provided with the
            Services. <br/><br/>
            <strong>‘Subscription Period’</strong> refers to the period of time for which your subscription with us is
            active. <br/><br/>
            <strong>‘Subscription Fee’</strong> refers to the cost of the Subscription for the selected Subscription
            Period. <br/><br/>
            <strong>‘Effective Date’</strong> refers to the date on which the payment of the Subscription Fee is
            processed. <br/><br/>
            <strong>‘Information’</strong> refers to information about the Users themselves provided for the purpose of
            using our Services, contacting support or anonymous statistical information collected from the Users through
            cookies or third-party services. <br/><br/>
            <strong>‘Confidential Information’</strong> refers to all information disclosed between the parties of this
            Agreement in relation to the Services, and especially, without limiting, Content available only for
            registered Users. Confidential Information does not cover information that was known to either party prior
            to disclosure, information that was made available to the public or information which is intended and
            disclosed for the purpose of publishing.</p>
        </section>
        <section>
          <h2>2. General Provisions</h2>
          <p><strong>(A) The Scope of the Services</strong></p>
          <p><strong>2.1</strong> LikeTank offers a Platform through which Users are able to utilize online Software,
            purchase social media ‘likes’, and other services fully described on the Site. Access to these Services is
            available upon registration which is done through proper forms on the Site.</p>
          <p><strong>2.2</strong> LikeTank cannot guarantee or warrant that the Services will meet your requirements
            or be available on an uninterrupted, secure, or error-free basis. LikeTank cannot and does not guarantee
            the 100% uptime for its Services. Services may be down (i) for scheduled maintenance, (ii) force majeure
            events, (iii) for specific Users because of the account suspension or termination, (iv) internet problems
            outside of LikeTank area of influence, (v) bugs in code, hardware or Services without a commercially know
            fix. LikeTank does not guarantee in any way the number of ‘likes’ a User may receive from the use of our
            Services.</p>
          <p><strong>2.3</strong> During the Subscription Period, LikeTank undertakes to provide customer support to
            the User as necessary and within its possibilities. User agrees that LikeTank is not responsible for
            issues whose cause does not come out of the Services, Software or Platform.</p>

          <p><strong>(B) Eligibility</strong></p>
          <p><strong>2.4</strong> By registering for the use of the Services, you confirm that you are at least 18 years
            of age. We may, in our sole discretion, refuse to offer the Services to any person or entity and change its
            eligibility criteria at any time. By using our Services, you confirm that (i) you are fully able and
            competent to enter into the terms, conditions, obligations, representations and warranties set forth in
            these Terms of Service and have full legal capacity to enter into a binding relation, (ii) that you will
            provide true, accurate, current, and complete information where requested, and information which is
            otherwise compatible with these Terms and maintain the accuracy of these information, (iii) that you will
            not use Services contrary to these Terms or applicable laws or regulations</p>
          <p><strong>2.5</strong> We may not control who uses the Platform, so it is upon you to assess whether using
            the Platform is in compliance with any local laws and regulations. Whenever you are using our Platform, you
            will need to comply with these Terms and any applicable laws, regulations, and policies. If any part of the
            Platform is not in compliance with your local laws, you may not use the Platform. Any such Service will be
            considered as ‘not available in your region.’</p>

          <p><strong>(C) Registration</strong></p>
          <p><strong>2.6</strong> During the registration process, you will be asked to provide some personal
            information, the collection, use, and storage of which is regulated by our Privacy Policy document and
            applicable laws. Users are required to provide true, accurate, current and complete information about
            themselves as prompted by forms provided. If you provide information contrary to aforementioned conditions,
            we may deny you or terminate your access to all or parts of our Services. We are not responsible for any
            failure in providing the Services which results from information that is not true, accurate, current and
            complete.</p>
          <p><strong>2.7</strong> You understand that it is your responsibility to keep your login information
            confidential. You are responsible for all activity under your account. If you ever find out or suspect that
            someone accessed your account without authorization, you are advised to inform us immediately.</p>

          <p><strong>(D) Individual orders</strong></p>
          <p><strong>2.8</strong> Users are able to place individual orders for our Services. Full list of available
            Services and prices is available on the Site. When you place an order you authorize us to charge the payment
            method you provided for the amount indicated at the checkout page. Upon payment of the order price,
            LikeTank will deliver the requested Service within reasonable time and with sufficient care. We reserve
            the right, with or without notice, to cancel or reduce the quantity of any order to be filled or ‘likes’ to
            be provided to you that may result in a violation of these Terms, as determined by us in our sole
            discretion.</p>

          <p><strong>(E) Subscription</strong></p>
          <p><strong>2.9</strong> Users are able to choose the duration of their Subscription Period. Subscription
            Period starts on the Effective Date, and ends on the same day in the month in which the selected duration of
            Subscription Period expires. In case the month in which the Subscription Period expires does not have the
            date which matches Effective Date, Subscription Period will end on the last day in that month.</p>
          <p><strong>2.10</strong> Subscription Period will automatically renew at the end of the current Subscription
            Period. Renewed Subscription Period will be by default for the same duration as the current Subscription
            Period. Subscription Fee for the Renewed Subscription Period will be calculated in accordance the prices
            that were in effect fifteen (15) days prior to the expiry of the current Subscription Period. If you wish to
            stop using our Services, you may discontinue automatic renewal option in your User account at least
            twenty-four (24) hours before the expiry of the current Subscription Period. While you may cancel any Paid
            Services at any time, you won't be issued a refund except in our sole discretion, or if legally required. If
            you want to discontinue using our Services you may do so only by turning off auto renewal, in which case you
            will be able to use the Services until the end of the current Subscription Period.</p>
          <p><strong>2.11</strong> LikeTank may offer a free trial period for using the Services or free access to
            Services which are not covered by the subscription plan. Any unused portion of a free trial period, if
            offered, will be forfeited when the User purchases a subscription.</p>

          <p><strong>(F) Contact</strong></p>
          <p><strong>2.12</strong> By allowing us access to your e-mail address, you agree that we may contact you using
            such contact information, for any matters relating to the Services (Service e-mails). These e-mails do not
            constitute “unsolicited commercial e-mail advertisements,” and you are not able to opt-out of receiving
            them. You may opt-in or subscribe to receive e-mails about content, promotions, special offers and or other
            topics of interest related to LikeTank and our affiliates (Promotional e-mails). You may choose to stop
            receiving these promotional e-mails at any time by following the instructions contained in promotional
            e-mails.</p>
          <p><strong>2.13</strong> If you have any question or suggestion you can contact us
            at <strong>support@liketank.com</strong>, or reach us at <strong>+46 8580 97233</strong>.</p>
        </section>
        <section>
          <h2>3. Acceptable Use Policy</h2>
          <p><strong>3.1</strong> You agree that you will not misuse our Platform. A misuse constitutes any use, access
            or interference with the Platform contrary to Terms, Privacy Policy and applicable laws and regulations. We
            can, in our sole discretion, suspend or terminate access to all or parts of the Platform to any User,
            without prior notice or need to deliberate on reasons for such measure. We reserve the right to deny
            Services to anyone at any time. During your use of our Platform, you will not behave contrary to the Terms,
            Policies, applicable laws and regulations, and you will especially not, without limitation, do any of the
            following:</p>
          <p>
            <i>(i)</i> send or otherwise post unauthorized commercial communications (such as spam) through the
            Platform; <br/><br/>
            <i>(ii)</i> collect Users' content or information, or otherwise access the Platform using automated means
            (such as harvesting bots, robots, spiders, or scrapers) without our permission; <br/><br/>
            <i>(iii)</i> upload viruses or other malicious code; <br/><br/>
            <i>(iv)</i> bully, intimidate, or harass any other User; <br/><br/>
            <i>(v)</i> post or transmit content which is illegal, hateful, obscene, threatening, incites violence,
            insulting, defamatory, infringing of intellectual property rights, invasive of privacy, or contains graphic
            or gratuitous violence or is otherwise objectionable to third parties; <br/><br/>
            <i>(vi)</i> harass, threaten, embarrass or cause distress or discomfort upon another individual or entity or
            impersonate any other person or entity or otherwise restricting or inhibiting any other person from using or
            enjoying the Platform; <br/><br/>
            <i>(vii)</i> take any action creating a disproportionately large usage load on our Platform unless expressly
            permitted by LikeTank; <br/><br/>
            <i>(viii)</i> post or transmit content that is misleading. <br/><br/>
            <i>(ix)</i> communicate any information or content that you do not have a right to make available under any
            law or under contractual or fiduciary relationships, or otherwise infringes or violates someone else's
            rights; <br/><br/>
            <i>(x)</i> encourage participation in or promote any contents, pyramid schemes, surveys, chain letters or
            spamming, or unsolicited emailing through the Platform; <br/><br/>
            <i>(xi)</i> post or transmit hyperlinks to other websites that violate these Terms; <br/><br/>
            <i>(xii)</i> facilitate or encourage any violation of these Terms.</p>
          <p><strong>3.2</strong> Users are solely responsible for their own User Data and the consequences of making
            the Data available to third-parties.</p>
          <p><strong>3.3</strong> If for any reason, your account, or any part thereof, is suspended, banned,
            restricted, blocked, terminated or otherwise disabled by LikeTank, you agree to abide by such decision.
            You may not create another account with the intent to bypass these limitations or attempt to circumvent any
            limitation imposed on your account without our permission. Any effort to evade these limitations may result
            in the termination of all current and future accounts you register.</p>
        </section>
        <section>
          <h2>4. Intellectual Property</h2>
          <p><strong>(A) Proprietary Rights</strong></p>
          <p><strong>4.1</strong> The copyright and all intellectual property rights in the Platform belong to
            LikeTank or are used with appropriate permissions. It includes design, all database rights, trademarks,
            text, graphics, code, file and links, service marks, and the selection and set up thereof. All rights are
            reserved. Nothing in this agreement shall be understood or intended for transfer of such intellectual
            property rights to you or any other third party.</p>
          <p><strong>4.2</strong> Subject to your compliance with this Agreement, we grant you a limited, non-exclusive,
            non-transferable, non-sub licensable license to access and use the Platform and the Software. Except as
            expressly permitted in this Agreement, you may not: copy, modify or create derivative works based on the
            Platform or Software; distribute, transfer, sublicense, lease, lend or rent the Platform or Software to any
            third party; reverse engineer, decompile or disassemble the Platform or Software; or make the functionality
            of the Platform or Software available to multiple users through any means.</p>

          <p><strong>(B) Notification of Infringement</strong></p>
          <p><strong>4.3</strong> If you believe that your work has been copied in a way that constitutes copyright
            infringement, or your intellectual property rights have been otherwise violated, please provide the
            following information to the Site’s Copyright Agent:</p>
          <p>
            <i>1.</i> An electronic or physical signature of the person authorized to act on behalf of the owner of the
            copyright or other intellectual property interest; <br/><br/>
            <i>2.</i> A description of the copyrighted work or other intellectual property that you claim has been
            infringed;
            <br/><br/>
            <i>3.</i> A description of where the material that you claim is infringing is located on the Site or the
            App;
            <br/><br/>
            <i>4.</i> Your name, address, telephone number and e-mail address; <br/><br/>
            <i>5.</i> A signed statement by you that you have a good faith belief that the disputed use is not
            authorized by the copyright owner, its agent, or the law; and
            <br/><br/>
            <i>6.</i> A statement by you, made under penalty of perjury, that the information provided in your
            Notice is accurate and that you are the copyright or intellectual property owner or authorized to act
            on the copyright or intellectual property owner’s behalf.
          </p>

          <p><strong>4.4</strong> Our copyright agent can be reached as follows: <strong>Email:
            support@liketank.com</strong></p>
          <p><strong>(C) User Data</strong></p>
          <p><strong>4.5</strong> Users retain all rights, title, and interest in the User Data they provide through the
            Platform. By providing the User Data, Users grant us a limited, non-exclusive, royalty-free, perpetual,
            transferable license to host, reproduce, and process such User Data for the sole purpose of providing the
            Services or customer support. LikeTank shall not use User Data contrary to these terms.</p>
          <p><strong>4.6</strong> Considering how we do not monitor the content of the User Data, you agree to inform us
            immediately if you come across any illegal activity, activity that is in breach of these Terms, or activity
            you suspect might be in violation of these Terms or applicable laws or might otherwise be objectionable.
            Although we expressly prohibit uploading of any User Data which is illegal, hateful, obscene, threatening,
            incites violence, insulting, defamatory, infringing of intellectual property rights, invasive of privacy, or
            contains graphic or gratuitous violence or is otherwise objectionable to third parties, we do not pre-screen
            the content, so you hereby agree that you may be exposed to any such content and that you use the Platform
            at your own risk. We reserve the right to remove any content which we find to constitute a breach of these
            Terms or relevant laws, without notifying the Users or providing reasoning for such action. You recognize
            and concur that LikeTank bears no obligation regarding the risk, harm, damage, or loss that might emerge
            from content submitted to or distributed on the Platform.</p>
          <p><strong>(D) Third party content</strong></p>
          <p><strong>4.8</strong> Some content on the Platform, such as advertisement, may be provided by the
            Third-Parties. We are not responsible for such content, nor do we monitor or control content provided by the
            Third-Parties.</p>
          <p><strong>(E) Confidential Information</strong></p>
          <p><strong>4.9</strong> During the term of this Agreement, Users may be required to provide or volunteer to
            provide certain Confidential Information to LikeTank and LikeTank may disclose certain Confidential
            Information to the Users. Regarding such information both parties hereby agree (i) to keep Confidential
            Information in strict confidence, (ii) to undertake all reasonable measures to protect the confidence of
            Confidential Information, (iii) not to disclose, or otherwise make available, Confidential Information to
            any third party without obtaining prior written consent, (iv) to use Confidential Information only for the
            purposes intended, (v) to return all Confidential Information and any and all copies, extracts or derivative
            works resulted from Confidential Information upon written request or upon termination of the Agreement, and
            to destroy or erase all remaining copies of the Confidential Information regardless of the form or media on
            which the Confidential Information is stored.</p>
        </section>
        <section>
          <h2>5. Payment and Pricing</h2>
          <p><strong>5.1</strong> A description of the currently available Prices for the Services is listed on the
            Site. It is hereby clarified, that all rates and fees in connection with the Services or otherwise, exclude
            all taxes, duties, levies, fees, charges or tolls imposed by applicable taxing authorities, and you shall be
            fully responsible and liable in connection with payment of such taxes, duties, levies, fees, charges or
            tolls. You hereby agree to pay for any such taxes, duties, levies, fees, charges or tolls that might be
            applicable due to your use of the Services and payments made by you to LikeTank. If LikeTank has a legal
            obligation to pay or collect indirect Taxes (such as value-added tax or goods and services tax) on the sale
            to you under the laws of your country (where you are established, have a permanent address or usually
            reside), you shall be liable for payment of any such indirect Taxes. Where LikeTank does not have a legal
            obligation to pay or collect indirect Taxes on a sale of Paid Services to you, you may be required to
            self-assess those Taxes under the applicable laws of your country (where you are established, have a
            permanent address or usually reside). Please note that any payment terms presented to you in the process of
            using or signing up for a Paid Service are deemed part of these Terms of Services. </p>
          <p><strong>5.2</strong> When subscribing on the Site or placing an order, you can choose the method of
            payment. If you are paying by credit card, you must provide valid and current information necessary to
            process the payment. You agree and authorize us to charge your credit card for all fees and rates payable
            during your use of the Services. </p>
          <p><strong>5.3</strong> Subscription Fee is calculated in the moment of submitting the registration on the
            Site. Subscription Fee for the current Subscription Period will not be affected by the Service price changes
            as described in article 5.6 of this Agreement.</p>
          <p><strong>5.4</strong> You authorize us to charge your credit card for the Subscription Fee at the expiry of
            the current Subscription Period unless auto-renewal is turned off in the moment of processing of the
            payment. </p>
          <p><strong>5.5</strong> If any amount owed is not paid by the due date we reserve the right to charge an
            interest on the due amount at the rate of 1.5% per month or maximum allowed interest rate under the law,
            whichever is less.</p>
          <p><strong>5.6</strong> We reserve the right to change prices at any time, by publishing the revised rates on
            the Site with no further notice. Such changes will not affect user’s current Subscription Period. Prices
            that were effective fifteen (15) days prior to the Subscription renewal will be applied for the payment of
            the renewed Subscription Period.</p>
          <p><strong>5.7</strong> You have the right to upgrade or downgrade a current Plan at any time by selecting a
            new Plan among the collection of Plans available on the Site. Downgrading of the current Plan may cause the
            loss of features or capacity of the Account, as well as the loss of Client Data.</p>
        </section>
        <section>
          <h2>6. Third-Party Services</h2>
          <p><strong>6.1</strong> The Services may be made available or accessed in connection with Third-Party services
            and content (including advertising) that LikeTank does not control. We may also provide you with links
            leading to the Third-Parties. You acknowledge that different Terms of Service and privacy policies may apply
            to your use of such Third-Party services and content. LikeTank does not endorse such Third-Party services
            and content and in no event shall LikeTank be responsible or liable for any products or services of such
            Third-Party providers.</p>
          <p><strong>6.2</strong> Some of our Services may be dependent on the Third-Party service provider or may
            entirely be provided by the Third-Party service provider. You understand and agree that we bear no
            responsibility over the availability of such Services and that in case of such Services we act only as an
            intermediary. We make no warranties as to the availability of such Services or fitness for a particular
            purpose. </p>
          <p><strong>6.3</strong> Our Services may be used in connection with third-party services such as Instagram,
            Facebook, Twitter, and other. In that sense your interaction with the Site and Services is further regulated
            by the Third-Party’s respective terms and privacy policies. LikeTank is not sponsored, endorsed, organized
            or in any other way supported by these third-parties.</p>
        </section>
        <section>
          <h2>7. Indemnity</h2>
          <p><strong>7.1</strong> You will indemnify and hold harmless LikeTank, and its employees and affiliates,
            from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses,
            including, without limitation, reasonable legal and accounting fees arising out of or in any way connected
            with your access to or use of the Site and our Services, content which you provide, or your violation of
            these Terms.</p>
        </section>
        <section>
          <h2>8. Limitation of liability</h2>
          <p><strong>8.1</strong> YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE
            REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH LikeTank SERVICE IS TO STOP USING THE SERVICES.</p>
          <p><strong>8.2</strong> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW LIKETANK, ITS EMPLOYEES, OFFICERS,
            AGENTS, AFFILIATES, SUCCESSORS, SUPPLIERS, ASSIGNEES OR LICENSORS SHALL NOT BE LIABLE FOR ANY INDIRECT,
            SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES,
            WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER INTANGIBLE LOSSES,
            ARISING OUT OF YOUR ACCESS OR USE OR INABILITY TO ACCESS OR USE THE PLATFORM, THIRD-PARTY APPLICATIONS OR
            THIRD-PARTY APPLICATION CONTENT, INCLUDING WITHOUT LIMITATION ANY OFFENSIVE OR ILLEGAL CONDUCT OF OTHER
            USERS OF THE PLATFORM, REGARDLESS OF LEGAL THEORY, EVEN IF LIKETANK HAS BEEN ADVISED OF THE POSSIBILITY OF
            THOSE DAMAGES AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>
          <p><strong>8.3</strong> LikeTank, its employees, agents, and its directors do not accept any liability and
            you hereby agree to release us of any liability arising (whether directly or indirectly) out of the
            information provided through the Platform, or any errors, in or omissions from information on the Platform.
            LikeTank is not liable for loss (whether directly or indirectly) caused by your actions or decisions based
            on your reliance on the information provided to you through the Site, nor caused by the delay, malfunction
            of the operation or the availability of the Platform.</p>
        </section>
        <section>
          <h2>9. Changes</h2>
          <p><strong>9.1</strong> TO THE FURTHEST EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS OTHERWISE PROVIDED IN
            THIS AGREEMENT, YOUR AGREE DURING THE TERM OF THIS AGREEMENT, THAT LIKETANK MAY: (1) REVISE THE TERMS AND
            CONDITIONS OF THIS AGREEMENT; AND/OR (2) CHANGE PART OF THE SERVICES PROVIDED UNDER THIS AGREEMENT AT ANY
            TIME WITHOUT ANY CLAIM TO COMPENSATION OR SPECIAL TERMINATION RIGHT FOR YOU. WE WILL POST SUCH CHANGES,
            REPLACEMENTS AND UPDATES ON THE PLATFORM AND SUCH CHANGE, REPLACEMENT AND UPDATE TO OUR TERMS OF SERVICE
            AGREEMENT WILL TAKE EFFECT IMMEDIATELY UPON POSTING. YOU ARE CONSENTING TO KEEP YOURSELF UP TO DATE WITH THE
            LATEST POSTED TERMS OF SERVICE AGREEMENT AND YOU ACCEPT AND ARE BOUND BY SUCH CHANGE, REPLACEMENT AND UPDATE
            IF YOU ACCESS OR USE OUR SERVICE AFTER WE HAVE POSTED UPDATED TERMS OF SERVICE.</p>
        </section>
        <section>
          <h2>10. Termination</h2>
          <p><strong>10.1</strong> User may terminate this Agreement without notice period by turning off the auto
            renewal option or by terminating their use of the Services if the User did not purchase the subscription. On
            termination of this Agreement, all provisions of this Agreement shall cease to have effect, except that any
            provision which can reasonably be inferred as continuing, or is expressly stated to continue, shall continue
            in full force and effect.</p>
          <p><strong>10.2</strong> LikeTank may terminate this agreement at any time, without cause, with notice and
            without notice period. LikeTank may terminate this agreement without notice and with immediate effect if
            User:</p>
          <p>
            <i>(i)</i> breaches this Agreement; <br/><br/>
            <i>(ii)</i> fails to make due payments; <br/><br/>
            <i>(iii)</i> misuses the Services or uses Services contrary to Acceptable Use policy; <br/><br/>
            <i>(iv)</i> engages in fraudulent or illegal activities.</p>
          <p><strong>10.3</strong> Upon termination of this Agreement, User will not be able to use the Services and we
            may immediately delete any User Data on the Platform. If the Agreement has been terminated, user shall be
            liable to pay to LikeTank any outstanding fees due for payment immediately. LikeTank shall not be
            responsible for any damage caused by the termination of this Agreement.</p>
        </section>
        <section>
          <h2>11. Governing Laws and Choice of Forum</h2>
          <p><strong>11.1</strong> This Agreement shall be governed by and construed under the laws of Sweden, without
            regard to its conflict of law provisions, as applied to agreements entered into and to be performed in
            Sweden by the Sweden residents. You agree that if you have any dispute with LikeTank you will contact us
            in order to settle through negotiations and mutual understanding. If the solution can not be reached in
            negotiations you agree and hereby submit to the exclusive jurisdiction of the official courts in Sweden.</p>
        </section>
        <section>
          <h2>12. Final Provisions</h2>
          <p><strong>12.1 Publicity.</strong> All media releases, public announcements and public disclosures by the
            User relating to this Agreement or its subject matter, including promotional or marketing material, shall be
            coordinated with and approved by LikeTank and User prior to release.</p>
          <p><strong>12.2 Assignment.</strong> Either party may only assign or transfer its rights or obligations under
            this Agreement with the other party’s prior written consent (such consent not to be unreasonably withheld).
          </p>
          <p><strong>12.3 Entire Agreement.</strong> The terms of this Agreement constitute the entire agreement between
            the parties regarding its subject matter and supersede and replace any and all prior agreements,
            understandings or arrangements between the parties, whether oral or in writing, with respect to the same.
            Neither party shall have any remedy in respect of any untrue statement made by the other upon which that
            party relied in entering into this Agreement (unless such untrue statement was made fraudulently) and that
            party's only remedies shall be for breach of contract as provided in this Agreement.</p>
          <p><strong>12.4 Severance.</strong> If any part of these Terms is found to be invalid, illegal or
            unenforceable in any respect, it will not affect the validity or enforceability of the remainder of the
            Terms. </p>
          <p><strong>12.5 Titles.</strong> The section titles in the Terms are for convenience only and have no legal or
            contractual effect.</p>
          <p><strong>12.6 Force Majeure.</strong> For the purpose of this Agreement Force Majeure Event shall mean any
            event arising that is beyond the reasonable control of the affected party (including any industrial dispute
            affecting any third party, governmental regulations, fire, flood, disaster, civil riot or war). A party who
            becomes aware of a Force Majeure Event which gives rise to, or which is likely to give rise to, any failure
            or delay to perform its obligations under this Agreement shall forthwith notify the other, and shall inform
            the other of the period for which it is estimated that such failure or delay shall continue. The affected
            party shall take reasonable steps to mitigate the effect of the Force Majeure Event.</p>
          <p><strong>12.7 Waiver.</strong> Any failure to exercise or enforce any right or the provision of this
            agreement shall not constitute a waiver of such right or provision. </p>
          <p><strong>12.8 Language.</strong> These Terms may be available on multiple languages, however English version
            will be considered as the authentic and official version.</p>
        </section>
      </div>
      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>

    <Footer/>
  </>
}

export default TermOfServicePage
