import {useParams} from "react-router-dom"
import Navbar from "../components/header/Navbar"
import Sidebar from "../components/header/Sidebar"
import Footer from "../components/footer/Footer"
import React from "react"
import OrderEnterDetails from "../components/order_enter_details/OrderEnterDetails"

const EnterDetailsPage = () => {
  let {packageSlug} = useParams()

  return <>
    <Navbar/>
    <Sidebar/>
    <OrderEnterDetails packageSlug={packageSlug}/>
    <Footer/>
  </>
}

export default EnterDetailsPage
