import * as React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import {TextValidator} from "react-material-ui-form-validator"
import {Autocomplete, IconButton} from "@mui/material"
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import {red} from "@mui/material/colors"

const PackageProviderRow = ({providers, index, formValues, setFormValues, removeRow}) => {
  const handleProviderChange = (event, value) => {
    formValues.package_providers[index].provider = value
    setFormValues({...formValues, package_providers: formValues.package_providers})
  }

  const handleQuantityChange = (event) => {
    formValues.package_providers[index].quantity = event.target.value
    setFormValues({...formValues, package_providers: formValues.package_providers})
  }

  const handleDisplayQuantityChange = (event) => {
    formValues.package_providers[index].display_quantity = event.target.value
    setFormValues({...formValues, package_providers: formValues.package_providers})
  }

  return (
    <TableRow
      key={`package_provider_table_row_${index}`}
      sx={{'&:last-child td, &:last-child th': {border: 0}}}
    >
      <TableCell>
        <Autocomplete
          disablePortal
          options={providers.map(provider => provider.uid)}
          onChange={handleProviderChange}
          value={formValues.package_providers[index].provider}
          getOptionLabel={(option) => {
            let provider = providers.find((item) => item.uid === option)
            return provider ? provider.title : ''
          }}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name={'provider'}
              label="Provider"
              placeholder={'Provider'}
              value={formValues.package_providers[index].provider}
              validators={['required']}
              errorMessages={['This field is required']}
            />
          )}
        />
      </TableCell>

      <TableCell>
        <TextValidator
          placeholder={'Quantity'}
          name={`quantity_${index}`}
          variant={'outlined'}
          fullWidth={true}
          validators={['required']}
          errorMessages={['This field is required']}
          onChange={handleQuantityChange}
          value={formValues.package_providers[index].quantity}
          type={'number'}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TableCell>

      <TableCell>
        <TextValidator
          placeholder={'Display Quantity'}
          name={`display_quantity_${index}`}
          variant={'outlined'}
          fullWidth={true}
          validators={['required']}
          errorMessages={['This field is required']}
          onChange={handleDisplayQuantityChange}
          value={formValues.package_providers[index].display_quantity}
          type={'number'}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </TableCell>

      <TableCell align="center">
        <IconButton onClick={() => {
          removeRow(index)
        }}>
          <DeleteOutlineIcon sx={{color: red[500]}}/>
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

export default PackageProviderRow
