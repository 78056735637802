import * as React from 'react'
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import {Link} from "react-router-dom"
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import {navbarLinks} from "../../data/navbar"
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import PopupState, {bindHover, bindPopover} from 'material-ui-popup-state'
import MenuIcon from '@mui/icons-material/Menu'
import {IconButton, ListItemIcon, ListItemText} from "@mui/material"
import {useNavbarContext} from "../../contexts/navbar_context"
import {alpha} from '@mui/material/styles'
import {theme} from "../../assets/css/styles"
import {getIconByCategoryTitle} from "../../utils"
import logo from "../../assets/img/logo/liketank-logo-horizontal-transparent.png"
import {useGlobalContext} from "../../contexts/global_context"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'
import CloseIcon from '@mui/icons-material/Close'
import Box from "@mui/material/Box";
import {
  pink,
  orange,
  deepPurple,
  grey,
  indigo,
} from "@mui/material/colors";
import {useState} from "react";

const HeaderNotification = () => {
  const storageName = 'showAutoLikeAlert'
  let savedShowHeadAlert = localStorage.getItem(storageName)
  const [showHeadAlert, setShowHeadAlert] = useState(savedShowHeadAlert !== 'false')

  const dismissAlert = () => {
    localStorage.setItem(storageName, 'false')
    setShowHeadAlert(false)
  }

  return <>
    {showHeadAlert ?
      <Box
        bgcolor={pink['A700']}
        sx={{
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            paddingTop: 1,
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 0.5,
          }}
        >
          <Typography variant="body1" color={'text.light'}>
            Try out the new <b>Automatic</b> Instagram Likes, Views and Comments!
            <Button
              size={'medium'}
              component={Link}
              to={'/signup'}
              variant="text"
              color={'secondary'}
              sx={{
                color: '#FFE0B2',
              }}
            >
              Sign Up
            </Button>

            -

            <Button
              size={'small'}
              variant="text"
              color={'secondary'}
              onClick={dismissAlert}
              sx={{
                color: '#FFE0B2',
                marginLeft: 0.5,
              }}
            >
              Dismiss
            </Button>
          </Typography>
        </Box>
      </Box> : ''}
  </>
}

export default HeaderNotification
