import {Grid} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
import GetStartedBox from "./GetStartedBox"
import InstagramLogo from '../../assets/img/instagram-original-logo.png'
import TikTokLogo from '../../assets/img/tiktok-original-logo.png'
import ClubhouseLogo from '../../assets/img/clubhouse-original-logo.png'
import {navbarMenuItems} from "../../data/navbar"
import SinDividerFooter from '../../assets/img/sin-divider-footer.svg'

const GetStarted = ({title, description, groupedCategories}) => {
  return (
    <div className={'get-started'}>
      <div className={'get-started-container'}>
        <Grid container justifyContent={'center'}>
          <Grid item xs={12} sm={12} md={6}>
            <div className={'our-features-head'}>
              <Typography variant={'h4'} component={'h1'}>
                {title}
              </Typography>

              <br/>

              <Typography variant="p" color={'text.secondary'}>
                {description}
              </Typography>
            </div>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12} md={4}>
            <GetStartedBox
              title={'Instagram Services'}
              description={
                <span>
                  Checkout our <b>real</b> Instagram likes, followers, views and comments packages.
                  You can purchase packages for your personal or business account.
                </span>
              }
              icon={InstagramLogo}
              items={groupedCategories.find((item) => item.social === 10)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <GetStartedBox
              title={'TikTok Services'}
              description={
                <span>
                  Do you need to increase your popularity on TikTok?
                  We offer the best <b>real</b> TikTok
                  likes, followers, views and comments packages. Check it out!
                </span>
              }
              icon={TikTokLogo}
              items={groupedCategories.find((item) => item.social === 20)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <GetStartedBox
              title={'Clubhouse Services'}
              description={
                <span>
                  We are one of the few sites that offer Clubhouse services.
                  Checkout our <b>real</b> room visitors, account followers and club followers packages.
                </span>
              }
              icon={ClubhouseLogo}
              items={groupedCategories.find((item) => item.social === 30)}
            />
          </Grid>
        </Grid>
      </div>

      <img className={'sin-divider-bottom'} src={SinDividerFooter} alt={''}/>
    </div>
  )
}

export default GetStarted
