import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TablePagination from "@mui/material/TablePagination"
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Grid from "@mui/material/Grid";
import {Chip} from "@mui/material";
import {Link} from "react-router-dom";
import CustomerConfirmDialog from "../dialog/CustomerConfirmDialog";
import {useState} from "react";
import MuiLink from '@mui/material/Link'
import {BACKEND_URL} from "../../../data/config";
import {useGlobalContext} from "../../../contexts/global_context";


const CustomerSubscriptionsTable = ({items, numTotal, page, setPage, fetchData}) => {
  let {sendAjax} = useGlobalContext()
  const [deletingItemUid, setDeletingItemUid] = useState("")

  const handleChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const getStatusColor = (status) => {
    if (status === 10) {
      return 'primary'
    } else if (status === 20) {
      return 'warning'
    } else if (status === 30) {
      return 'success'
    } else if (status === 40) {
      return 'error'
    } else {
      return ''
    }
  }

  const handleDelete = () => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/subscription/customer/auto-services/${deletingItemUid}`,
      method: 'delete',
      data: {},
    }).then(r => {
      if (r.data.status_code === 200) {
        fetchData()
        setDeletingItemUid('')
      }
    })
  }

  return <>
    <CustomerConfirmDialog
      message={'Cancel Subscription?'}
      subtitle={'Are you sure that you want to cancel this subscription?'}
      cancelBtnText={'No'}
      confirmBtnText={'Cancel Subscription'}
      onConfirm={handleDelete}
      onClose={() => {
        setDeletingItemUid('')
      }}
      isOpen={Boolean(deletingItemUid)}
      zIndex={2000}
    />

    <Paper elevation={0}>
      <TableContainer>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>TITLE</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell>USERNAME</TableCell>
              <TableCell>ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              items.length ?
                items.map((row, index) => (
                  <TableRow
                    key={row.uid}
                    sx={{'&:last-child td, &:last-child th &:last-child a': {border: 0}}}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <MuiLink
                        to={`/dashboard/subscriptions/update/${row.uid}`}
                        component={Link}
                      >
                        {row.package_title}
                      </MuiLink>
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={row.status_display}
                        color={getStatusColor(row.status)}
                      />
                    </TableCell>
                    <TableCell>{row.username}</TableCell>
                    <TableCell sx={{whiteSpace: 'nowrap'}}>
                      <Grid container sx={{width: 'max-content'}}>
                        <Grid item xs={6} sm={6} md={6}>
                          <Button
                            variant="outlined"
                            startIcon={<EditIcon/>}
                            color={'primary'}
                            component={Link}
                            to={`/dashboard/subscriptions/update/${row.uid}`}
                          >
                            Edit
                          </Button>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6}>
                          <Button
                            variant="outlined"
                            startIcon={<DeleteIcon/>}
                            color={'error'}
                            onClick={() => {
                              setDeletingItemUid(row.uid)
                            }}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell
                    colSpan={5}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    You don't have any active subscription yet
                  </TableCell>
                </TableRow>

            }
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={numTotal}
        rowsPerPage={10}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
      />
    </Paper>
  </>
}

export default CustomerSubscriptionsTable
