import Navbar from "../../../components/header/Navbar";
import Sidebar from "../../../components/header/Sidebar";
import Footer from "../../../components/footer/Footer";
import React from "react";
import CustomerOrderList from "../../../components/customer_panel/orders/CustomerOrderList";


const CustomerOrderListPage = () => {
  return <>
    <Navbar/>
    <Sidebar/>
    <CustomerOrderList/>
    <Footer/>
  </>
}

export default CustomerOrderListPage
