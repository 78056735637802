import {Divider, Grid} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"
import FooterCol from "./FooterCol"
import {footerResources} from "../../data/navbar"
import VisaCardLogo from '../../assets/img/visa-card.svg'
import MasterCardLogo from '../../assets/img/mastercard.svg'
import DiscoverCardLogo from '../../assets/img/discover-card.svg'
import AmExLogo from '../../assets/img/american-express.svg'
import {useGlobalContext} from "../../contexts/global_context"

const Footer = () => {
  let {groupedCategories} = useGlobalContext()

  const getCategories = (social) => {
    let group = groupedCategories.find(item => item.social === social)
    let categories = group ? JSON.parse(JSON.stringify(group.categories)) : []

    categories.forEach(item => {
      item.slug = `/categories/${item.slug}`
    })

    return categories
  }

  return (
    <footer className={'footer'}>
      <div className={'footer-container'}>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <FooterCol
              title={"Instagram"}
              items={getCategories(10)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FooterCol
              title={"TikTok"}
              items={getCategories(20)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FooterCol
              title={"Clubhouse"}
              items={getCategories(30)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FooterCol title={'Resources'} items={footerResources}/>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Divider variant="middle"/>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={'footer-col'}>
              <Typography variant="h6" component="div">
                LikeTank
              </Typography>

              <Typography variant="body1" component="p" color={'text.light'}>
                support@liketank.com
              </Typography>

              <Typography variant="body1" component="p" color={'text.light'}>
                Copyright © 2023 · LikeTank
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <div className={'footer-col'}>
              <img className={'payment-card-img'} src={VisaCardLogo} alt={'Visa Card'}/>
              <img className={'payment-card-img'} src={MasterCardLogo} alt={'Mastercard'}/>
              <img className={'payment-card-img'} src={DiscoverCardLogo} alt={'Discover Card'}/>
              <img className={'payment-card-img'} src={AmExLogo} alt={'American Express'}/>
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>
  )
}

export default Footer
