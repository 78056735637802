import Navbar from "../components/header/Navbar"
import Sidebar from "../components/header/Sidebar"
import Hero from "../components/hero/Hero"
import OurFeatures from "../components/our_features/OurFeatures"
import HowItWorks from "../components/how_it_works/HowItWorks"
import GetStarted from "../components/get_started/GetStarted"
import Footer from "../components/footer/Footer"
import Pricing from "../components/pricing/Pricing"
import {useGlobalContext} from "../contexts/global_context"
import {Helmet} from "react-helmet";
import React from "react";
import {DEFAULT_META_DESCRIPTION} from "../data/head";
import HeaderNotification from "../components/notification/HeaderNotification";

const IndexPage = () => {
  let {groupedCategories} = useGlobalContext()

  return <>
    {/* SEO */}
    <Helmet>
      <title>Buy Likes, Followers, Views & Comments - 100% Real & Instant - LikeTank</title>
      <meta name="description" content={DEFAULT_META_DESCRIPTION}/>
    </Helmet>

    <HeaderNotification/>
    <Navbar/>
    <Sidebar/>
    <Hero groupedCategories={groupedCategories}/>
    <OurFeatures/>
    <HowItWorks/>
    <Pricing showTabs={true} groupedCategories={groupedCategories}/>
    <GetStarted
      title={'Other Great Services'}
      description={
        'Choose one of the options below to get started. ' +
        'Your fantastic likes, followers, views and comments are only a few clicks away!'
      }
      groupedCategories={groupedCategories}
    />
    <Footer/>
  </>
}

export default IndexPage
