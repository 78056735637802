import AdminContainer from "../../../components/admin/AdminContainer"
import React, {useEffect, useState} from "react"
import AdminTable from "../../../components/admin/AdminTable"
import {BACKEND_URL} from "../../../data/config"
import {Button, IconButton} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import {Link} from "react-router-dom"
import AddIcon from "@mui/icons-material/Add"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
import {useGlobalContext} from "../../../contexts/global_context"
import {Helmet} from "react-helmet";

const DeleteDialog = ({deletingItemUid, setDeletingItemUid, fetchData}) => {
  let {sendAjax} = useGlobalContext()

  const DeleteItem = (uid) => {
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/admin/categories/${uid}/`,
      method: "delete",
      data: {},
    }).then((r) => {
      setDeletingItemUid("")
      fetchData()
    })
  }

  return (
    <Dialog
      open={Boolean(deletingItemUid)}
      onClose={() => {
        setDeletingItemUid("")
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete Item?"}</DialogTitle>
      {/* <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Delete Item?
        </DialogContentText>
      </DialogContent> */}
      <DialogActions>
        <Button
          onClick={() => {
            setDeletingItemUid("")
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            DeleteItem(deletingItemUid)
          }}
          autoFocus
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CategoryListAdminPage = () => {
  let {sendAjax} = useGlobalContext()
  const [page, setPage] = useState(0)
  const [data, setData] = useState({})
  const [deletingItemUid, setDeletingItemUid] = useState("")
  let items = []
  let numTotal = 0

  const fetchData = () => {
    sendAjax({
      url: BACKEND_URL + "/api/v1/pkg/admin/categories/",
      method: "get",
      data: {page: page + 1},
    }).then((r) => {
      setData(r.data)
    })
  }

  const handleClickDelete = (uid) => {
    setDeletingItemUid(uid)
  }

  useEffect(() => {
    fetchData()
  }, [page])

  try {
    for (let [index, item] of data.data.results.entries()) {
      items.push({
        uid: item.uid,
        "#": index + 1,
        title: item.title,
        index: item.index,
        status: item.status_display,
        actions: (
          <>
            <IconButton
              component={Link}
              to={`/admin/categories/update/${item.uid}`}
            >
              <EditIcon/>
            </IconButton>

            <IconButton
              onClick={() => {
                handleClickDelete(item.uid)
              }}
            >
              <DeleteIcon/>
            </IconButton>
          </>
        ),
      })
    }
    numTotal = data.data.count
  } catch {
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>Categories - LikeTank</title>
      </Helmet>

      <DeleteDialog
        {...{deletingItemUid, setDeletingItemUid, fetchData}}
      />

      <Button
        component={Link}
        to={"/admin/categories/create"}
        variant="outlined"
        startIcon={<AddIcon/>}
        sx={{marginBottom: "20px"}}
      >
        Add
      </Button>

      <AdminTable
        items={items}
        numTotal={numTotal}
        page={page}
        setPage={setPage}
      />
    </AdminContainer>
  )
}

export default CategoryListAdminPage
