import {Grid} from "@mui/material"
import Typography from "@mui/material/Typography"
import * as React from "react"

const HowItWorksItem = ({title, description, icon, index}) => {
  let textOrder = {}
  let iconOrder = {}
  let iconAlign = null
  if (index % 2 === 0) {
    textOrder = {xs: 1, sm: 1, md: 1}
    iconOrder = {xs: 2, sm: 2, md: 2}
    iconAlign = 'right'
  } else {
    textOrder = {xs: 1, sm: 1, md: 2}
    iconOrder = {xs: 2, sm: 2, md: 1}
    iconAlign = 'left'
  }

  return (
    <div className={'how-it-works-item'}>
      <Grid container>
        <Grid item xs={12} sm={12} md={8} order={textOrder} sx={{display: 'flex', alignItems: 'center'}}>
          <div className={'how-it-works-text'}>
            <Typography variant={'h6'} component={'h2'}>
              {title}
            </Typography>

            <Typography variant="body2" component={'p'} color="#e0e0e0">
              {description}
            </Typography>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={4} order={iconOrder} className={`how-it-works-img-grid-${iconAlign}`}>
          <img className={'how-it-works-img'} src={icon} alt={'Select Item'}/>
        </Grid>
      </Grid>
    </div>
  )
}

export default HowItWorksItem
