import {useNavigate, useParams} from 'react-router-dom'
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import React, {useEffect, useRef, useState} from "react"
import {getFieldErrorParams, getInputValue} from "../../../utils"
import AdminContainer from "../../../components/admin/AdminContainer"
import {Autocomplete, Chip, Grid} from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import {useGlobalContext} from "../../../contexts/global_context"
import {BACKEND_URL} from "../../../data/config"
import Divider from "@mui/material/Divider"
import PackageProviderTable from "../../../components/admin/package/PackageProviderTable"
import {Helmet} from "react-helmet";

const PackageCreateUpdateAdminPage = () => {
  const navigate = useNavigate()
  let {uid} = useParams()
  let form = useRef()
  let {sendAjax} = useGlobalContext()
  let [fieldErrors, setFieldErrors] = useState({})
  let [categories, setCategories] = useState([])

  useEffect(() => {
    // get categories
    sendAjax({
      url: `${BACKEND_URL}/api/v1/pkg/admin/categories/`,
      method: 'get',
      data: {
        page_size: 1000,
      },
    }).then(r => {
      if (r.data.status_code === 200) {
        setCategories(r.data.data.results)
      }
    })

    // get package
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/packages/${uid}/`,
        method: 'get',
        data: {},
      }).then((r) => {
        if (r.data.status_code === 200) {
          let pkg = r.data.data
          setFormValues({
            category: pkg.category,
            title: pkg.title,
            price: pkg.price,
            discount: pkg.discount,
            status: pkg.status,
            index: pkg.index,
            seo_title: pkg.seo_title,
            slug: pkg.slug,
            meta_description: pkg.meta_description,
            pricing_descriptions: pkg.pricing_descriptions,
            package_providers: pkg.package_providers,
          })
        }
      })
    }
  }, [])

  let [formValues, setFormValues] = useState({
    category: '',
    title: '',
    price: '',
    discount: '',
    status: '',
    index: '',
    seo_title: '',
    slug: '',
    meta_description: '',
    pricing_descriptions: ['', '', '', '', ''],
    package_providers: [],
  })

  const afterSubmit = (r) => {
    if (r.data.status_code === 200) {
      navigate("/admin/packages/")
    } else if (r.data.status_code === 400) {
      setFieldErrors(r.data.data)
    }
  }

  const handleSubmit = () => {
    if (uid) {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/packages/${uid}/`,
        method: 'put',
        data: formValues,
      }).then(
        afterSubmit
      )
    } else {
      sendAjax({
        url: `${BACKEND_URL}/api/v1/pkg/admin/packages/`,
        method: 'post',
        data: formValues,
      }).then(
        afterSubmit
      )
    }
  }

  const handleChange = (event) => {
    setFormValues({...formValues, [event.target.name]: getInputValue(event.target)})
    setFieldErrors({})
  }

  const handlePricingDescriptionChange = (event) => {
    if (event.target.name === 'pricing_description_1') {
      formValues.pricing_descriptions[0] = event.target.value
    } else if (event.target.name === 'pricing_description_2') {
      formValues.pricing_descriptions[1] = event.target.value
    } else if (event.target.name === 'pricing_description_3') {
      formValues.pricing_descriptions[2] = event.target.value
    } else if (event.target.name === 'pricing_description_4') {
      formValues.pricing_descriptions[3] = event.target.value
    } else if (event.target.name === 'pricing_description_5') {
      formValues.pricing_descriptions[4] = event.target.value
    }
    setFormValues({...formValues, pricing_descriptions: formValues.pricing_descriptions})
    setFieldErrors({})
  }

  const handleComboBoxChange = (name) => (event, value) => {
    setFormValues({...formValues, [name]: value})
    setFieldErrors({})
  }

  return (
    <AdminContainer>
      <Helmet>
        <title>Packages - LikeTank</title>
      </Helmet>

      <Box
        component={ValidatorForm}
        ref={form}
        onSubmit={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={4}>
            <Autocomplete
              disablePortal
              name={'category'}
              options={categories.map(category => category.uid)}
              onChange={handleComboBoxChange('category')}
              value={formValues.category}
              getOptionLabel={(option) => {
                let category = categories.find((item) => item.uid === option)
                return category ? category.title : ''
              }}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name={'category'}
                  label="Category"
                  placeholder={'Category'}
                  value={formValues.category}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  {...getFieldErrorParams(fieldErrors, 'category')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Title'}
              placeholder={'Title'}
              name={'title'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.title}
              {...getFieldErrorParams(fieldErrors, 'title')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Price'}
              placeholder={'Price'}
              name={'price'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.price}
              type={'number'}
              {...getFieldErrorParams(fieldErrors, 'price')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Discount'}
              placeholder={'Discount'}
              name={'discount'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.discount}
              type={'number'}
              {...getFieldErrorParams(fieldErrors, 'discount')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              select
              label="Status"
              name={'status'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.status}
              {...getFieldErrorParams(fieldErrors, 'status')}
              InputLabelProps={{
                shrink: true,
              }}
            >
              <MenuItem value={10}>Enabled</MenuItem>
              <MenuItem value={20}>Disabled</MenuItem>
            </TextValidator>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Index'}
              placeholder={'Index'}
              name={'index'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.index}
              type={'number'}
              {...getFieldErrorParams(fieldErrors, 'index')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'SEO Title'}
              name={'seo_title'}
              placeholder={'SEO Title'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.seo_title}
              {...getFieldErrorParams(fieldErrors, 'seo_title')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextValidator
              label={'Slug'}
              name={'slug'}
              placeholder={'Slug'}
              variant={'outlined'}
              fullWidth={true}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.slug}
              {...getFieldErrorParams(fieldErrors, 'slug')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextValidator
              label={'Meta Description'}
              name={'meta_description'}
              placeholder={'Meta Description'}
              variant={'outlined'}
              fullWidth={true}
              multiline={true}
              rows={2}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handleChange}
              value={formValues.meta_description}
              {...getFieldErrorParams(fieldErrors, 'meta_description')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'Pricing Description 1'}
              name={'pricing_description_1'}
              placeholder={'Pricing Description 1'}
              variant={'outlined'}
              fullWidth={true}
              rows={2}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handlePricingDescriptionChange}
              value={formValues.pricing_descriptions[0]}
              {...getFieldErrorParams(fieldErrors, 'pricing_descriptions', 0)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'Pricing Description 2'}
              name={'pricing_description_2'}
              placeholder={'Pricing Description 2'}
              variant={'outlined'}
              fullWidth={true}
              rows={2}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handlePricingDescriptionChange}
              value={formValues.pricing_descriptions[1]}
              {...getFieldErrorParams(fieldErrors, 'pricing_descriptions', 1)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'Pricing Description 3'}
              name={'pricing_description_3'}
              placeholder={'Pricing Description 3'}
              variant={'outlined'}
              fullWidth={true}
              rows={2}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handlePricingDescriptionChange}
              value={formValues.pricing_descriptions[2]}
              {...getFieldErrorParams(fieldErrors, 'pricing_descriptions', 2)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'Pricing Description 4'}
              name={'pricing_description_4'}
              placeholder={'Pricing Description 4'}
              variant={'outlined'}
              fullWidth={true}
              rows={2}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handlePricingDescriptionChange}
              value={formValues.pricing_descriptions[3]}
              {...getFieldErrorParams(fieldErrors, 'pricing_descriptions', 3)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              label={'Pricing Description 5'}
              name={'pricing_description_5'}
              placeholder={'Pricing Description 5'}
              variant={'outlined'}
              fullWidth={true}
              rows={2}
              validators={['required']}
              errorMessages={['This field is required']}
              onChange={handlePricingDescriptionChange}
              value={formValues.pricing_descriptions[4]}
              {...getFieldErrorParams(fieldErrors, 'pricing_descriptions', 4)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Divider sx={{margin: '50px 0 20px 0'}}>
          <Chip label="PROVIDER"/>
        </Divider>

        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <PackageProviderTable {...{formValues, setFormValues}}/>
          </Grid>
        </Grid>

        <Button
          color={'primary'}
          type={'submit'}
          variant="contained"
          sx={{
            margin: '20px 0 500px 0',
          }}
          onClick={() => {
          }}
        >
          Save
        </Button>
      </Box>
    </AdminContainer>
  )
}

export default PackageCreateUpdateAdminPage
