import React from 'react'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'

export const navbarMenuItems = [
  {
    uid: '1',
    title: 'Instagram',
    subMenuItems: [
      {
        uid: '1',
        title: 'Instagram Likes',
        slug: '/categories/instagram-likes',
        service_type: 10,
      },
      {
        uid: '2',
        title: 'Instagram Followers',
        slug: '/categories/instagram-followers',
        service_type: 20,
      },
      {
        uid: '3',
        title: 'Instagram Views',
        slug: '/categories/instagram-views',
        service_type: 30,
      },
    ],
  },

  {
    uid: '2',
    title: 'TikTok',
    subMenuItems: [
      {
        uid: '1',
        title: 'TikTok Likes',
        slug: '/categories/tiktok-likes',
        service_type: 10,
      },
      {
        uid: '2',
        title: 'TikTok Followers',
        slug: '/categories/tiktok-followers',
        service_type: 20,
      },
      {
        uid: '3',
        title: 'TikTok Views',
        slug: '/categories/tiktok-views',
        service_type: 30,
      },
    ],
  },

  {
    uid: '3',
    title: 'Clubhouse',
    subMenuItems: [
      {
        uid: '1',
        title: 'Clubhouse Followers',
        slug: '/categories/clubhouse-followers',
        service_type: 20,
      },
      {
        uid: '2',
        title: 'Clubhouse Room Visitors',
        slug: '/categories/clubhouse-room-visitors',
        service_type: 50,
      },
      {
        uid: '3',
        title: 'Clubhouse Club Followers',
        slug: '/categories/clubhouse-club-followers',
        service_type: 60,
      },
    ],
  },
]

export const navbarLinks = [
  // {
  //   uid: '1',
  //   title: 'Blog',
  //   slug: '/blog',
  //   icon: <BookIcon/>,
  // },
  {
    uid: '2',
    title: 'Support',
    slug: '/support',
    icon: <SupportAgentIcon/>,
  },
]

export const footerResources = [
  // {
  //   uid: '1',
  //   title: 'Blog',
  //   slug: '/blog',
  // },
  {
    uid: '2',
    title: 'Support',
    slug: '/support',
  },
  {
    uid: '3',
    title: 'Terms of Service',
    slug: '/terms',
  },
  {
    uid: '4',
    title: 'Privacy Policy',
    slug: '/privacy-policy',
  },
  {
    uid: '5',
    title: 'Refund Policy',
    slug: '/refund-policy',
  },
]
