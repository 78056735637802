import AdminContainer from "../../../components/admin/AdminContainer"
import React, {useEffect, useState} from "react"
import AdminTable from "../../../components/admin/AdminTable"
import {BACKEND_URL} from "../../../data/config"
import {useGlobalContext} from "../../../contexts/global_context"
import moment from "moment"
import {Link, useNavigate, useParams} from "react-router-dom"
import OrderDetailsDialog from "../../../components/admin/order/OrderDetailsDialog"
import {Grid, Link as MuiLink, TextField} from "@mui/material"
import {getInputValue} from "../../../utils"
import InputAdornment from "@mui/material/InputAdornment"
import SearchIcon from '@mui/icons-material/Search'
import Button from "@mui/material/Button"
import {Helmet} from "react-helmet";
import OrderListFilters from "../../../components/admin/order/OrderListFilters";

const OrderListAdminPage = () => {
  let {uid} = useParams()
  const {sendAjax, showAlert} = useGlobalContext()
  const [page, setPage] = useState(0)
  const [data, setData] = useState({})
  const [invoice, setInvoice] = useState('')
  const navigate = useNavigate()
  let items = []
  let numTotal = 0

  const [filters, setFilters] = useState({
    search: '',
    invoice_number: '',
    status: [20, 40, 50, 60, 70, 80],
    category: [],
    pkg: [],
    title: '',
    min_price: '',
    max_price: '',
    social: [],
    email: '',
    ip: '',
    social_username: '',
    social_post: '',
    service_type: [],
    provider_type: [],
    service_code: '',
    provider_order_id: '',
  })

  const handleSubmitSearch = (event) => {
    event.preventDefault()
    fetchData()
  }

  const handleOpenByInvoice = (event) => {
    event.preventDefault()

    sendAjax({
      url: `${BACKEND_URL}/api/v1/commerce/admin/orders/`,
      method: "get",
      data: {
        invoice_number: invoice,
      },
    }).then((r) => {
      if (r.data.data.results.length) {
        navigate(`/admin/orders/details/${r.data.data.results[0]['uid']}`)
        setInvoice('')
      } else {
        showAlert('Not found.', 'error')
      }
    })
  }

  const handleChangeFilterInput = (event) => {
    setFilters({...filters, [event.target.name]: getInputValue(event.target)})
  }

  const handleChange = (event) => {
    setFilters({...filters, [event.target.name]: getInputValue(event.target)})
  }

  const handleComboBoxChange = (name) => (event, value) => {
    setFilters({...filters, [name]: value})
  }

  const fetchData = () => {
    const query = new URLSearchParams(filters)

    sendAjax({
      url: `${BACKEND_URL}/api/v1/commerce/admin/orders/?${query.toString()}`,
      method: "get",
      data: {page: page + 1},
    }).then((r) => {
      setData(r.data)
    })
  }

  useEffect(() => {
    fetchData()
  }, [page])

  try {
    for (let [index, item] of data.data.results.entries()) {
      let createdAt = moment(item.created_at)
      let detailsURL = `/admin/orders/details/${item.uid}`

      items.push({
        'uid': item.uid,
        "#": index + 1,
        'Invoice Number': <MuiLink component={Link} to={detailsURL}>{item.invoice_number}</MuiLink>,
        'Title': item.title,
        'Price': item.price,
        'Status': item.status_display,
        'Elapsed': createdAt.fromNow(),
        'Date': createdAt.format('LLL'),
      })
    }
    numTotal = data.data.count
  } catch {
  }

  return <>
    <Helmet>
      <title>Orders - LikeTank</title>
    </Helmet>

    {<OrderDetailsDialog uid={uid}/>}

    <AdminContainer>
      <Grid container sx={{marginBottom: 3}} spacing={3}>
        <Grid container item xs={12} sm={12} md={6} spacing={3} component={'form'} onSubmit={handleSubmitSearch}>
          <Grid item xs={9} sm={9} md={9}>
            <TextField
              label={'Search'}
              placeholder={'Search'}
              name={'search'}
              variant={'outlined'}
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color={'secondary'}/>
                  </InputAdornment>
                ),
              }}
              onChange={handleChangeFilterInput}
              value={filters.search}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={3}>
            <Button
              variant="outlined"
              fullWidth={true}
              type={'submit'}
              sx={{height: '100%', maxWidth: '150px'}}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        <Grid container item xs={12} sm={12} md={6} spacing={3} component={'form'} onSubmit={handleOpenByInvoice}>
          <Grid item xs={9} sm={9} md={9}>
            <TextField
              label={'Invoice Number'}
              placeholder={'Invoice Number'}
              name={'search'}
              variant={'outlined'}
              fullWidth={true}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color={'secondary'}/>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setInvoice(e.target.value)
              }}
              value={invoice}
            />
          </Grid>

          <Grid item xs={3} sm={3} md={3}>
            <Button
              variant="outlined"
              fullWidth={true}
              type={'submit'}
              sx={{height: '100%', maxWidth: '150px'}}
            >
              Open
            </Button>
          </Grid>
        </Grid>

      </Grid>


      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={12}>
          <OrderListFilters {...{fetchData, handleChange, filters, handleComboBoxChange}}/>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <AdminTable
            items={items}
            numTotal={numTotal}
            page={page}
            setPage={setPage}
          />
        </Grid>
      </Grid>
    </AdminContainer>
  </>
}

export default OrderListAdminPage
