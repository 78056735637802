import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import {useNavbarContext} from "../../contexts/navbar_context"
import {navbarLinks, navbarMenuItems} from "../../data/navbar"
import ListItemButton from '@mui/material/ListItemButton'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {Link} from 'react-router-dom'
import {useState} from "react"
import CloseIcon from '@mui/icons-material/Close'
import {getIconByCategoryTitle, getIconBySocial} from "../../utils"
import logo from "../../assets/img/logo/liketank-logo-horizontal-transparent.png"
import {useGlobalContext} from "../../contexts/global_context"
import {Divider} from "@mui/material"
import {grey} from "@mui/material/colors"
import LoginIcon from '@mui/icons-material/Login'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const Sidebar = () => {
  let {groupedCategories} = useGlobalContext()
  let {isOpenSidebar, toggleSidebar} = useNavbarContext()

  return (
    <React.Fragment>
      <Drawer
        anchor={'right'}
        open={isOpenSidebar}
        onClose={toggleSidebar(false)}
      >
        <Box
          sx={{width: 300}}
          role="presentation"
          onKeyDown={toggleSidebar(false)}
        >
          <SidebarList groupedCategories={groupedCategories}/>
        </Box>
      </Drawer>
    </React.Fragment>
  )
}

const SidebarList = ({groupedCategories}) => {
  let {toggleSidebar, logoutUser} = useNavbarContext()
  let {userInfo} = useGlobalContext()
  let [isOpenList, setIsOpenList] = useState(Array(navbarMenuItems.length).fill(false))

  const handleClick = (index) => {
    let newList = Array(navbarMenuItems.length).fill(false)
    newList[index] = !isOpenList[index]
    setIsOpenList(newList)
  }

  const renderUserButtons = () => {
    if (userInfo && userInfo.email) {
      return <>
        <ListItemButton
          onClick={() => {
            window.location = userInfo.is_staff ? '/admin/orders/' : '/dashboard/'
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon/>
          </ListItemIcon>
          <ListItemText primary={'Dashboard'}/>
        </ListItemButton>

        <ListItemButton
          onClick={logoutUser}
        >
          <ListItemIcon>
            <LogoutIcon/>
          </ListItemIcon>
          <ListItemText primary={'Logout'}/>
        </ListItemButton>
      </>
    } else {
      return <>
        <ListItemButton component={Link} to={'/signup'} onClick={toggleSidebar(false)}>
          <ListItemIcon>
            <PersonAddAltIcon/>
          </ListItemIcon>
          <ListItemText primary={'Sign Up'}/>
        </ListItemButton>

        <ListItemButton component={Link} to={'/login'} onClick={toggleSidebar(false)}>
          <ListItemIcon>
            <LoginIcon/>
          </ListItemIcon>
          <ListItemText primary={'Login'}/>
        </ListItemButton>
      </>
    }
  }

  return (
    <List
      sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItem divider onClick={toggleSidebar(false)}
                sx={{paddingTop: '5px', paddingBottom: '10px', borderColor: 'white'}}>
        <Link to={'/'}>
          <img src={logo} alt={'LikeTank Logo'} className={'header-logo'} width={'50%'}/>
        </Link>
        <CloseIcon onClick={toggleSidebar(false)}/>
      </ListItem>

      <Divider
        sx={{
          padding: '10px 20px 0px 20px',
          color: grey[400],
          fontSize: 'small',
        }}
      >
        Buy Packages
      </Divider>

      {groupedCategories && groupedCategories.map((navbarItem, index) => {
        return <div key={index}>
          <ListItemButton onClick={() => {
            handleClick(index)
          }}>
            <ListItemIcon>
              {getIconBySocial(navbarItem.social_display)}
            </ListItemIcon>
            <ListItemText primary={navbarItem.social_display}/>
            {isOpenList[index] ? <ExpandLess/> : <ExpandMore/>}
          </ListItemButton>

          <Collapse in={isOpenList[index]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {navbarItem.categories.map(submenuItem => {
                return (
                  <ListItemButton
                    component={Link}
                    to={`/categories/${submenuItem.slug}`}
                    key={submenuItem.uid}
                    sx={{pl: 4}}
                    onClick={toggleSidebar(false)}
                  >
                    <ListItemIcon>
                      {getIconByCategoryTitle(submenuItem.title)}
                    </ListItemIcon>
                    <ListItemText primary={submenuItem.title}/>
                  </ListItemButton>
                )
              })}
            </List>
          </Collapse>
        </div>
      })}

      <Divider
        sx={{
          padding: '20px 20px 0px 20px',
          color: grey[400],
          fontSize: 'small',
        }}
      >
        Useful Links
      </Divider>

      {navbarLinks.map(item => {
        return (
          <ListItemButton key={item.uid} component={Link} to={item.slug} onClick={toggleSidebar(false)}>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.title}/>
          </ListItemButton>
        )
      })}

      {renderUserButtons()}
    </List>
  )
}

export default Sidebar
